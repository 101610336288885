import { BaseSyntheticEvent } from 'react';
import axiosInstance from './axiosInstance';
import { CvNo, Parish } from './Interface';

interface BaseRes {
  id: string;
}
interface BasePostApi {
  onSuccess?: Function;
  onFail?: Function;
  callback?: Function;
}

interface PostParticularType {
  chart_of_account: string | undefined;
  code: string;
  church: string;
  name: string;
  particular_tag?: string;
}

export const PostParticularName = async ({ church, name, code, particular_tag, chart_of_account }: PostParticularType) => {
  await axiosInstance
    .post('/parish/api/particulars/', {
      church,
      name,
      code,
      particular_tag,
      chart_of_account,
    })
    .then(() => {})
    .catch(() => {})
    .finally(() => {});
};

export const PostFundName = async (church: string, fundName: string, onSuccess?: Function, onFail?: Function, callback?: Function) => {
  await axiosInstance
    .post('/parish/api/chart-of-accounts/', {
      church: church,
      name: fundName,
    })
    .then(() => {
      if (onSuccess) onSuccess();
    })
    .catch(() => {
      if (onFail) onFail();
    })
    .finally(() => {
      if (callback) callback();
    });
};

interface PostAccountForChurchType extends BasePostApi {
  user?: UserAccountForChurch | FormData;
  church?: AccountForChurch | FormData | any;
}
interface UserAccountForChurch {
  username: string;
  password: string;
  email: string;
}

interface AccountForChurch {
  name: string;
  location: string;
  logo: string | null;
}

export const PostAccountForChurch = async ({ user, church, onFail, onSuccess, callback }: PostAccountForChurchType) =>
  await axiosInstance
    .post(
      'api/add-church-with-account/',
      {
        user,
        church,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    )
    .then((res: { data: Parish & BaseRes }) => {
      if (onSuccess) onSuccess();
      return res.data as Parish & BaseRes;
    })
    .catch(() => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

interface PostChurchForm extends BasePostApi {
  data: FormData;
}

interface PostChurchType extends BasePostApi {
  data: FormData;
}

export const PostChurch = async ({ onFail, onSuccess, callback, data }: PostChurchType) =>
  await axiosInstance
    .post('api/church/', data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res: { data: PostAccountForChurchType & BaseRes }) => {
      if (onSuccess) onSuccess();
      return res.data as PostAccountForChurchType & BaseRes;
    })
    .catch(() => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

interface AddAccountForChurchType extends BasePostApi {
  user: UserAccountForChurch;
  church: string;
}

export const AddAccountForChurch = async ({ onFail, onSuccess, callback, user, church }: AddAccountForChurchType) =>
  await axiosInstance
    .post('api/add-account-for-church/', {
      user,
      church,
    })
    .then((res: { data: AccountForChurch & BaseRes }) => {
      if (res) {
        if (onSuccess) onSuccess();
        return res.data as AccountForChurch & BaseRes;
      }
      return null;
    })
    .catch(() => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

interface AddChartOfAccountPost extends BasePostApi {
  church: string;
  name: string;
}

export const AddChartOfAccount = async <Type,>({ onFail, onSuccess, callback, church, name }: AddChartOfAccountPost) =>
  await axiosInstance
    .post('parish/api/chart-of-accounts/', {
      church,
      name,
    })
    .then((res: { data: Type & BaseRes }) => {
      if (res) {
        if (onSuccess) onSuccess();
        return res.data as Type & BaseRes;
      }
      return null;
    })
    .catch(() => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

interface PostParticularTagType {
  name: string;
  church: string;
  subsidiary_no: string;
}

export const PostParticularTag = async ({ name, church, subsidiary_no }: PostParticularTagType) =>
  await axiosInstance
    .post('/parish/api/particular-tags/', {
      name,
      church,
      subsidiary_no,
    })
    .then((res: { data: PostParticularTagType & BaseRes }) => {
      if (!res) return null;
      return res.data as PostParticularTagType & BaseRes;
    })
    .catch(() => null);

interface PostEntryType {
  church: string;
  particular: string;
  amount: number;
  date_time: string;
}

export const PostEntry = async ({ church, particular, amount, date_time }: PostEntryType) => {
  let data: Partial<{}> = {
    amount,
    particular,
    church,
  };
  if (date_time !== '') {
    data = { ...data, date_time: new Date(date_time) };
  }
  return await axiosInstance
    .post('/parish/api/entries/', data)
    .then((res: { data: PostEntryType & BaseRes }) => {
      if (!res) return null;
      return res.data as PostEntryType & BaseRes;
    })
    .catch(() => null);
};

export interface PostVoucherType extends BaseRes {
  church: string;
  church_account: string;
  name: string;
  type: string;
  amount_in_words: string;
  date_time: string;
  cv_no: string | undefined;
  pay_to: string;
  description: string;
  amount: number;
}

export const PostVoucher = async ({
  id,
  church,
  church_account,
  name,
  type,
  amount_in_words,
  date_time,
  cv_no,
  pay_to,
  description,
  amount,
}: PostVoucherType) => {
  let data: Partial<{}> = {
    id,
    church,
    church_account,
    name,
    type,
    amount_in_words,
    cv_no,
    pay_to,
    description,
    amount,
  };
  if (date_time !== '') {
    data = { ...data, date_time: new Date(date_time) };
  }
  return await axiosInstance
    .post('parish/api/vouchers/create/', data)
    .then((res: { data: PostVoucherType & BaseRes }) => {
      if (!res) return null;
      return res.data as PostVoucherType & BaseRes;
    })
    .catch(() => null);
};
export interface PostVoucherItemType extends BaseRes {
  id: string;
  church: string;
  voucher: string;
  code: string;
  name: string;
  particular: string;
  debit: number;
  credit: number;
}

export const PostVoucherItem = async ({ id, church, voucher, name, code, particular, debit, credit }: PostVoucherItemType) =>
  await axiosInstance
    .post('parish/api/voucher-items/create/', {
      id,
      church,
      voucher,
      name,
      code,
      particular,
      debit,
      credit,
    })
    .then((res: { data: PostVoucherItemType & BaseRes }) => res.data as PostVoucherItemType & BaseRes)
    .catch(() => null);

interface AddCashReceiptsPost extends BasePostApi {
  church: string;
  amount: string;
  date_time: string;
  balance_sheet: string;
  income_expense: string;
  received_from: string;
  address: string;
  payment_for: string;
  amount_in_words: string;
  form_of_payment: string;
  received_by: string;
  ar_no: string;
}

export const AddCashReceipts = async <Type,>({
  onFail,
  onSuccess,
  callback,
  church,
  amount,
  date_time,
  balance_sheet,
  income_expense,
  received_from,
  address,
  payment_for,
  amount_in_words,
  form_of_payment,
  received_by,
  ar_no,
}: AddCashReceiptsPost) =>
  await axiosInstance
    .post('/parish/api/cash-receipts/create', {
      church,
      amount,
      date_time,
      balance_sheet,
      income_expense,
      received_from,
      address,
      payment_for,
      amount_in_words,
      form_of_payment,
      received_by,
      ar_no,
    })
    .then((res: { data: Type & BaseRes }) => {
      if (res) {
        if (onSuccess) onSuccess();
        return res.data as Type & BaseRes;
      }
      return null;
    })
    .catch(() => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

interface PostFileShareType extends BasePostApi {
  data: FormData;
}

export const PostFileShare = async ({ onFail, onSuccess, callback, data }: PostFileShareType) =>
  await axiosInstance
    .post('/parish/api/file-share/create/', data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res: { data: PostFileShareType & BaseRes }) => {
      if (onSuccess) onSuccess();
      return res.data as PostFileShareType & BaseRes;
    })
    .catch(() => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

interface PostPettyCashVoucherType extends BaseRes {
  church: string;
  church_account: string;
  particular: string;
  date: string;
  pcv_no: string | undefined;
  to: string;
  amount: string;
}

export const PostPettyCashVoucher = async ({
  id,
  church,
  church_account,
  particular,
  date,
  pcv_no,
  to,
  amount,
}: PostPettyCashVoucherType) =>
  await axiosInstance
    .post('/parish/api/petty-cash-voucher/create', {
      id,
      church,
      church_account,
      particular,
      date,
      pcv_no,
      to,
      amount,
    })
    .then((res: { data: PostPettyCashVoucherType & BaseRes }) => res.data as PostPettyCashVoucherType & BaseRes)
    .catch(() => null);

interface PostJournalVoucherType extends BaseRes {
  id: string;
  church: string;
  church_account: string;
  date: string;
  jv_no: string | undefined;
  description: string;
  amount: string;
}

export const PostJournalVoucher = async ({ id, church, church_account, date, jv_no, description, amount }: PostJournalVoucherType) =>
  await axiosInstance
    .post('/parish/api/journal-voucher/create', {
      id,
      church,
      church_account,
      date,
      jv_no,
      description,
      amount,
    })
    .then((res: { data: PostJournalVoucherType & BaseRes }) => res.data as PostJournalVoucherType & BaseRes)
    .catch(() => null);

interface PostJournalVoucherItemType extends BaseRes {
  id: string;
  church: string;
  journal_voucher: string;
  code: string;
  particular: string;
  debit: number;
  credit: number;
}

export const PostJournalVoucherItem = async ({
  id,
  church,
  journal_voucher,
  code,
  particular,
  debit,
  credit,
}: PostJournalVoucherItemType) =>
  await axiosInstance
    .post('/parish/api/journal-voucher-item/create', {
      id,
      church,
      journal_voucher,
      code,
      particular,
      debit,
      credit,
    })
    .then((res: { data: PostJournalVoucherItemType & BaseRes }) => res.data as PostJournalVoucherItemType & BaseRes)
    .catch(() => null);
