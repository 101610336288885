import { ChangeEventHandler, FormEventHandler, MouseEventHandler, ReactElement, ReactNode, WheelEventHandler } from 'react';
import { ColorType } from '../Interface/Pages';
import { GetColorTypeButton } from '../styles/button';
import style from './form.module.css';

interface Form {
  id: string;
  handleSubmit: FormEventHandler;
  children: ReactNode;
}

interface FormButton extends ColorType {
  title: string | JSX.Element;
  name: string;
  onClick?: MouseEventHandler;
  type: 'submit' | 'reset' | 'button';
  icon?: ReactElement;
  width?: string;
  margin?: string;
}

export const FormButton = ({
  type,
  title,
  name,
  onClick,
  primary,
  secondary,
  success,
  warning,
  danger,
  icon,
  width,
  margin,
  disabled,
}: FormButton) => (
  <div className={style.buttonContainer} style={{ width: width, margin: margin }}>
    <button
      disabled={disabled}
      type={type}
      className={`${GetColorTypeButton({ disabled, primary, secondary, success, warning, danger })}`}
      name={name}
      onClick={onClick}
    >
      {icon}
      {title}
    </button>
  </div>
);

interface BaseInput {
  placeHolder?: string;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  icon?: ReactElement;
  title?: string | JSX.Element;
  name: string;
  value?: string;
  onChange: ChangeEventHandler;
  onWheel?: WheelEventHandler;
  disabled?: boolean;
  fontWeight?: string;
  fontSize?: string;
  readonly?: boolean;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  color?: boolean;
  className?: string;
  minValue?: string | number;
  maxValue?: string | number;
  required?: boolean;
  list?: string;
  defaultValue?: string;
}

interface FormInput extends BaseInput {
  type?: string;
}

export const FormInput = ({
  title,
  name,
  value,
  onChange,
  type,
  placeHolder,
  width,
  height,
  padding,
  margin,
  disabled,
  fontWeight,
  fontSize,
  readonly,
  textAlign,
  color,
  className,
  maxValue,
  minValue,
  required,
  list,
  defaultValue,
  onWheel,
}: FormInput) => (
  <div
    className={color ? style.inputContainerRed : style.inputContainer}
    style={{ width: width, padding, margin: margin, fontWeight: fontWeight, fontSize: fontSize, height: height }}
  >
    {title && <div className={style.title}>{title}</div>}
    <input
      name={name}
      max={maxValue}
      min={minValue}
      className={className}
      type={type}
      required={required}
      onChange={onChange}
      value={value}
      placeholder={placeHolder ? placeHolder : ''}
      disabled={disabled}
      style={{ fontWeight: fontWeight, fontSize: fontSize, textAlign }}
      readOnly={readonly}
      list={list}
      defaultValue={defaultValue}
      onWheel={onWheel}
    />
  </div>
);

interface FormTextArea extends BaseInput {
  cols?: number;
  rows?: number;
}

export const FormTextArea = ({
  title,
  name,
  value,
  onChange,
  placeHolder,
  width,
  padding,
  margin,
  rows,
  cols,
  fontSize,
  fontWeight,
  required,
}: FormTextArea) => (
  <div
    className={style.inputContainer}
    style={{ width: width, padding: padding, margin: margin, fontSize: fontSize, fontWeight: fontWeight }}
  >
    {title && <div className={style.title}>{title}</div>}
    <textarea
      rows={rows}
      cols={cols}
      name={name}
      required={required}
      onChange={onChange}
      value={value}
      placeholder={placeHolder ? placeHolder : ''}
      style={{ fontSize: fontSize, fontWeight: fontWeight }}
    />
  </div>
);

export const FormLabel = ({ title, width }: any) => (
  <div className={style.inputContainer} style={{ width: width }}>
    <label>{title}</label>
  </div>
);

interface BaseContainer {
  children: ReactNode;
}

interface FormFieldContainer extends BaseContainer {}

interface FieldContainer extends BaseContainer {
  width?: string;
  minWidth?: string;
  padding?: string;
  margin?: string;
  marginLeft?: string;
  marginRight?: string;
}

export const FormFieldContainer = ({ children }: FormFieldContainer) => (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>{children}</div>
);

export const FieldContainer = ({ minWidth, children, width, padding, margin, marginLeft, marginRight }: FieldContainer) => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      width: width,
      justifyContent: 'space-between',
      minWidth: minWidth,
      padding: padding,
      margin: margin,
      marginLeft,
      marginRight,
    }}
  >
    {children}
  </div>
);

const Form = ({ id, handleSubmit, children }: Form) => (
  <div className={style.container}>
    <form id={id} onSubmit={handleSubmit}>
      <div className={style.inputContents}>{children}</div>
    </form>
  </div>
);

export default Form;
