import Container, { Content } from '../../components/Container/Container';
import VoidSticker from '../../components/Modal/VoidSticker';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReceiptType, Me, ProcessedCashReceiptType } from '../../utils/Interface';

type SelectedReceiptTableType = {
  selectedCashReceipt: CashReceiptType | null;
};

const SelectedReceiptTable = ({ selectedCashReceipt }: SelectedReceiptTableType) => (
  <>
    <VoidSticker isVoid={selectedCashReceipt?.void} />
    {selectedCashReceipt?.void ? (
      <div style={{ display: 'grid', justifyContent: 'start' }}>
        <div style={{ textAlign: 'start' }}>
          <span style={{ color: 'red' }}>This transaction was voided!</span>
        </div>
        <div>
          <span style={{ color: '#055437' }}>
            <strong>Purpose of void:</strong>
            {selectedCashReceipt.purpose_of_void}
          </span>
        </div>
      </div>
    ) : (
      <></>
    )}
    <Table3
      success
      width="850px"
      header={[
        <div className="text-start">{FormatDate(selectedCashReceipt?.date_time ?? '')}</div>,
        <div className="text-center">Acc.Code</div>,
        <div className="text-center">Acc.Title</div>,
        <div className="text-centert">Debit</div>,
        <div className="text-center">Credit</div>,
      ]}
    >
      <TableRow3
        info={[<h4 style={{ textAlign: 'start', fontWeight: 'bolder', fontSize: '1em' }}>AR No.{selectedCashReceipt?.ar_no}</h4>]}
      />
      <TableRow3 info={[<h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{selectedCashReceipt?.payment_for}</h4>]} />
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{selectedCashReceipt?.balance_sheet.code}</h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{selectedCashReceipt?.balance_sheet.name}</h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</h4>,
        ]}
      />
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{selectedCashReceipt?.income_expense.code}</h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{selectedCashReceipt?.income_expense.name}</h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</h4>,
        ]}
      />
      <tr style={{ backgroundColor: '#D9D9D9' }}>
        <td colSpan={2} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
          Total
        </td>
        <td colSpan={1}></td>
        <td style={{ textAlign: 'center', fontWeight: 'bolder' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</td>
        <td style={{ textAlign: 'center', fontWeight: 'bolder' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</td>
      </tr>
    </Table3>
  </>
);

export default SelectedReceiptTable;
