import { MouseEventHandler, ReactNode, useState } from 'react';
import { Loader } from '../Loader/Spinner';
import style from './card.module.css';

interface CardContainer {
  children: ReactNode;
  justify?: string;
  isLoading?: boolean;
}

interface BaseCard {
  title: string;
  grow?: boolean;
  width?: string;
  border?: string;
}

interface Card extends BaseCard {
  content: any;
}

interface CardBox extends BaseCard {
  children?: ReactNode;
}

interface CardButton extends Card {
  onClick: MouseEventHandler;
  disabled?: boolean;
}

const GetStyle = (justify: string | undefined, width?: string | undefined, border?: string | undefined) => {
  let base = {};
  if (justify) base = { ...base, justifyContent: justify };
  if (width) base = { ...base, width: width };
  if (border) base = { ...base, border: border };
  return base;
};

const CardContainer = ({ children, justify, isLoading }: CardContainer) => (
  <div className={style.container} style={GetStyle(justify)}>
    {isLoading && (
      <div className={style.containerLoader}>
        <Loader type={'roller'} />
      </div>
    )}
    {children}
  </div>
);

export const CardButton = ({ title, content, grow, width, border, onClick, disabled }: CardButton) => (
  <button
    disabled={disabled}
    className={`${disabled ? style.dCardButton : style.cardButton} ${grow ? style.grow : ''}`}
    style={{ ...GetStyle(undefined, width, border) }}
    onClick={onClick}
  >
    <div className={style.logo}>{content}</div>
    <div className={style.text}>{title}</div>
  </button>
);

export const CardBox = ({ grow, title, children, width, border }: CardBox) => (
  <div className={`${style.cardBox} ${grow ? style.grow : ''}`} style={{ ...GetStyle(undefined, width, border) }}>
    <div className={style.title}>{title}</div>
    <div className={style.content}>{children}</div>
  </div>
);

export const Card = ({ title, content, grow, width, border }: Card) => (
  <div className={`${style.card} ${grow ? style.grow : ''}`} style={GetStyle(undefined, width, border)}>
    <div className={style.title}>{title}</div>
    <div className={style.content}>{content}</div>
  </div>
);

export default CardContainer;
