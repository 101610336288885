// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_select__trOIB {\n  padding: 0.4em 0 0.4em 1em;\n  margin: auto;\n  text-align: start;\n  font-size: 1.2em;\n  border: 1px solid #055437;\n  /* border : 1px solid #00b894; */\n  color: #055437;\n  border-radius: 0.2em;\n  background: none;\n  width: 235px;\n}\n\n.select_title__0ynTu {\n  text-align: left;\n  font-size: 1.3em;\n  /* color : #00b894; */\n  color: #055437;\n  font-weight: 500;\n  margin-bottom: 0.2em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Select/select.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,gCAAgC;EAChC,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".select {\n  padding: 0.4em 0 0.4em 1em;\n  margin: auto;\n  text-align: start;\n  font-size: 1.2em;\n  border: 1px solid #055437;\n  /* border : 1px solid #00b894; */\n  color: #055437;\n  border-radius: 0.2em;\n  background: none;\n  width: 235px;\n}\n\n.title {\n  text-align: left;\n  font-size: 1.3em;\n  /* color : #00b894; */\n  color: #055437;\n  font-weight: 500;\n  margin-bottom: 0.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "select_select__trOIB",
	"title": "select_title__0ynTu"
};
export default ___CSS_LOADER_EXPORT___;
