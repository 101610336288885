import React, { useEffect, useState } from 'react';
import { TableLoader2 } from '../../components/Loader/Spinner';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { ParseToPHP } from '../../utils/Formatter';
import { CashReportsType } from '../../utils/Interface';

type CashReportsTableType = {
  cashReports: CashReportsType | null;
  isLoading: boolean;
};

const CashReportsTable = ({ cashReports, isLoading }: CashReportsTableType) => {
  const part = cashReports?.results.find((item) => item.particulars);

  const TotalAmount = () => {
    const sum = cashReports?.results.reduce(
      (accumulator, reports) =>
        accumulator + reports.particulars.reduce((total, particular) => total + particular.debit + particular.credit, 0),
      0
    );

    if (sum === undefined) return;

    const total = sum / 2;

    return total;
  };

  return (
    <>
      <Table3
        success
        width="850px"
        header={[
          <div></div>,
          <div className="text-center">Acc.Code</div>,
          <div className="text-center">Acc.Title</div>,
          <div className="text-end">Debit</div>,
          <div className="text-end">Credit</div>,
        ]}
      >
        {isLoading ? (
          part ? (
            cashReports?.results.map((item, i) => (
              <React.Fragment key={i}>
                <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>{item.date}</h4>]} />
                <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'start', fontWeight: 'bolder' }}>AR NO:{item.ar_no}</h4>]} />
                <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{item.payment_for}</h4>]} />
                {item.particulars.map((part, p) => (
                  <TableRow3
                    key={p}
                    info={[
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                      <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{part.code}</h4>,
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{part.name}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.debit)}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.credit)}</h4>,
                    ]}
                  />
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={2}></td>
              <td style={{ textAlign: 'center', fontWeight: 'normal', padding: '.5em 1.7em' }}>No Cash Reports!</td>
            </tr>
          )
        ) : (
          <>
            <tr>
              <td colSpan={2}></td>
              <TableLoader2 type="boxbox" />
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td style={{ textAlign: 'center', fontWeight: 'normal' }}>Loading... Please Select Date From and Date To!</td>
            </tr>
          </>
        )}
        {isLoading ? (
          <tr style={{ backgroundColor: '#D9D9D9' }}>
            <td colSpan={3} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
              Total
            </td>

            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(TotalAmount() ?? 0)}</td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(TotalAmount() ?? 0)}</td>
          </tr>
        ) : (
          <tr style={{ backgroundColor: '#D9D9D9' }}>
            <td colSpan={2} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
              Total
            </td>
            <td colSpan={1}></td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(0)}</td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(0)}</td>
          </tr>
        )}
      </Table3>
      {isLoading ? (
        <Table4
          success
          width="850px"
          header={[
            <div className="text-start"></div>,
            <div className="text-center">Code</div>,
            <div className="text-center">Account Summaries</div>,
            <div className="text-end">Debit</div>,
            <div className="text-end">Credit</div>,
          ]}
        >
          {isLoading ? (
            cashReports?.account_summaries ? (
              cashReports?.account_summaries.map((acc, i) => {
                if (acc.type === 'Balance Sheet') {
                  return (
                    <TableRow3
                      key={i}
                      info={[
                        <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{acc.code}</h4>,
                        <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{acc.name}</h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(acc.total_amount)}</h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}></h4>,
                      ]}
                    />
                  );
                }
                if (acc.type === 'Income & Expense') {
                  return (
                    <TableRow3
                      key={i}
                      info={[
                        <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{acc.code}</h4>,
                        <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{acc.name}</h4>,
                        <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(acc.total_amount)}</h4>,
                      ]}
                    />
                  );
                }
              })
            ) : (
              <TableRow3 colspan={3} info={[<h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>No Cash Reports!</h4>]} />
            )
          ) : (
            <></>
          )}
        </Table4>
      ) : (
        <></>
      )}
    </>
  );
};

export default CashReportsTable;
