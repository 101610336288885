import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import CardContainer, { Card } from '../components/Card/Card';
import { BarChart, PieChart } from '../components/Charts/Charts';
import Container, { ContainerFade, Content } from '../components/Container/Container';
import { Loader } from '../components/Loader/Spinner';
import { SwalFailed } from '../components/Swal/Swal';
import axiosInstance from '../utils/axiosInstance';
import { AppContext } from '../utils/context';
import { EachMonth, ProcessDashboardIncomeExpense } from '../utils/DashboardProcessor';
import { ParseToPHP } from '../utils/Formatter';
import { GetExpenseBreakout } from '../utils/GetApi';
import GetDashboardApi, { GetDashboardIncomeExpense } from '../utils/GetDashboard';
import { ExpenseBreakoutType } from '../utils/Interface';

const Home = () => {
  const [dbInfo, setDBInfo] = useState<string[] | null>(null);

  const [dbIncomeExpense, setDBIncomeExpense] = useState<EachMonth[] | null>(null);
  const [expenseBreakout, setExpenseBreakout] = useState<ExpenseBreakoutType[] | null>(null);

  useEffect(() => {
    GetDashboard();
    GetExpenseBreakout().then((res) => {
      if (res === null) {
        SwalFailed('Expense Breakout Failed!');
        setExpenseBreakout([]);
        return;
      }
      setExpenseBreakout(res);
    });
  }, []);

  const GetDashboard = async () => {
    const data = await GetDashboardApi();
    if (!data) {
      SwalFailed('Dashboard Summary Failed!');
      setDBInfo(['Failed!', 'Failed!', 'Failed!']);
    } else {
      setDBInfo(data.map((item) => ParseToPHP(parseFloat(item))));
    }

    const dataIE = await GetDashboardIncomeExpense();
    if (!dataIE) {
      SwalFailed('Income & Expense Failed!');
      setDBIncomeExpense([]);
      return;
    }

    const newMonths = ProcessDashboardIncomeExpense(dataIE);
    setDBIncomeExpense(newMonths);
  };

  const usedColors = new Set();

  const GetRandomColor = (): string => {
    let color = '#';
    const letters = 'ABCDEF';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 6)];
    }
    if (usedColors.has(color)) {
      return GetRandomColor();
    }
    usedColors.add(color);
    return color;
  };

  const formatAmount = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <>
      <div className="flex justify-end mr-2">
        <div className="border border-green-800 px-10 rounded-lg text-green-900">{moment().format('LL')}</div>
      </div>
      <CardContainer>
        <Card grow title={'TOTAL CASH BALANCE'} content={dbInfo ? dbInfo[0] : 'Loading...'} />
        <Card grow title={'NET INCOME FOR CURRENT YEAR'} content={dbInfo ? dbInfo[1] : 'Loading...'} />
        <Card grow title={'NET INCOME FOR CURRENT MONTH'} content={dbInfo ? dbInfo[2] : 'Loading...'} />
      </CardContainer>
      {/* <ContainerFade message="Under Development" icon={<i className="fa-solid fa-screwdriver-wrench"></i>}> */}
      <Container flex width="100%" justify="space-around">
        {dbIncomeExpense ? (
          <Content flex width="60%" minWidth="300px" alignContent="end">
            <BarChart
              id={'income-expense'}
              labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
              datasets={[
                {
                  label: 'Debit',
                  data: [...dbIncomeExpense.map((ie) => ie.income)],
                  backgroundColor: '#55efc4',
                },
                {
                  label: 'Credit',
                  data: [...dbIncomeExpense.map((ie) => Math.abs(ie.expense))],
                  backgroundColor: '#ff7675',
                },
              ]}
            />
          </Content>
        ) : (
          <div style={{ margin: 'auto' }}>
            <Loader type={'ring'} />
            <h2 className="text-xl">Loading Income Expense</h2>
          </div>
        )}
        <Content flex width="40%" minWidth="300px">
          {expenseBreakout ? (
            expenseBreakout.length >= 1 ? (
              <PieChart
                id={'sd'}
                labels={expenseBreakout.map((expense) => expense.particular.toUpperCase())}
                datasets={[
                  {
                    label: 'Something',
                    data: expenseBreakout.map((amount) => parseFloat(amount.amount)),
                    backgroundColor: expenseBreakout.map((expense) => GetRandomColor()),
                  },
                ]}
              />
            ) : (
              <PieChart
                id={'sd'}
                labels={['No Expense']}
                datasets={[
                  {
                    label: 'Something',
                    data: [1],
                    backgroundColor: ['#55efc4'],
                  },
                ]}
              />
            )
          ) : (
            <div style={{ margin: 'auto' }}>
              <Loader type={'ring'} />
              <h2 className="text-xl">Loading Expense Breakout</h2>
            </div>
          )}
        </Content>
      </Container>
      {/* </ContainerFade> */}
    </>
  );
};

export default Home;
