import { ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ErrorPage from '../components/Pages/ErrorPage';
import { GetTokens, isAdmin } from './axiosInstance';

interface AdminRouteType {
  children?: ReactNode;
}

function AdminRoute({ children }: AdminRouteType) {
  const tokens = GetTokens();
  if (!isAdmin(tokens)) {
    return <ErrorPage code={403} message={'unauthorized access'} />;
  }

  return <>{children}</>;
}

export default AdminRoute;
