// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".color_primary__Oqd9h {\n  color: #0984e3;\n}\n.color_secondary__Kwm-r {\n  color: #636e72;\n}\n.color_success__siW8u {\n  /* color : #00b894; */\n  color: #055437;\n  /* color : #22835f; */\n}\n.color_warning__LMcTV {\n  color: #fdcb6e;\n}\n.color_danger__Vsdwf {\n  color: #d63031;\n}\n", "",{"version":3,"sources":["webpack://./src/components/styles/color.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;EACrB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".primary {\n  color: #0984e3;\n}\n.secondary {\n  color: #636e72;\n}\n.success {\n  /* color : #00b894; */\n  color: #055437;\n  /* color : #22835f; */\n}\n.warning {\n  color: #fdcb6e;\n}\n.danger {\n  color: #d63031;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "color_primary__Oqd9h",
	"secondary": "color_secondary__Kwm-r",
	"success": "color_success__siW8u",
	"warning": "color_warning__LMcTV",
	"danger": "color_danger__Vsdwf"
};
export default ___CSS_LOADER_EXPORT___;
