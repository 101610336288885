import style from './header.module.css';

interface HeaderType {
  title: string;
}

const Header = ({ title }: HeaderType) => (
  <div className={style.container}>
    <div className={style.title}>{title}</div>
  </div>
);

export default Header;
