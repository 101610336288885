import { access } from 'fs';
import React, { BaseSyntheticEvent, ChangeEvent, FormEvent, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Container, { Content } from '../components/Container/Container';
import Form, { FormButton, FormInput } from '../components/Form/Form';
import { Loader, TableLoader, TableLoader2 } from '../components/Loader/Spinner';
import Modal from '../components/Modal/Modal';
import Select, { Option } from '../components/Select/Select';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess, SwalUnderCons } from '../components/Swal/Swal';
import { TableAction, TableEmpty } from '../components/Table/Table';
import Table2, { TableRow2, TableRow2Header } from '../components/Table/Table2';
import axiosInstance, { GetTokens, isTokenValid } from '../utils/axiosInstance';
import { ParseToPHP } from '../utils/Formatter';
import { GetCoA, GetCoAList, GetCoAListType, GetCodes, GetGenericList, GetMe } from '../utils/GetApi';
import { BaseListView, ChartOfAccounts, CoAListType, CoATags, Me, Particulars, ParticularTags, ParticularType } from '../utils/Interface';
import { AddChartOfAccount, PostParticularName, PostParticularTag } from '../utils/PostApi';
import style from '../components/styles/import.module.css';
import { GetChurchListApi } from '../api/ChurchApi';
import ReactLoading from 'react-loading';
import { PostSpecialParticularApi } from '../api/SpecialParticularApi';
import GeneralLedgerTable from './ChartofAccounts/GeneralLedgerTable';
import { GetGeneralLedgerAccountApi, GetGeneralLedgerAccountTagApi, GetGeneralLedgerAccountTitleApi } from '../api/GeneralLedgerApi';

const BalanceSheet = () => {
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [chartOfAccounts, setChartOfAccounts] = useState<BaseListView<CoAListType> | null>(null);
  const [rerender, setRerender] = useState(true);
  const [rerender2, setRerender2] = useState(true);
  const [particular, setParticular] = useState<BaseListView<Particulars> | null>(null);
  const [subnum1, setSubNum1] = useState('');
  const [accountTag, setAccountTag] = useState('');
  const [selectedCoA, setSelectedCoA] = useState<CoAListType>();
  const [showGL, setShowGL] = useState(false);

  const ChangeAccountHandler = ({ target: { value } }: BaseSyntheticEvent) => {
    const SelectedCoA = chartOfAccounts?.results.find((CoA) => CoA.id === value);
    if (SelectedCoA === undefined) return;
    setSelectedCoA(SelectedCoA);
  };

  // #region useEffect List

  const [isLoading, setIsLoading] = useState(true);

  const SetTransactions = async () => {
    setIsLoading(true);
    await GetCoAListType<CoAListType>({
      type: 'balance-sheet',
      setList: setChartOfAccounts,
    }).then((res) => {
      setIsLoading(false);
      setIsDoneLoading(true);
    });
  };

  const [coaAssets, setCoaAssets] = useState<CoAListType>();
  const [coaLiabilities, setCoaLiabilities] = useState<CoAListType>();
  const [coaEquity, setCoaEquity] = useState<CoAListType | null>();
  const [codes, setCode] = useState<string[] | null>(null);

  useEffect(() => {
    if (chartOfAccounts === null) return;

    setCoaAssets(chartOfAccounts.results.find((coa) => coa.name === 'Assets'));
    setCoaLiabilities(chartOfAccounts.results.find((coa) => coa.name === 'Liabilities'));
    setCoaEquity(chartOfAccounts.results.find((coa) => coa.name === 'Fund Equity'));
  }, [chartOfAccounts]);

  useEffect(() => {
    setParticularList(particularList);
  }, [rerender]);

  useEffect(() => {
    setIsLoading(true);
    SetTransactions();

    GetCodes().then((res) => {
      setCode(res);
    });

    GetGenericList<Particulars>({
      apiUrl: '/parish/api/particulars/',
      setList: setParticular,
    });
    setParticularList(particularList);
  }, [rerender2]);

  useEffect(() => {
    SetMe();
  }, []);

  useEffect(() => {
    if (!isAdmin) return;
    GetChurchList();
  }, [myAccInfo]);

  // #endregion

  const [isAdmin, setIsAdmin] = useState(false);

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setIsAdmin(true);
      }
      setMyAccInfo(tokens);
    }
  };

  const [isDoneLoading, setIsDoneLoading] = useState(false);

  // #region Church List

  const [useSelect, setUseSelect] = useState(true);

  const OnSelectAll = () => {
    const list = churchListSelection;
    if (!list) return;
    const selectedAll = list.filter((item) => item.isSelected === true).length === list.length;
    setChurchListSelection((prev) =>
      prev
        ? [
            ...prev.map((item) => ({
              ...item,
              isSelected: !selectedAll,
            })),
          ]
        : null
    );
  };

  const OnSelectChurch = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { checked } = e.target;
    setChurchListSelection((prev) =>
      prev
        ? [
            ...prev.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  isSelected: checked,
                };
              }
              return item;
            }),
          ]
        : null
    );
  };

  type ChurchStatusType = {
    isSelected?: boolean;
    isPosting?: boolean;
    isSuccess?: boolean;
    isDone?: boolean;
  };

  const OnChangeChurchStatus = (id: string, isPosting: boolean, isSuccess: boolean, isDone: boolean) => {
    setChurchListSelection((prev) =>
      prev
        ? [
            ...prev.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  isPosting,
                  isSuccess,
                  isDone,
                };
              }
              return item;
            }),
          ]
        : null
    );
  };

  const [churchList, setChurchList] = useState<BaseListView<Church> | null>(null);
  const [churchListSelection, setChurchListSelection] = useState<(Church & ChurchStatusType)[] | null>(null);

  const GetChurchList = async () => {
    const data = await GetChurchListApi();
    if (!data) {
      SwalFailedCustom({
        title: 'Church List Error',
        text: 'Get Church List Failed!',
      });
    }
    setChurchList(data);
  };

  // #endregion

  // #region Post Buttons

  interface Particular {
    name: string;
    code: string;
  }

  const [particularList, setParticularList] = useState<Particular[]>([{ name: '', code: '' }]);

  const particularHandler = (index: number, name: 'name' | 'code', { target }: BaseSyntheticEvent) => {
    particularList[index][name] = target.value;
    setRerender((prev) => !prev);
  };

  const SubmitAddPartircularHandler = (e: React.FormEvent) => {
    e.preventDefault();

    const latestParticular = particularList[particularList.length - 1];
    if (!selectedCoA) {
      return SwalFailed('Please select Chart of Account!');
    }
    if (latestParticular.code.trim() !== '' && latestParticular.name.trim() === '') {
      return SwalFailed('Name is empty');
    }
    if (latestParticular.code.trim() === '' && latestParticular.name.trim() !== '') {
      return SwalFailed('Code is empty');
    }
    if (latestParticular.code.trim() === '' && latestParticular.name.trim() === '') {
      return SwalFailed('Text Field is empty');
    }
    if (codes?.includes(latestParticular.code)) {
      // return SwalFailed('The recent code inputed is already existing')
    }
    if (useSelect) {
      if (!isAdmin) return;
      if (!churchListSelection) return;
      const noSelected = churchListSelection.filter((item) => item.isSelected === true).length <= 0;
      if (noSelected) {
        SwalFailed('Please select at least one parish.');
        return;
      }
      SubmitBulkAddParticular();
    } else SubmitAddparticular();
  };

  const SubmitBulkAddParticular = async () => {
    if (!myAccInfo) return;
    if (!selectedCoA) return;
    if (!churchListSelection) return;

    let errorOccured = false;

    setIsLoading(true);
    const selectedChurches = churchListSelection.filter((item) => item.isSelected);
    for (let i = 0; i < selectedChurches.length; i++) {
      const church = selectedChurches[i];
      OnChangeChurchStatus(church.id, true, false, false);
      const isSuccess = await PostParticular(church.id, selectedCoA);
      if (isSuccess) OnChangeChurchStatus(church.id, false, true, true);
      else {
        OnChangeChurchStatus(church.id, false, false, true);
        errorOccured = true;
      }
    }

    setRerender2((prev) => !prev);

    setIsLoading(false);
    if (errorOccured) return;
    // [TBC] = THIS WILL NOT CHECK EACH PARTICULAR CALL STATUS
    SwalSuccess('Particular/s Added!');
    setAccountTag('');
    setSubNum1('');
    setParticularList([{ name: '', code: '' }]);
    setSelectedCoA(undefined);
    setShowAdd(false);
  };

  const PostParticular = async (churchID: string, coa: CoAListType) => {
    let tagData: ParticularTags | null = null;
    if (accountTag.trim() !== '' && subnum1.trim() !== '') {
      tagData = await PostParticularTag({
        name: accountTag,
        church: churchID,
        subsidiary_no: subnum1,
      });
      if (!tagData) return false;
    }

    const particulartPostList = particularList.map((item) =>
      PostSpecialParticularApi({
        church: churchID,
        name: item.name,
        code: item.code,
        particular_tag: tagData?.id,
        chart_of_account: coa.id,
      })
    );
    return await Promise.allSettled(particulartPostList)
      .then((res) => true)
      .catch((err) => false);
  };

  const SubmitAddparticular = async () => {
    if (!myAccInfo) return;
    if (myAccInfo.church === 'N/A') {
      Swal.fire({
        icon: 'warning',
        title: `No Parish assigned to your account!`,
      });
      return;
    }
    // Swal Loading -> start post api
    SwalLoading();
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === null) return;
    const churchID = pKey === '' ? myAccInfo.church : pKey;
    let tagData: ParticularTags | null = null;
    if (accountTag !== '' && subnum1 !== '')
      tagData = await PostParticularTag({
        name: accountTag,
        church: churchID,
        subsidiary_no: subnum1,
      });
    const particulartPostList = particularList.map((item) =>
      PostParticularName({
        church: churchID,
        name: item.name,
        code: item.code,
        particular_tag: tagData?.id,
        chart_of_account: selectedCoA?.id,
      })
    );
    await Promise.allSettled(particulartPostList)
      .then((res) => {
        // Swal Success Here
        SwalSuccess('Particular Added!');
        setAccountTag('');
        setSubNum1('');
        setParticularList([{ name: '', code: '' }]);
        setSelectedCoA(undefined);
        setShowAdd(false);
        setRerender2((prev) => !prev);
      })
      .catch((err) => {
        // Swal Failed Here
        SwalFailed('Failed to add particular!');
      });
  };

  // #endregion

  const [modals, setModals] = useState({
    addIncome: false,
    addExpense: false,
    addCoA: false,
  });

  const AddButton = (
    onClick: MouseEventHandler,
    text: string,
    color: string,
    align: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
    cursor: string
  ) => (
    <div style={{ textAlign: align, paddingTop: '0.8em', marginRight: '0.3em' }}>
      <button
        style={{ border: 'none', background: 'none', fontWeight: '500', color: color, cursor: cursor }}
        onClick={onClick}
        type={'button'}
      >
        <i className="fa-solid fa-plus"></i>
        {text}
      </button>
    </div>
  );

  const AddNewPart = () => {
    const latestParticular = particularList[particularList.length - 1];
    if (!selectedCoA) {
      return SwalFailed('Please select Chart of Account!');
    }
    if (latestParticular.code !== '' && latestParticular.name === '') {
      return SwalFailed('Name is empty');
    }
    if (latestParticular.code === '' && latestParticular.name !== '') {
      return SwalFailed('Code is empty');
    }
    if (latestParticular.code === '' && latestParticular.name === '') {
      return SwalFailed('Text Field is empty');
    }
    if (codes?.includes(latestParticular.code)) {
      // return SwalFailed('The code already exists!')
      // return SwalFailed('The recent code inputed is already existing')
    }

    particularList.push({ name: '', code: '' });
    setRerender((prev) => !prev);
  };

  const [showAdd, setShowAdd] = useState(false);

  const ModalCloseHandler = () => {
    setAccountTag('');
    setSubNum1('');
    setParticularList([{ name: '', code: '' }]);
    setSelectedCoA(undefined);
    setShowAdd(false);
  };

  // Auto checked viewing church
  useEffect(() => {
    if (!churchList) return;
    if (!myAccInfo) return;

    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === null) setChurchListSelection(churchList.results);
    else {
      const newList = churchList.results.map((item) => {
        if (item.id === pKey) {
          return {
            ...item,
            isSelected: true,
          };
        }
        return item;
      });
      setChurchListSelection(newList);
    }
  }, [showAdd, churchList, myAccInfo]);

  // #region Particular Delete Process
  const [partToDelete, setPartToDelete] = useState<ParticularType | null>(null);
  const [showDeletePart, setShowDeletePart] = useState(false);

  const DeletePartShowModal = (part: ParticularType) => {
    setPartToDelete(part);
    setShowDeletePart(true);
  };

  const DeletePart = async () => {
    if (!partToDelete) return;
    await axiosInstance.delete(`/parish/api/particulars/${partToDelete.id}`).then((res) => {
      SwalSuccess('Particular Deleted');
      setShowDeletePart(false);
      setRerender2((prev) => !prev);
    });
  };
  // #endregion

  // #region Particular Tag Delete Process
  const [partTagToDelete, setPartTagToDelete] = useState<CoATags | null>(null);
  const [showDeletePartTag, setShowDeletePartTag] = useState(false);

  const DeletePartTagShowModal = (tag: CoATags) => {
    setPartTagToDelete(tag);
    setShowDeletePartTag(true);
  };

  const DeleteTagPart = async () => {
    if (!partTagToDelete) return;
    await axiosInstance.delete(`/parish/api/particular-tags/${partTagToDelete?.id}`).then((res) => {
      SwalSuccess('Particular Tag Deleted');
      setShowDeletePartTag(false);
      setRerender2((prev) => !prev);
    });
  };
  // #endregion

  // #region Chart of Account Delete Process
  const [coaToDelete, setCoaToDelete] = useState<CoAListType | null | undefined>(null);
  const [showDeleteCoa, setShowDeleteCoa] = useState(false);

  const DeleteCoaShowModal = (coa: CoAListType | null | undefined) => {
    setCoaToDelete(coa);
    setShowDeleteCoa(true);
  };

  const DeleteCoa = async () => {
    if (!coaToDelete) return;
    await axiosInstance.delete(`/parish/api/chart-of-accounts/${coaToDelete?.id}`).then((res) => {
      SwalSuccess('Chart of Account Deleted');
      setShowDeleteCoa(false);
      setRerender2((prev) => !prev);
    });
  };

  // #endregion

  // #region show general ledger
  const [activeTable, setActiveTable] = useState<GeneralLedgerCategoryType>();
  const [coaGeneralLedgerToVIew, setCoaGeneralLedgerToVIew] = useState<CoAListType | null | undefined>(null);
  const [partTagToView, setPartTagToView] = useState<CoATags | null>(null);
  const [partToView, setPartToView] = useState<ParticularType | null>(null);
  const [showGeneralLedger, setShowGeneralLedger] = useState(false);
  const [generalLedgerList, setGeneralLedgerList] = useState<GeneralLedgerType | null>(null);
  const [loading, setLoading] = useState(false);

  const ShowCoaGeneralLedgerModal = (coa: CoAListType | null | undefined) => {
    setCoaGeneralLedgerToVIew(coa);
    setShowGeneralLedger(true);
  };

  const ShowTagGeneralLedgerModal = (tag: CoATags | null) => {
    setPartTagToView(tag);
    setShowGeneralLedger(true);
  };

  const ShowPartGeneralLedgerModal = (part: ParticularType | null) => {
    setPartToView(part);
    setShowGeneralLedger(true);
  };

  const GetCoaGeneralLedger = async (category: string) => {
    if (!coaGeneralLedgerToVIew) return;
    setLoading(true);
    const data = await GetGeneralLedgerAccountApi(coaGeneralLedgerToVIew.id, category);
    setLoading(false);
    if (!data) return;
    setGeneralLedgerList(data);
  };

  const GetAccTagGeneralLedger = async (category: string) => {
    if (!partTagToView) return;
    setLoading(true);
    const data = await GetGeneralLedgerAccountTagApi(partTagToView.id, category);
    setLoading(false);
    if (!data) return;
    setGeneralLedgerList(data);
  };

  const GetAccTitleGeneralLedger = async (category: string) => {
    if (!partToView) return;
    setLoading(true);
    const data = await GetGeneralLedgerAccountTitleApi(partToView.id, category);
    setLoading(false);
    if (!data) return;
    setGeneralLedgerList(data);
  };

  const CloseGeneralLedgerModal = () => {
    setActiveTable(undefined);
    setShowGeneralLedger(false);
    setGeneralLedgerList(null);
  };

  useEffect(() => {
    if (!activeTable) return;
    GetCoaGeneralLedger(activeTable);
    GetAccTagGeneralLedger(activeTable);
    GetAccTitleGeneralLedger(activeTable);
  }, [activeTable]);

  // #endregion

  const sumAssetsAmount = (arr: CoAListType[] | undefined) => {
    let disbursementsSum = 0;
    arr?.forEach((item) => {
      if (item.name === 'Assets') {
        item.tags.forEach((tag) => {
          tag.particulars.forEach((particular) => {
            disbursementsSum += particular.amount;
          });
        });
      }
    });
    return disbursementsSum;
  };

  const sumLiabilitiesAmount = (arr: CoAListType[] | undefined) => {
    let disbursementsSum = 0;
    arr?.forEach((item) => {
      if (item.name === 'Liabilities') {
        item.tags.forEach((tag) => {
          tag.particulars.forEach((particular) => {
            disbursementsSum += particular.amount;
          });
        });
      }
    });
    return disbursementsSum;
  };

  const sumEquityAmount = (arr: CoAListType[] | undefined) => {
    let disbursementsSum = 0;
    arr?.forEach((item) => {
      if (item.name === 'Fund Equity') {
        item.tags.forEach((tag) => {
          tag.particulars.forEach((particular) => {
            disbursementsSum += particular.amount;
          });
        });
      }
    });
    return disbursementsSum;
  };

  return (
    <>
      {isAdmin ? (
        <div
          className={style.container}
          onClick={() => {
            SwalUnderCons('This feature is under development!');
          }}
        >
          <div className={style.content}>
            <i className="fa-solid fa-file-import" style={{ fontSize: '30px' }}></i>
            <p className={style.title}>IMPORT</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isAdmin ? (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <span>
            {AddButton(
              () => {
                setShowAdd(true);
              },
              'Add Account Title',
              '#055437',
              'center',
              'pointer'
            )}
          </span>
        </div>
      ) : (
        <></>
      )}
      <CoA
        ShowCoaGeneralLedgerModal={ShowCoaGeneralLedgerModal}
        ShowTagGeneralLedgerModal={ShowTagGeneralLedgerModal}
        ShowPartGeneralLedgerModal={ShowPartGeneralLedgerModal}
        title="Assets"
        totalName="Total Assets"
        isAdmin={isAdmin}
        coa={coaAssets}
        DeleteCoAModal={DeleteCoaShowModal}
        DeletePartTagModal={DeletePartTagShowModal}
        DeletePartModal={DeletePartShowModal}
        sumDisbursementsAmount={sumAssetsAmount}
        chartOfAccounts={chartOfAccounts}
        isDoneLoading={isDoneLoading}
        AddButton={AddButton(
          () => {
            setShowAdd(true);
          },
          'Add Account Title',
          '#055437',
          'center',
          'pointer'
        )}
      />
      <CoA
        ShowCoaGeneralLedgerModal={ShowCoaGeneralLedgerModal}
        ShowTagGeneralLedgerModal={ShowTagGeneralLedgerModal}
        ShowPartGeneralLedgerModal={ShowPartGeneralLedgerModal}
        title="Liabilities"
        totalName="Total Liabilities"
        isAdmin={isAdmin}
        coa={coaLiabilities}
        DeleteCoAModal={DeleteCoaShowModal}
        DeletePartTagModal={DeletePartTagShowModal}
        DeletePartModal={DeletePartShowModal}
        sumDisbursementsAmount={sumLiabilitiesAmount}
        chartOfAccounts={chartOfAccounts}
        isDoneLoading={isDoneLoading}
        AddButton={AddButton(
          () => {
            setShowAdd(true);
          },
          'Add Account Title',
          '#055437',
          'center',
          'pointer'
        )}
      />
      <CoA
        ShowCoaGeneralLedgerModal={ShowCoaGeneralLedgerModal}
        ShowTagGeneralLedgerModal={ShowTagGeneralLedgerModal}
        ShowPartGeneralLedgerModal={ShowPartGeneralLedgerModal}
        title="Equity"
        totalName="Total Equity"
        isAdmin={isAdmin}
        coa={coaEquity}
        DeleteCoAModal={DeleteCoaShowModal}
        DeletePartTagModal={DeletePartTagShowModal}
        DeletePartModal={DeletePartShowModal}
        sumDisbursementsAmount={sumEquityAmount}
        chartOfAccounts={chartOfAccounts}
        isDoneLoading={isDoneLoading}
        AddButton={AddButton(
          () => {
            setShowAdd(true);
          },
          'Add Account Title',
          '#055437',
          'center',
          'pointer'
        )}
      />
      <Modal
        title={'Add Income'}
        success
        show={showAdd}
        onClose={() => {
          setShowAdd(false);
        }}
        name={''}
      >
        <div className="flex">
          <Form id={''} handleSubmit={SubmitAddPartircularHandler}>
            <Container width="100%" padding="0">
              <Content padding="0 1em" flex justify="space-between">
                <Select
                  title={
                    <>
                      <span className="text-red-500">*</span>Account:
                    </>
                  }
                  name="account"
                  required
                  onChange={ChangeAccountHandler}
                  placeHolder={'Choose Account'}
                  value={selectedCoA?.id}
                  disabled={isLoading}
                >
                  {chartOfAccounts?.results.map((CoA) => (
                    <Option key={CoA.id} value={CoA.id} title={`${CoA.control_no}---${CoA.name}`} />
                  ))}
                </Select>
              </Content>
            </Container>
            <Container flex width="100%" padding="0">
              <Content padding="0 1em">
                <FormInput
                  title="Subsidiary #:"
                  name={''}
                  value={subnum1}
                  disabled={isLoading}
                  onChange={({ target }: BaseSyntheticEvent) => {
                    setSubNum1(target.value);
                  }}
                />
              </Content>
              <Content padding="0 1em">
                <FormInput
                  title="Account Title Tag:"
                  name={''}
                  value={accountTag}
                  disabled={isLoading}
                  onChange={({ target }: BaseSyntheticEvent) => {
                    setAccountTag(target.value);
                  }}
                />
              </Content>
            </Container>
            {particularList.map((item, i) => (
              <div key={`q-${i}`} style={{ display: 'flex', margin: 'auto', width: '100%' }}>
                <div style={{ display: 'flex', margin: '.2em 0', padding: '0px 1em' }}>
                  <FormInput
                    key={`a-${i}`}
                    {...(i === 0 && { title: 'Subsidiary #:' })}
                    name={''}
                    value={item.code}
                    padding={'.2em'}
                    disabled={isLoading}
                    onChange={(e) => {
                      particularHandler(i, 'code', e);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', margin: '.2em 0', padding: '0px 0.4em' }}>
                  <FormInput
                    key={`b-${i}`}
                    {...(i === 0 && { title: 'Account Title:' })}
                    name={''}
                    value={item.name}
                    padding={'.2em'}
                    disabled={isLoading}
                    onChange={(e) => {
                      particularHandler(i, 'name', e);
                    }}
                  />
                </div>
              </div>
            ))}
            {!isLoading &&
              AddButton(
                () => {
                  AddNewPart();
                },
                'Add Account Title',
                '#00b894',
                'left',
                'pointer'
              )}
            <Container flex>
              <Content padding="0 1em">
                <FormButton width="8em" success title={'ADD'} name={''} onClick={() => {}} disabled={isLoading} type={'submit'} />
              </Content>
              <Content padding="0 1em">
                <FormButton
                  width="8em"
                  secondary
                  success
                  title={'CANCEL'}
                  name={''}
                  disabled={isLoading}
                  onClick={() => {
                    ModalCloseHandler();
                  }}
                  type={'button'}
                />
              </Content>
            </Container>
          </Form>
          {isAdmin && (
            <div className="mx-5 overflow-scroll text-green-900">
              <h2 className="font-bold">{churchListSelection ? 'Select Parishes' : ''}</h2>
              <button onClick={OnSelectAll} disabled={isLoading} className={'font-normal border border-green-900 rounded-md px-2'}>
                Select All
              </button>
              {myAccInfo && churchListSelection ? (
                churchListSelection.map((item) => {
                  const cStyle = item.isDone ? (item.isSuccess ? '' : 'text-red-500') : 'text-green-900';

                  return (
                    <div key={item.id} className={`flex items-center ${cStyle}`}>
                      {item.isPosting && <ReactLoading width={24} height={24} type="spin" color="#00b894" />}
                      <label
                        className={'flex justify-between ml-4'}
                        style={{
                          width: 240,
                        }}
                      >
                        {item.name}
                        {myAccInfo.church === item.id && ' (You)'}
                        <input
                          type={'checkbox'}
                          disabled={isLoading}
                          checked={item.isSelected}
                          onChange={(e) => {
                            OnSelectChurch(e, item.id);
                          }}
                        />
                      </label>
                    </div>
                  );
                })
              ) : (
                <h2 className="">Church list empty!</h2>
              )}
            </div>
          )}
        </div>
      </Modal>

      {partToDelete && (
        <Modal
          show={showDeletePart}
          onClose={() => {
            setPartToDelete(null);
            setShowDeletePart(false);
          }}
          name={'deleteParticular'}
          title={`Delete Particular`}
          width="400px"
          success
        >
          <div>
            <p style={{ color: '#298D69', fontWeight: 'bolder' }}>
              Are you sure you want to delete <span style={{ color: '#d63031' }}> {partToDelete.name} </span>?
            </p>
          </div>
          <Content flex justify="space-around" padding="1em 0 0 0">
            <div>
              <FormButton
                success
                title={'Confirm'}
                name={''}
                onClick={() => {
                  DeletePart();
                }}
                type={'submit'}
              />
            </div>
            <div>
              <FormButton
                title={'Cancel'}
                secondary
                name={'addNewModal'}
                onClick={() => {
                  setPartToDelete(null);
                  setShowDeletePart(false);
                }}
                type={'button'}
              />
            </div>
          </Content>
        </Modal>
      )}

      {partTagToDelete && (
        <Modal
          show={showDeletePartTag}
          onClose={() => {
            setPartTagToDelete(null);
            setShowDeletePart(false);
          }}
          name={'deleteParticular'}
          title={`Delete Particular Tag`}
          width="400px"
          success
        >
          <div>
            <p style={{ color: '#298D69', fontWeight: 'bolder' }}>
              Are you sure you want to delete <span style={{ color: '#d63031' }}> {partTagToDelete.name} </span>?
            </p>
          </div>
          <Content flex justify="space-around" padding="1em 0 0 0">
            <div>
              <FormButton
                success
                title={'Confirm'}
                name={''}
                onClick={() => {
                  DeleteTagPart();
                }}
                type={'submit'}
              />
            </div>
            <div>
              <FormButton
                title={'Cancel'}
                secondary
                name={'addNewModal'}
                onClick={() => {
                  setPartTagToDelete(null);
                  setShowDeletePart(false);
                }}
                type={'button'}
              />
            </div>
          </Content>
        </Modal>
      )}

      {coaToDelete && (
        <Modal
          show={showDeleteCoa}
          onClose={() => {
            setCoaToDelete(null);
            setShowDeletePart(false);
          }}
          name={'deleteParticular'}
          title={`Delete Chart of Account`}
          width="400px"
          success
        >
          <div>
            <p style={{ color: '#298D69', fontWeight: 'bolder' }}>
              Are you sure you want to delete <span style={{ color: '#d63031' }}> {coaToDelete.name} </span>?
            </p>
          </div>
          <Content flex justify="space-around" padding="1em 0 0 0">
            <div>
              <FormButton
                success
                title={'Confirm'}
                name={''}
                onClick={() => {
                  DeleteCoa();
                }}
                type={'submit'}
              />
            </div>
            <div>
              <FormButton
                title={'Cancel'}
                secondary
                name={'addNewModal'}
                onClick={() => {
                  setCoaToDelete(null);
                  setShowDeletePart(false);
                }}
                type={'button'}
              />
            </div>
          </Content>
        </Modal>
      )}
      <GeneralLedgerTable
        CloseGeneralLedgerModal={CloseGeneralLedgerModal}
        activeTable={activeTable}
        showGeneralLedger={showGeneralLedger}
        setShowGeneralLedger={setShowGeneralLedger}
        generalLedger={generalLedgerList}
        setActiveTable={setActiveTable}
        isLoading={loading}
      />
    </>
  );
};

export default BalanceSheet;

type CoAType = {
  title: string;
  isAdmin: boolean;
  coa: CoAListType | null | undefined;
  DeletePartModal: (part: ParticularType) => void;
  DeletePartTagModal: (partTag: CoATags) => void;
  DeleteCoAModal: (coA: CoAListType | null | undefined) => void;
  sumDisbursementsAmount: (arr: CoAListType[] | undefined) => number;
  chartOfAccounts: BaseListView<CoAListType> | null;
  AddButton: JSX.Element;
  isDoneLoading: boolean;
  totalName: string;
  ShowCoaGeneralLedgerModal: (coa: CoAListType | null | undefined) => void;
  ShowTagGeneralLedgerModal: (tag: CoATags | null) => void;
  ShowPartGeneralLedgerModal: (part: ParticularType | null) => void;
};

const CoA = ({
  title,
  isAdmin,
  coa,
  DeletePartModal,
  DeletePartTagModal,
  DeleteCoAModal,
  sumDisbursementsAmount,
  chartOfAccounts,
  isDoneLoading,
  totalName,
  ShowCoaGeneralLedgerModal,
  ShowTagGeneralLedgerModal,
  ShowPartGeneralLedgerModal,
}: CoAType) => (
  <React.Fragment>
    <div className={style.coaTitleContainer}>
      <h3 className={style.coaTitle}>{title}</h3>
    </div>
    <Table2
      success
      header={
        isAdmin
          ? [
              <div className="text-center">Control #</div>,
              <div className="text-center">Subsidary #</div>,
              <div className="text-center">Account</div>,
              <div className="text-centert">Account Title</div>,
              <div className="text-center">Amount</div>,
              <div className="text-center">Action</div>,
            ]
          : [
              <div className="text-center">Control #</div>,
              <div className="text-center">Subsidary #</div>,
              <div className="text-center">Account</div>,
              <div className="text-centert">Account Title</div>,
              <div className="text-center">Amount</div>,
            ]
      }
    >
      <TableRow2
        info={
          isAdmin
            ? [
                <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontFamily: '"IBM Plex Sans", sans-serif' }}>{coa?.control_no}</h4>,
                <h4 style={{ fontWeight: 'bolder' }}></h4>,
                <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>{coa?.name}</h4>,
                <h4 style={{ fontWeight: 'bolder' }}></h4>,
                <h4 style={{ textAlign: 'end', fontWeight: 'bolder' }}>{ParseToPHP(sumDisbursementsAmount(chartOfAccounts?.results))}</h4>,
                <div style={{ textAlign: 'center' }}>
                  <TableAction
                    icon={<i className="fa-solid fa-eye"></i>}
                    onClick={() => {
                      ShowCoaGeneralLedgerModal(coa);
                    }}
                  />
                  <span style={{ padding: '0.3em' }}></span>
                  <TableAction
                    icon={<i className="fa-solid fa-trash"></i>}
                    onClick={() => {
                      DeleteCoAModal(coa);
                    }}
                  />
                </div>,
              ]
            : [
                <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontFamily: '"IBM Plex Sans", sans-serif' }}>{coa?.control_no}</h4>,
                <h4 style={{ fontWeight: 'bolder' }}></h4>,
                <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>{coa?.name}</h4>,
                <h4 style={{ fontWeight: 'bolder' }}></h4>,
                <h4 style={{ textAlign: 'end', fontWeight: 'bolder' }}>{ParseToPHP(sumDisbursementsAmount(chartOfAccounts?.results))}</h4>,
              ]
        }
      />
      {isDoneLoading ? (
        coa?.tags.map((tags, t) => {
          if (tags.name === '') {
            return (
              <React.Fragment key={t}>
                {tags.particulars.map((part, p) => (
                  <TableRow2
                    key={p}
                    info={
                      isAdmin
                        ? [
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'center', fontWeight: 'normal', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                              {part.subsidiary_no}
                            </h4>,
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'start', fontWeight: 'normal', paddingLeft: '2em' }}>{part.name}</h4>,
                            <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.amount)}</h4>,
                            <div style={{ textAlign: 'center' }}>
                              <TableAction
                                icon={<i className="fa-solid fa-eye"></i>}
                                onClick={() => {
                                  ShowPartGeneralLedgerModal(part);
                                }}
                              />
                              <span style={{ padding: '0.3em' }}></span>
                              <TableAction
                                icon={<i className="fa-solid fa-trash"></i>}
                                onClick={() => {
                                  DeletePartModal(part);
                                }}
                              />
                            </div>,
                          ]
                        : [
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'center', fontWeight: 'normal', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                              {part.subsidiary_no}
                            </h4>,
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'start', fontWeight: 'normal', paddingLeft: '2em' }}>{part.name}</h4>,
                            <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.amount)}</h4>,
                          ]
                    }
                  />
                ))}
              </React.Fragment>
            );
          }
          if (tags.name !== '') {
            return (
              <React.Fragment key={t}>
                <TableRow2
                  key={t}
                  info={
                    isAdmin
                      ? [
                          <h4 style={{ fontWeight: 'bolder' }}></h4>,
                          <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                            {tags.subsidiary_no}
                          </h4>,
                          <h4 style={{ fontWeight: 'bolder' }}></h4>,
                          <h4 style={{ textAlign: 'start', fontWeight: 'bolder', paddingLeft: '2em' }}>{tags.name}</h4>,
                          <h4 style={{ textAlign: 'end', fontWeight: 'bolder' }}>{ParseToPHP(tags.amount)}</h4>,
                          <div style={{ textAlign: 'center' }}>
                            <TableAction
                              icon={<i className="fa-solid fa-eye"></i>}
                              onClick={() => {
                                ShowTagGeneralLedgerModal(tags);
                              }}
                            />
                            <span style={{ padding: '0.3em' }}></span>
                            <TableAction
                              icon={<i className="fa-solid fa-trash"></i>}
                              onClick={() => {
                                DeletePartTagModal(tags);
                              }}
                            />
                          </div>,
                        ]
                      : [
                          <h4 style={{ fontWeight: 'bolder' }}></h4>,
                          <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                            {tags.subsidiary_no}
                          </h4>,
                          <h4 style={{ fontWeight: 'bolder' }}></h4>,
                          <h4 style={{ textAlign: 'start', fontWeight: 'bolder', paddingLeft: '2em' }}>{tags.name}</h4>,
                          <h4 style={{ textAlign: 'end', fontWeight: 'bolder' }}>{ParseToPHP(tags.amount)}</h4>,
                        ]
                  }
                />
                {tags.particulars.map((part, p) => (
                  <TableRow2
                    key={p}
                    info={
                      isAdmin
                        ? [
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'center', fontWeight: 'normal', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                              {part.subsidiary_no}
                            </h4>,
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'start', fontWeight: 'normal', paddingLeft: '2em' }}>{part.name}</h4>,
                            <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.amount)}</h4>,
                            <div style={{ textAlign: 'center' }}>
                              <TableAction
                                icon={<i className="fa-solid fa-eye"></i>}
                                onClick={() => {
                                  ShowPartGeneralLedgerModal(part);
                                }}
                              />
                              <span style={{ padding: '0.3em' }}></span>
                              <TableAction
                                icon={<i className="fa-solid fa-trash"></i>}
                                onClick={() => {
                                  DeletePartModal(part);
                                }}
                              />
                            </div>,
                          ]
                        : [
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'center', fontWeight: 'normal', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                              {part.subsidiary_no}
                            </h4>,
                            <h4 style={{ fontWeight: 'bolder' }}></h4>,
                            <h4 style={{ textAlign: 'start', fontWeight: 'normal', paddingLeft: '2em' }}>{part.name}</h4>,
                            <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.amount)}</h4>,
                          ]
                    }
                  />
                ))}
              </React.Fragment>
            );
          }
        })
      ) : (
        <tr>
          <td colSpan={3}></td>
          <TableLoader2 type={'dual_ring'} />
        </tr>
      )}
      <tr style={{ backgroundColor: '#D9D9D9' }}>
        <td colSpan={2} style={{ textAlign: 'center' }}>
          {totalName}
        </td>
        <td colSpan={2}></td>
        <td style={{ textAlign: 'end', padding: '.2em 1em' }}>{ParseToPHP(sumDisbursementsAmount(chartOfAccounts?.results))}</td>
        {isAdmin ? <td></td> : <></>}
      </tr>
    </Table2>
  </React.Fragment>
);
