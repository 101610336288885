import React, { useEffect, useState } from 'react';
import { TableLoader, TableLoader2 } from '../../components/Loader/Spinner';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { ParseToPHP } from '../../utils/Formatter';
import { CashReportsType, VoucherPartReportsType, VoucherReportsType } from '../../utils/Interface';

type VoucherReportsTableType = {
  voucherReports: VoucherReportsType | null;
  isLoading: boolean;
};

const VoucherReportsTable = ({ voucherReports, isLoading }: VoucherReportsTableType) => {
  const part = voucherReports?.results.find((item) => item);
  let noReport = true;
  let counter = 0;

  const debitArray = voucherReports?.results.map((entry) => entry.debit);
  const creditArray = voucherReports?.results.map((entry) => entry.credit);

  const debitTotal = debitArray?.reduce((total, amount) => total + amount, 0);
  const creditTotal = creditArray?.reduce((total, amount) => total + amount, 0);

  return (
    <>
      <Table4
        success
        width="850px"
        header={[
          <div className="text-center">Code</div>,
          <div className="text-center">Account Summaries</div>,
          <div className="text-end">Debit</div>,
          <div className="text-end">Credit</div>,
        ]}
      >
        {isLoading ? (
          part ? (
            voucherReports?.results.map((acc, i) => {
              if (acc.debit > 0 || acc.credit > 0) {
                noReport = false;
                return (
                  <TableRow3
                    key={i}
                    info={[
                      <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{acc.code}</h4>,
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{acc.name}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{acc.debit === 0 ? '---' : ParseToPHP(acc.debit)}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{acc.credit === 0 ? '---' : ParseToPHP(acc.credit)}</h4>,
                    ]}
                  />
                );
              }
              counter++;
              if (counter === voucherReports.count && noReport) {
                return (
                  <TableRow3 colspan={4} info={[<h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>No Voucher Reports!</h4>]} />
                );
              }
            })
          ) : (
            <TableRow3 colspan={4} info={[<h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>No Voucher Reports!!</h4>]} />
          )
        ) : (
          <>
            <tr>
              <TableLoader2 colSpan={4} type="boxbox" />
            </tr>
            <tr>
              <td colSpan={4} style={{ textAlign: 'center', fontWeight: 'normal' }}>
                Loading... Please Select Date From and Date To!
              </td>
            </tr>
          </>
        )}
        {isLoading ? (
          <tr style={{ backgroundColor: '#D9D9D9' }}>
            <td colSpan={2} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
              Total
            </td>

            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(debitTotal ?? 0)}</td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(creditTotal ?? 0)}</td>
          </tr>
        ) : (
          <tr style={{ backgroundColor: '#D9D9D9' }}>
            <td colSpan={2} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
              Total
            </td>

            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(0)}</td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(0)}</td>
          </tr>
        )}
      </Table4>
    </>
  );
};

export default VoucherReportsTable;
