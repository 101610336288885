import { BaseSyntheticEvent, SetStateAction } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import style from './Paginate.module.scss';

export const GET_API_LIMIT = 5;

type GoToPageType = {
  disabled: boolean;
  next: string | null | undefined;
  prev: string | null | undefined;
  currentPage: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
  jumpTo: () => void;
  count: number | undefined;
  limit: number;
};

const GoToPage = ({ disabled, currentPage, setCurrentPage, jumpTo, next, prev, count, limit }: GoToPageType) => {
  const counts = count ?? 0;
  const pages = Math.ceil(counts / limit);

  return (
    <div>
      <strong>Jump to Page:</strong>
      <input
        disabled={pages === 1 || count === 0 || disabled ? true : false}
        style={{ border: 'solid 1px', width: '75px', borderRadius: '10px', padding: '0.5em', margin: '0.2em' }}
        defaultValue={currentPage}
        type="number"
        onChange={({ target }: BaseSyntheticEvent) => {
          setCurrentPage(target.value);
        }}
      />
      <button
        style={
          pages === 1 || count === 0 || disabled
            ? { color: 'white', background: 'gray', width: '35px', borderRadius: '10px', padding: '0.5em', margin: '0.2em' }
            : { color: 'white', background: '#22835f', width: '35px', borderRadius: '10px', padding: '0.5em', margin: '0.2em' }
        }
        onClick={jumpTo}
        disabled={pages === 1 || count === 0 || disabled ? true : false}
      >
        Go
      </button>
    </div>
  );
};

export default GoToPage;
