import moment from 'moment';
import printJS from 'print-js';
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { GetReportApi } from '../api/ReportsApi';
import Container, { Content } from '../components/Container/Container';
import Form, { FormButton, FormInput, FormTextArea } from '../components/Form/Form';
import { Loader } from '../components/Loader/Spinner';
import Select, { Option } from '../components/Select/Select';
import Table from '../components/Table/Table';
import Table2, { TableRow2Header } from '../components/Table/Table2';
import { GetReportList } from '../utils/GetApi';
import Reports_ByList from './Reports/ReportByList';
import Reports_ByMonth from './Reports/ReportByMonth';
import header from '../components/Dashboard/header.module.css';
import Modal from '../components/Modal/Modal';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess, SwalUnderCons } from '../components/Swal/Swal';
import Reports_ByYear from './Reports/ReportByYear';
import { PostFileShare } from '../utils/PostApi';
import axiosInstance, { GetTokens, isAdmin, isTokenValid } from '../utils/axiosInstance';
import { Me } from '../utils/Interface';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';

type DateFilterType = {
  from: string;
  to: string;
};

const NewReports = () => {
  const calendar = new Date();
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const createPDF = useRef<HTMLDivElement>(null);

  const SetMe = async () => {
    const token = GetTokens();
    if (!isTokenValid(token)) return;
    // if(!isAdmin(token)) return
    setMyAccInfo(token);
  };

  useEffect(() => {
    SetMe();
  }, []);

  const [dateFilter, setDateFilter] = useState<DateFilterType>({
    from: `${calendar.getFullYear()}-01-01`,
    to: calendar.toISOString().slice(0, 10),
  });

  // const [dateFilter, setDateFilter] = useState("");

  const HandleDateFilter = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;
    setDateFilter((prev) => ({ ...prev, [name]: value }));
  };

  const [isLoading, setIsLoading] = useState(true);

  const [activeTable, setActiveTable] = useState(0);

  const [reportType, setReportType] = useState<ReportBy>('month');

  const [reportByList, setReportByList] = useState<ReportByList | null>(null);
  const [reportByMonth, setReportByMonth] = useState<ReportByMonth | null>(null);
  const [reportByYear, setReportByYear] = useState<ReportByYear | null>(null);

  // #region Month Filter

  const [monthFilter, setMonthFilter] = useState({
    fromIndex: 0,
    toIndex: 0,
  });

  const [maxDate, setMaxDate] = useState(calendar.toISOString().slice(0, 10));
  const [minDate, setMinDate] = useState(`${calendar.getFullYear()}-01-01`);

  const HandleMonthFilter = (fromDate: string, toDate: string, activeTable: number) => {
    // #region Preset filter for Reports by Month
    const monthFrom = moment(fromDate).month();
    const monthTo = moment(toDate).month();
    const dates = {
      fromIndex: monthFrom,
      toIndex: monthTo + 1,
    };
    setMonthFilter(dates);
    // #endregion

    // #region [by Income & Expense][by Month] use same max and min date
    if (activeTable === 0 || activeTable === 1) {
      const lastDayOfYear = new Date(calendar.getFullYear(), 12, 1);
      setMaxDate(lastDayOfYear.toISOString().slice(0, 10));
      setMinDate(`${calendar.getFullYear()}-01-01`);
    }
    // #endregion

    // #region [by Income & Expense][by Month] use same max and min date
    if (activeTable === 2) {
      const lastDayOfYear = new Date(calendar.getFullYear(), 12, 1);
      setMaxDate(lastDayOfYear.toISOString().slice(0, 10));
      setMinDate(`${calendar.getFullYear()}-01-01`);
    }
    // #endregion
  };

  // #endregion

  const GetReports = async () => {
    setIsLoading(true);
    if (activeTable === 0) {
      setReportType('list');
      const data = await GetReportApi<'list'>(dateFilter.from, dateFilter.to, 'list');
      if (!data) {
        SwalFailedCustom({
          title : "Error",
          text : "There was an error while generating report. Please try again."
        })
        return;
      }
      setReportByList(data);
    }
    if (activeTable === 1) {
      setReportType('month');
      const data = await GetReportApi<'month'>(dateFilter.from, dateFilter.to, 'month');
      if (!data) {
        SwalFailedCustom({
          title : "Error",
          text : "There was an error while generating report. Please try again."
        })
        return;
      }
      setReportByMonth(data);
    }
    if (activeTable === 2) {
      setReportType('year');
      const data = await GetReportApi<'year'>(dateFilter.from, dateFilter.to, 'year');
      if (!data) {
        return;
      }
      setReportByYear(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    HandleMonthFilter(dateFilter.from, dateFilter.to, activeTable);
    GetReports();
  }, [dateFilter, activeTable]);
  // #region Share Report

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const ShareReportHandler = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (!myAccInfo) return;

    SwalLoading();
    const onSuccess = () => {
      SwalSuccess('Shared Succesfuly').then((res) => {
        setModals((prev) => ({ ...prev, shareReport: false }));
      });
      setMessage('');
      setTitle('');
    };
    const onFail = () => {
      SwalFailed('OOPS! something went wrong.').then((res) => {
        setModals((prev) => ({ ...prev, shareReport: false }));
      });
      setMessage('');
      setTitle('');
    };

    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const divContents = createPDF.current;
    if (!divContents) return;

    html2canvas(divContents).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');

      doc.addImage(imgData, 'PNG', 10, 10, 180, 0);

      const pdfBlob = doc.output('blob');

      const shared = new FormData();

      shared.append('name', title);
      shared.append('shared_to', myAccInfo?.church_account);
      shared.append('description', message);
      shared.append('file', pdfBlob);

      await PostFileShare({
        data: shared,
        onSuccess,
        onFail,
      });
    });
  };

  const CloseShareModal = () => {
    setMessage('');
    setTitle('');
    setModals((prev) => ({ ...prev, shareReport: false }));
  };

  // #endregion

  // #region Modal Handler

  const [modals, setModals] = useState({
    shareReport: false,
  });

  const ModalOpenHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: false }));

  // #endregion

  return (
    <>
      <div id="reportsPrint">
        <Container flex justify="space-between">
          <Content>
            <Select
              title="Statement of Income & Expenses"
              name=""
              className="print-input"
              onChange={({ target }: BaseSyntheticEvent) => {
                setActiveTable(parseInt(target.value, 10));
              }}
            >
              <Option value={'0'} title={'by Income and Expense'} />
              <Option value={'1'} title={'by Month'} />
              <Option value={'2'} title={'by Current Month v. Year'} />
            </Select>
          </Content>
          {activeTable !== 2 ? (
            <>
              <Content>
                <FormInput
                  maxValue={dateFilter.to}
                  className="print-input"
                  title={'Date From:'}
                  type={'date'}
                  name={'from'}
                  value={dateFilter.from}
                  onChange={HandleDateFilter}
                />
              </Content>
              <Content>
                <FormInput
                  minValue={dateFilter.from}
                  className="print-input"
                  title={'Date To:'}
                  type="date"
                  name={'to'}
                  value={dateFilter.to}
                  onChange={HandleDateFilter}
                />
              </Content>
            </>
          ) : (
            <></>
          )}
        </Container>
        <Content isLoading={isLoading}>
          {activeTable === 0 && <>{reportByList ? <Reports_ByList list={reportByList} /> : <Loader type={'ring'} />}</>}
          {activeTable === 1 && (
            <>{reportByMonth ? <Reports_ByMonth list={reportByMonth} months={monthFilter} /> : <Loader type={'ring'} />}</>
          )}
          {activeTable === 2 && <>{reportByYear ? <Reports_ByYear list={reportByYear} /> : <Loader type={'ring'} />}</>}
        </Content>
      </div>

      <div
        style={{
          position: 'absolute',
          left: '-9999em',
          width: '1000px',
        }}
      >
        <div ref={createPDF}>
          <Container>
            <Content textAlign="center" padding="0.2em">
              <h3 style={{ fontSize: '25px', color: '#055437', fontWeight: 'bold' }}>Generated Reports</h3>
            </Content>
          </Container>
          <Container flex padding="0">
            <Content>
              {activeTable === 0 && (
                <>
                  <h3 style={{ fontSize: '20px', color: '#055437', fontWeight: 'normal' }}>
                    Statement of Income and Expense: by Income and Expense
                  </h3>
                </>
              )}
              {activeTable === 1 && (
                <>
                  <h3 style={{ fontSize: '20px', color: '#055437', fontWeight: 'normal' }}>Statement of Income and Expense: by Month</h3>
                </>
              )}
              {activeTable === 2 && (
                <>
                  <h3 style={{ fontSize: '20px', color: '#055437', fontWeight: 'normal' }}>
                    Statement of Income and Expense: by Current Month v. Year
                  </h3>
                </>
              )}
            </Content>
          </Container>
          <Container flex padding="0">
            <Content padding="0.2em">
              <h3 style={{ fontSize: '20px', color: '#055437', fontWeight: 'normal' }}>Date From: {dateFilter.from}</h3>
            </Content>
            <Content padding="0.2em">
              <h3 style={{ fontSize: '20px', color: '#055437', fontWeight: 'normal' }}>Date To: {dateFilter.to}</h3>
            </Content>
          </Container>
          <Content padding="0.2em" isLoading={isLoading}>
            {activeTable === 0 && <>{reportByList ? <Reports_ByList list={reportByList} /> : <Loader type={'ring'} />}</>}
            {activeTable === 1 && (
              <>{reportByMonth ? <Reports_ByMonth list={reportByMonth} months={monthFilter} /> : <Loader type={'ring'} />}</>
            )}
            {activeTable === 2 && <>{reportByYear ? <Reports_ByYear list={reportByYear} /> : <Loader type={'ring'} />}</>}
          </Content>
        </div>
      </div>

      <Container flex justify="right">
        <FormButton
          disabled={isLoading}
          margin="0.5em"
          width="unset"
          icon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
          title={'SHARE REPORT'}
          success
          name={'shareReport'}
          onClick={ModalOpenHandler}
          type={'button'}
        />

        <FormButton
          disabled={isLoading}
          width="unset"
          icon={<i className="fa-solid fa-print"></i>}
          title={'PRINT REPORT'}
          success
          name={''}
          onClick={Print}
          type={'button'}
        />
      </Container>
      <Modal
        show={modals.shareReport}
        onClose={CloseShareModal}
        name={'shareReport'}
        title={'Share Report'}
        success
        width="400px"
        footer={[]}
      >
        <Container>
          <Form id={'shareReport'} handleSubmit={ShareReportHandler}>
            <Content>
              <FormInput
                fontSize=".9em"
                fontWeight="300"
                value={`${calendar.toLocaleString('en-US', {
                  dateStyle: 'short',
                })}_StatementOfIncomeAndExpense.pdf`}
                name={''}
                onChange={() => {}}
              />
              <FormInput fontSize=".9em" title="Share to:" name={'shareTo'} value={'Admin'} onChange={() => {}} />
              <FormInput
                fontSize=".9em"
                required
                title="Title / Subject:"
                name={'title'}
                value={title}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setTitle(target.value);
                }}
              />
              <FormTextArea
                title="Message / Description"
                name={'message'}
                value={message}
                rows={5}
                fontSize="1em"
                onChange={({ target }: BaseSyntheticEvent) => {
                  setMessage(target.value);
                }}
              />
            </Content>

            <Content flex justify="space-around">
              <FormButton
                width="40%"
                success
                disabled={isLoading}
                icon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
                title={'Share'}
                name={''}
                type={'submit'}
              />
              <FormButton
                width="40%"
                secondary
                disabled={isLoading}
                icon={<i className="fa-solid fa-xmark"></i>}
                title={'Cancel'}
                name={''}
                onClick={() => {
                  CloseShareModal();
                }}
                type={'button'}
              />
            </Content>
          </Form>
        </Container>
      </Modal>
    </>
  );
};

const Print = async () => {
  printJS({
    printable: 'reportsPrint',
    header: 'Reports',
    headerStyle: `${header.title}`,
    documentTitle: 'Reports',
    type: 'html',
    // targetStyles: ["*"],
    style:
      '*{font-family: Calibri;} .print-right{display : block; text-align: right;} .print-input{border: none;  -moz-appearance: none; -webkit-appearance: none;} select::-ms-expand{display: none;} td, th{border-top:1px #1c5938 solid;-moz-border-top:1px #1c5938 solid;} table{border-collapse: collapse;}',
    showModal: true,
  });
};

export default NewReports;
