import React, { useEffect, useState } from 'react';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReportsType, Me, VoucherItemListType, VoucherType } from '../../utils/Interface';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { PostVoucherType } from '../../utils/PostApi';

type ChequeVoucherPrintType = {
  payTo: string;
  date: string;
  amount: string;
  id: string;
};

const ChequeVoucherPrint = ({ id, payTo, date, amount }: ChequeVoucherPrintType) => {
  const writtenNumber = require('written-number');

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: '-9999em',
          width: '1000px',
        }}
      >
        <pre id={id}>
          <div style={{ display: 'flex', textAlign: 'start', width: '19.3cm' }}>
            <div style={{ marginRight: 'auto', color: '#055437' }}>
              <p style={{ paddingTop: '1.5cm', paddingLeft: '2.4cm', fontSize: '13px' }}>{payTo.toUpperCase()}</p>
              <p style={{ paddingLeft: '1.6cm', fontSize: '13px' }}>{writtenNumber(amount).toUpperCase()} PESO ONLY</p>
            </div>
            <div style={{ color: '#055437' }}>
              <p style={{ paddingTop: '0.8cm', paddingRight: '2.3cm', fontSize: '13px' }}>{FormatDate(date ?? 'No Date')}</p>
              <p style={{ fontSize: '13px' }}>{ParseToPHP(parseFloat(amount ?? '0'))}</p>
            </div>
          </div>
        </pre>
      </div>
    </>
  );
};

export default ChequeVoucherPrint;
