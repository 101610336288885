// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_container__LOX6d {\n  padding: 0.5em 0;\n}\n\n.header_title__OBO6A {\n  font-family: 'IBM Plex Sans', sans-serif;\n  margin-top: 20px;\n  font-weight: 600;\n  font-size: 2em;\n  /* color : #00b894; */\n  color: #055437 !important;\n  text-align: left;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dashboard/header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,yBAAyB;EACzB,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".container {\n  padding: 0.5em 0;\n}\n\n.title {\n  font-family: 'IBM Plex Sans', sans-serif;\n  margin-top: 20px;\n  font-weight: 600;\n  font-size: 2em;\n  /* color : #00b894; */\n  color: #055437 !important;\n  text-align: left;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "header_container__LOX6d",
	"title": "header_title__OBO6A"
};
export default ___CSS_LOADER_EXPORT___;
