import axiosInstance from './axiosInstance';

const GetDashboardApi = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/dashboard/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      const data = Object.values(res.data);
      return data as string[];
    })
    .catch((err) => null);
};

type DashboardIncomeExpense = {
  expense: string;
  income: string;
  month: Month;
};

export const GetDashboardIncomeExpense = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/dashboard-income-expense/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as DashboardIncomeExpense[])
    .catch((err) => null);
};

export default GetDashboardApi;
