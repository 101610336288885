import React, { useEffect, useState } from 'react';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import {
  CashReportsType,
  Me,
  PettyVoucherFormStatusType,
  SelectedPettyVoucherFormItem,
  VoucherItemListType,
  VoucherItemType,
  VoucherType,
} from '../../utils/Interface';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { TableLoader2 } from '../../components/Loader/Spinner';
import VoidSticker from '../../components/Modal/VoidSticker';
import Modal from '../../components/Modal/Modal';
import Table2, { TableRow2 } from '../../components/Table/Table2';
import Container, { Content } from '../../components/Container/Container';
import { FormButton } from '../../components/Form/Form';

type PettyCashVoucherFormPrintType = {
  showAdd: boolean;
  CloseVoucherModal: () => void;
  id: string;
  payee: string;
  amountInPeso: string;
  date: string;
  description: string;
  cv: string | undefined;
  selectedPettyCash: (SelectedPettyVoucherFormItem & PettyVoucherFormStatusType)[];
  totalDebit: number;
  totalCredit: number;
  PrintCheque: () => Promise<void>;
  PrintVoucher: () => Promise<void>;
};

const PettyCashVoucherFormPrint = ({
  showAdd,
  CloseVoucherModal,
  id,
  payee,
  amountInPeso,
  date,
  description,
  cv,
  selectedPettyCash,
  totalDebit,
  totalCredit,
  PrintCheque,
  PrintVoucher,
}: PettyCashVoucherFormPrintType) => {
  const writtenNumber = require('written-number');

  return (
    <>
      <Modal
        title={`Cash Voucher`}
        success
        show={showAdd}
        onClose={() => {
          CloseVoucherModal();
        }}
        name={''}
        width={'800px'}
      >
        <pre id={id}>
          <div style={{ display: 'flex', textAlign: 'start' }}>
            <div style={{ marginRight: 'auto', color: '#055437' }}>
              <p>Pay To: {payee}</p>
              <p>Amount in Words: {writtenNumber(amountInPeso)} peso only</p>
              <p>Amount in Peso: {ParseToPHP(parseFloat(amountInPeso))}</p>
            </div>
            <div style={{ color: '#055437' }}>
              <p>Date: {FormatDate(date ?? 'No Date')}</p>
              <p>Description: {description}</p>
              <p>CV No.: {cv}</p>
            </div>
          </div>
          <div>
            <Table2 header={['Code', 'Account Title', 'Debit', 'Credit']} success>
              {selectedPettyCash.map((item, i) => (
                <TableRow2
                  key={i}
                  info={[
                    <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{item.particular_code}</h4>,
                    <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{item.particular}</h4>,
                    <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>
                      {ParseToPHP(parseFloat(item.debit === '' ? '0' : item.debit))}
                    </h4>,
                    <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>
                      {ParseToPHP(parseFloat(item.credit === '' ? '0' : item.credit))}
                    </h4>,
                  ]}
                />
              ))}
              <TableRow2
                info={[
                  <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Total :</h4>,
                  <h4></h4>,
                  <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>{ParseToPHP(totalDebit)}</h4>,
                  <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>{ParseToPHP(totalCredit)}</h4>,
                ]}
              />
            </Table2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
            <p>Prepared By:</p>
            <p>Checked By:</p>
            <p>Approved By:</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
            <p>_______________</p>
            <p>_______________</p>
            <p>_______________</p>
          </div>
        </pre>
        <Container flex>
          <Content padding="0 1em">
            <FormButton
              width="18em"
              success
              title={'PRINT CHEQUE VOUCHER'}
              name={''}
              onClick={() => {
                PrintCheque();
              }}
              type={'submit'}
            />
          </Content>
          <Content padding="0 1em">
            <FormButton
              width="16em"
              success
              title={'PRINT CASH VOUCHER'}
              name={''}
              onClick={() => {
                PrintVoucher();
              }}
              type={'submit'}
            />
          </Content>
          <Content padding="0 1em">
            <FormButton
              width="7em"
              secondary
              success
              title={'CLOSE'}
              name={''}
              onClick={() => {
                CloseVoucherModal();
              }}
              type={'button'}
            />
          </Content>
        </Container>
      </Modal>
    </>
  );
};

export default PettyCashVoucherFormPrint;
