// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".voidSticker_container__cU\\+rV {\n  position: relative;\n}\n\n.voidSticker_centeredDiv__6JQqg {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, 30%);\n  z-index: 1;\n}\n\n.voidSticker_slanted__msJ7v {\n  font-family: Helvetica, Arial, sans-serif;\n  font-weight: bold;\n  padding: 4px 8px;\n  color: rgb(255, 104, 104);\n  width: 450px;\n  border: solid 5px;\n  font-size: 100px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Modal/voidSticker.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,+BAA+B;EAC/B,UAAU;AACZ;;AAEA;EACE,yCAAyC;EACzC,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".container {\n  position: relative;\n}\n\n.centeredDiv {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, 30%);\n  z-index: 1;\n}\n\n.slanted {\n  font-family: Helvetica, Arial, sans-serif;\n  font-weight: bold;\n  padding: 4px 8px;\n  color: rgb(255, 104, 104);\n  width: 450px;\n  border: solid 5px;\n  font-size: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "voidSticker_container__cU+rV",
	"centeredDiv": "voidSticker_centeredDiv__6JQqg",
	"slanted": "voidSticker_slanted__msJ7v"
};
export default ___CSS_LOADER_EXPORT___;
