import React, { useEffect, useState } from 'react';
import { TableLoader2 } from '../../components/Loader/Spinner';
import VoidSticker from '../../components/Modal/VoidSticker';
import Table2, { TableRow2 } from '../../components/Table/Table2';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReportsType, Me, VoucherItemListType, VoucherItemType, VoucherType } from '../../utils/Interface';

type VoucherViewType = {
  selectedVoucher: VoucherType | null;
  processedVoucherItem: VoucherItemType | null;
  myAccInfo: Me | null;
  isLoading: boolean;
};

const VoucherView = ({ selectedVoucher, processedVoucherItem, myAccInfo, isLoading }: VoucherViewType) => {
  const writtenNumber = require('written-number');

  return (
    <>
      <VoidSticker isVoid={selectedVoucher?.void} />
      {selectedVoucher?.void ? (
        <div style={{ display: 'grid', justifyContent: 'start' }}>
          <div style={{ textAlign: 'start' }}>
            <span style={{ color: 'red' }}>This transaction was voided!</span>
          </div>
          <div>
            <span style={{ color: '#055437' }}>
              <strong>Purpose of void:</strong>
              {selectedVoucher.purpose_of_void}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div style={{ display: 'flex', textAlign: 'start' }}>
        <div style={{ marginRight: 'auto', color: '#055437' }}>
          <p>Pay To: {selectedVoucher?.pay_to}</p>
          <p>Amount in Peso: {ParseToPHP(parseFloat(selectedVoucher?.amount ?? ''))}</p>
          <p>Amount in Words: {writtenNumber(selectedVoucher?.amount ?? '')} peso only</p>
        </div>
        <div style={{ color: '#055437' }}>
          <p>Date: {FormatDate(selectedVoucher?.date_time ?? 'No Date')}</p>
          <p>Description: {selectedVoucher?.description}</p>
          <p>CV No.: {selectedVoucher?.cv_no}</p>
        </div>
      </div>
      <div>
        <Table2 header={['Code', 'Account Title', 'Debit', 'Credit']} success>
          {isLoading ? (
            processedVoucherItem?.voucher_items.map((item, i) => (
              <TableRow2
                key={i}
                info={[
                  <h4 className="text-center font-normal">{item.particular_code}</h4>,
                  <h4 className="text-center font-normal">{item.particular_name}</h4>,
                  <h4 className="text-end font-normal">{ParseToPHP(item.debit)}</h4>,
                  <h4 className="text-end font-normal">{ParseToPHP(item.credit)}</h4>,
                ]}
              />
            ))
          ) : (
            <tr>
              <TableLoader2 colSpan={4} type="boxbox" />
            </tr>
          )}
          <TableRow2
            info={[
              <h4 className="text-center font-bold">Total:</h4>,
              <h4 className="text-center font-normal"></h4>,
              <h4 className="text-end font-bold">{isLoading ? ParseToPHP(processedVoucherItem?.total_debit ?? 0) : ParseToPHP(0)}</h4>,
              <h4 className="text-end font-bold">{isLoading ? ParseToPHP(processedVoucherItem?.total_credit ?? 0) : ParseToPHP(0)}</h4>,
            ]}
          />
        </Table2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
        <p>Prepared By:</p>
        <p>Checked By:</p>
        <p>Approved By:</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
        <p>{myAccInfo?.username}</p>
        <p>---------------</p>
        <p>---------------</p>
      </div>
    </>
  );
};

export default VoucherView;
