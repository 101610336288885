import Swal from 'sweetalert2';
import style from './test.module.css';

export const SwalSuccess = (message: string) =>
  Swal.fire({
    iconHtml: '<img src="check.png" width="70px" height="70px">',
    customClass: {
      icon: style.noBorder,
    },
    confirmButtonColor: '#055437',
    background: '#298D69',
    color: '#ffff',
    title: 'Successful',
    text: message,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

// #region KR CUSTOM SWAL

type SwalCustomType = {
  title?: string;
  text?: string;
};
export const SwalSuccessCustom = ({ title, text }: SwalCustomType) =>
  Swal.fire({
    iconHtml: '<img src="check.png" width="70px" height="70px">',
    customClass: {
      icon: style.noBorder,
    },
    confirmButtonColor: '#055437',
    background: '#298D69',
    color: '#ffff',
    title: title ?? 'Successful!',
    text: text,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

export const SwalFailedCustom = ({ title, text }: SwalCustomType) =>
  Swal.fire({
    icon: 'error',
    confirmButtonColor: '#055437',
    background: '#298D69',
    color: '#ffff',
    title: title ?? 'Failed!',
    text: text,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

// #endregion

export const SwalFailed = (message: string) =>
  Swal.fire({
    icon: 'warning',
    confirmButtonColor: '#055437',
    background: '#298D69',
    color: '#ffff',
    title: 'Failed',
    text: message,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

export const SwalLoading = () =>
  Swal.fire({
    title: 'Loading',
    html: 'Please wait a moment',
    icon: 'info',
    background: '#298D69',
    color: '#ffff',
    showConfirmButton: false,
    allowOutsideClick: false,
  });

export const SwalNotAuthorized = (message: string) =>
  Swal.fire({
    icon: 'warning',
    confirmButtonColor: '#055437',
    background: '#298D69',
    color: '#ffff',
    title: "You're Not Authorized",
    text: message,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

export const SwalUnderCons = (message: string) =>
  Swal.fire({
    icon: 'warning',
    confirmButtonColor: '#055437',
    background: '#298D69',
    color: '#ffff',
    title: 'Under Development',
    text: message,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
