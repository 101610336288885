import style from './spinner.module.css';

type Loader = {
  text?: string;
  type: 'ring' | 'dual_ring' | 'roller' | 'ellipsis' | 'ripple' | 'boxbox';
  colSpan?: number;
};

export const Loader = ({ text, type }: Loader) => {
  switch (type) {
    case 'dual_ring':
      return <div className={style.lds_dual_ring}></div>;
    case 'ring':
      return (
        <div className={style.lds_ring}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    case 'roller':
      return (
        <div className={style.lds_roller}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    case 'ellipsis':
      return (
        <div className={style.lds_ellipsis}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    case 'ripple':
      return (
        <div className={style.lds_ripple}>
          <div></div>
          <div></div>
        </div>
      );
    case 'boxbox':
      return (
        <div className={style.lds_boxbox}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    default:
      return (
        <div className={style.lds_ring}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
  }
};

export const TableLoader = ({ text, type, colSpan }: Loader) => (
  <tr style={{ justifyContent: 'center' }}>
    <td colSpan={colSpan} style={{ paddingTop: '1em' }}>
      <Loader type={type} />
    </td>
  </tr>
);

export const TableLoader2 = ({ text, type, colSpan }: Loader) => (
  <td colSpan={colSpan} style={{ paddingTop: '1em', textAlign: 'center' }}>
    <Loader type={type} />
  </td>
);

export const TableLoader3 = ({ text, type }: Loader) => (
  <div style={{ paddingTop: '1em', textAlign: 'center', width: '100%' }}>
    <Loader type={type} />
  </div>
);
