import { MouseEventHandler, ReactNode } from 'react';
import { ColorType } from '../Interface/Pages';
import { GetColorType } from '../styles/background';
import { GetColorTypeButton } from '../styles/button';
import style from './modal.module.css';

type ModalType = {
  bM?: string | number;
  bP?: string | number;
  hM?: string | number;
  hP?: string | number;
  fM?: string | number;
  fP?: string | number;
  show: boolean;
  onClose?: MouseEventHandler;
  name: string;
  title?: ReactNode;
  children?: ReactNode;
  width?: string | number;
  height?: string | number;
  header?: JSX.Element[];
  footer?: JSX.Element[];
  background?: string;
  mxw?: string | number;
  mxh?: string | number;
  bHead?: JSX.Element;
  hide?: boolean;
} & ColorType;

type ModalButtonType = {
  key: string | number;
  onClick?: MouseEventHandler;
  text: string;
  name?: string;
  form?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  disabled?: boolean;
} & ColorType;

export const ModalButton = ({ onClick, text, primary, secondary, success, warning, danger, name, form }: ModalButtonType) => (
  <button
    className={`${style.button} ${GetColorTypeButton({ primary, secondary, success, warning, danger })}`}
    name={name ? name : ''}
    form={form ? form : ''}
    type={form ? 'submit' : 'button'}
    onClick={onClick}
  >
    {text}
  </button>
);

export const ModalFooterButton = ({
  onClick,
  text,
  primary,
  secondary,
  success,
  warning,
  danger,
  name,
  form,
  padding,
  border,
  borderRadius,
  disabled,
}: ModalButtonType) => (
  <button
    className={
      disabled
        ? `${style.footerDisabledButton} ${GetColorTypeButton({ primary, secondary, success, warning, danger })}`
        : `${style.footerButton} ${GetColorTypeButton({ primary, secondary, success, warning, danger })}`
    }
    name={name ?? ''}
    form={form ? form : ''}
    type={form ? 'submit' : 'button'}
    onClick={onClick}
    disabled={disabled}
    style={{ border: border, borderRadius: borderRadius, padding: padding }}
  >
    {text}
  </button>
);

const Modal = ({
  show,
  children,
  onClose,
  width,
  height,
  header,
  title,
  footer,
  primary,
  secondary,
  success,
  warning,
  danger,
  name,
  background,
  mxh,
  mxw,
  bM,
  bP,
  hM,
  hP,
  fM,
  fP,
  bHead,
  hide,
}: ModalType) => (
  <>
    {show ? (
      <div className={`${style.main}`} style={{ display: show ? 'flex' : 'none' }}>
        <div className={`${style.modal}`} style={{ width, height, background, maxWidth: mxw, maxHeight: mxh }}>
          <div
            className={`${style.header} ${GetColorType({ primary, secondary, success, warning, danger })}`}
            style={{ margin: hM, padding: hP }}
          >
            {title}
            {header && header.map((item) => item)}
            {onClose && (
              <button className={style.close} name={name} onClick={onClose}>
                {hide ? '' : 'X'}
              </button>
            )}
          </div>
          <div className={style.fixedHead}>{bHead}</div>
          <div className={style.body} style={{ margin: bM, padding: bP }}>
            {children}
          </div>
          <div className={style.footer} style={{ margin: fM, padding: fP }}>
            {footer && footer.map((item) => item)}
          </div>
        </div>
      </div>
    ) : (
      <></>
    )}
  </>
);

export default Modal;
