import Container, { Content } from '../../components/Container/Container';
import VoidSticker from '../../components/Modal/VoidSticker';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReceiptType, Me, ProcessedCashReceiptType } from '../../utils/Interface';

type PrintReceiptTableType = {
  selectedCashReceipt: CashReceiptType | null;
  myAccInfo: Me | null;
};

const PrintReceiptTable = ({ selectedCashReceipt, myAccInfo }: PrintReceiptTableType) => (
  <>
    <VoidSticker isVoid={selectedCashReceipt?.void} />
    <Container flex>
      <Content grid>
        <h4 style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '25px', color: '#055437' }}>{selectedCashReceipt?.church.name}</h4>
        <h4 style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '20px', color: '#055437' }}>
          {selectedCashReceipt?.church.location}
        </h4>
      </Content>
    </Container>
    {selectedCashReceipt?.void ? (
      <div style={{ display: 'grid', justifyContent: 'start', paddingLeft: '1em' }}>
        <div style={{ textAlign: 'start' }}>
          <span style={{ color: 'red' }}>This transaction was voided!</span>
        </div>
        <div>
          <span style={{ color: '#055437' }}>
            <strong>Purpose of void:</strong>
            {selectedCashReceipt.purpose_of_void}
          </span>
        </div>
      </div>
    ) : (
      <></>
    )}
    <Table3 width="900px" header={[]}>
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', color: '#055437' }}>Received From:</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal', color: '#055437' }}>{selectedCashReceipt?.received_from}</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'end', fontWeight: 'bold', color: '#055437' }}>Date:</h4>,
          <h4 style={{ textAlign: 'end', fontWeight: 'normal', color: '#055437' }}>{FormatDate(selectedCashReceipt?.date_time ?? '')}</h4>,
        ]}
      />
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', color: '#055437' }}>Address:</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal', color: '#055437' }}>{selectedCashReceipt?.address}</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'end', fontWeight: 'bold', color: '#055437' }}>Amount in Peso:</h4>,
          <h4 style={{ textAlign: 'end', fontWeight: 'normal', color: '#055437' }}>
            {ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}
          </h4>,
        ]}
      />
    </Table3>
    <Table4 width="900px" header={['In Payment of:', 'Amount', 'Form of Payment', '', 'Total Amount']}>
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'center', fontWeight: 'normal', color: '#055437' }}>{selectedCashReceipt?.payment_for}</h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal', color: '#055437' }}>
            {ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}
          </h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal', color: '#055437' }}>{selectedCashReceipt?.form_of_payment}</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal', color: '#055437' }}>
            {ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}
          </h4>,
        ]}
      />
      <TableRow4
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', color: '#055437' }}>Total Amount</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'center', fontWeight: 'normal', color: '#055437' }}>
            {ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}
          </h4>,
        ]}
      />
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', color: '#055437' }}>Received by:</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'end', fontWeight: 'bold', color: '#055437' }}>Acknowledgement Receipt</h4>,
        ]}
      />
      <TableRow3
        info={[
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', color: '#055437' }}>{myAccInfo?.username}</h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
          <h4 style={{ textAlign: 'end', fontWeight: 'bold', color: '#055437' }}>No.{selectedCashReceipt?.ar_no}</h4>,
        ]}
      />
    </Table4>
  </>
);

export default PrintReceiptTable;
