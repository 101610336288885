import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GetTokens, isTokenValid } from '../../utils/axiosInstance';
import { GetMe } from '../../utils/GetApi';
import { Me } from '../../utils/Interface';
import { Responsive } from '../Interface/Pages';
import { SwalFailed, SwalUnderCons } from '../Swal/Swal';
import style from './sidebar.module.css';

interface Sidebar extends Responsive {
  hidden?: Boolean | undefined;
}

interface SideLinkBase {
  icon: ReactElement;
  title: string;
}

interface SideLink extends SideLinkBase {
  path: string;
  activePath: string;
}
interface SideDropdown extends SideLinkBase {
  links: ReactElement[];
}

const SideLink = ({ icon, title, path, activePath }: SideLink) => {
  const active = path === activePath ? style.active : style.inactive;
  return (
    <div className={`${style.link} ${active}`}>
      <div className={style.icon}>{icon}</div>
      <Link className={style.title} to={`/${path}`}>
        {title}
      </Link>
    </div>
  );
};

const SideDropdown = ({ icon, title, links }: SideDropdown) => {
  const [isDropped, setIsDropped] = useState(false);

  return (
    <div className={style.dropdown}>
      <div className={style.link}>
        <button
          onClick={() => {
            setIsDropped((prev) => !prev);
          }}
        >
          <div className={style.icon}>{icon}</div>
          <a className={style.title}>
            {title}
            <div className={`${isDropped ? style.arrUnhidden : style.arrHidden} ml-auto mr-1`}>
              <i className="fas fa-chevron-right"></i>
            </div>
          </a>
        </button>
      </div>
      <div className={`${style.dropdownLinks} ${isDropped ? style.unhidden : style.hidden}`}>{links.map((link) => link)}</div>
    </div>
  );
};

type TempSideLinkType = {
  onClick: () => void;
} & SideLinkBase;

const TempSideLink = ({ icon, title, onClick }: TempSideLinkType) => (
  <div className={`${style.tempLink}`}>
    <div className={style.icon}>{icon}</div>
    <button className={style.title} onClick={onClick}>
      {title}
    </button>
  </div>
);

const Sidebar = ({ view, expander, hidden }: Sidebar) => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [expand, setExpand] = useState(true);
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);

  const newView = view ? style.hide : style.show;
  const newExpand = expand ? style.mainExpand : style.mainCollapse;

  const OnExpand = () => {
    setExpand((prev) => !prev);
    expander && expander();
  };

  const [isChurchEnable, setIsChurchEnable] = useState(false);

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setIsChurchEnable(true);
      }
      setMyAccInfo(tokens);
    }
  };

  useEffect(() => {
    SetMe();
  }, []);

  return !hidden ? (
    <div className={`${style.container} ${newView}`}>
      <div className={`${style.main} ${newExpand}`}>
        {/* <div className={`${style.buttonContainer}`}>
				<button onClick={()=>OnExpand()}
					className={`${expand ? style.expand : style.collapse}`}>
						<i className="fa-solid fa-caret-right"></i>
				</button>
			</div> */}
        <div className={style.header}>
          <div className={style.imgContainer}>
            <img src="./profileIcon.png" width="50px" height="50px" />
          </div>
          <div className="flex items-center">
            <h3 style={{ color: 'white' }}>Good Day, {myAccInfo ? myAccInfo.username : 'User'}</h3>
          </div>
        </div>
        <div className={style.listLink}>
          <SideLink activePath={path} icon={<i className="fa-solid fa-house-user"></i>} path={'home'} title={'Home'} />
          {isChurchEnable && (
            <SideLink activePath={path} icon={<i className="fas fa-solid fa-cross"></i>} path={'churches'} title={'Parishes'} />
          )}
          <SideDropdown
            links={[
              <SideLink
                key={1}
                activePath={path}
                icon={<i className="fa-solid fa-circle-dollar-to-slot"></i>}
                path={'income-and-expense'}
                title={'Income and Expense'}
              />,
              <SideLink
                key={2}
                activePath={path}
                icon={<i className="fa-solid fa-scale-balanced"></i>}
                path={'balance-sheet'}
                title={'Balance Sheet'}
              />,
            ]}
            icon={<i className="fa-solid fa-list"></i>}
            title={'Chart of Accounts'}
          />
          <SideDropdown
            links={[
              <SideLink
                key={3}
                activePath={path}
                icon={<i className="fa-solid fa-sack-dollar"></i>}
                path={'cash-receipts'}
                title={'Cash Receipts'}
              />,
              <SideDropdown
                key={4}
                links={[
                  <SideLink
                    key={5}
                    activePath={path}
                    icon={<i className="fa-solid fa-money-check-dollar"></i>}
                    path={'voucher'}
                    title={'Cheque Voucher'}
                  />,
                  <SideLink
                    key={6}
                    activePath={path}
                    icon={<i className="fa-solid fa-newspaper"></i>}
                    path={'journal-voucher'}
                    title={'Journal Voucher'}
                  />,
                  <SideLink
                    key={7}
                    activePath={path}
                    icon={<i className="fa-solid fa-money-bill"></i>}
                    path={'petty-cash-voucher'}
                    title={'Petty Cash Voucher'}
                  />,
                  // <TempSideLink key={6} icon={<i className="fa-solid fa-newspaper"></i>} title={"Journal Voucher"}
                  // onClick={()=>{
                  // 	SwalUnderCons("This feature is under development.")
                  // }}/>,
                  // <TempSideLink key={7} icon={<i className="fa-solid fa-money-bill"></i>} title={"Petty Cash Voucher"}
                  // onClick={()=>{
                  // 	SwalUnderCons("This feature is under development.")
                  // }}/>,
                ]}
                icon={<i className="fa-solid fa-list"></i>}
                title={'Vouchers'}
              />,
              <SideLink key={8} activePath={path} icon={<i className="fa-solid fa-chart-simple"></i>} path={'reports'} title={'Reports'} />,
              <SideLink key={9} activePath={path} icon={<i className="fa-solid fa-file"></i>} path={'e-files'} title={'E-files'} />,
              // <TempSideLink key={4} icon={<i className="fa-solid fa-chart-simple"></i>} title={"Voucher"}
              // 	onClick={()=>{
              // 		SwalUnderCons("This feature is under development.")
              // 	}}/>,
              // <TempSideLink key={4} icon={<i className="fa-solid fa-chart-simple"></i>} title={"Reports"}
              // 	onClick={()=>{
              // 		SwalUnderCons("This feature is under development.")
              // 	}}/>,
              // <TempSideLink key={5} icon={<i className="fa-solid fa-file"></i>} title={"E-files"}
              // 	onClick={()=>{
              // 		SwalUnderCons("This feature is under development.")
              // 	}}/>,
              // <TempSideLink key={6} icon={<i className="fa-solid fa-file"></i>} title={"E-files"}
              // 	onClick={()=>{
              // 		SwalUnderCons("This feature is under development.")
              // 	}}/>,
            ]}
            icon={<i className="fa-solid fa-calculator"></i>}
            title={'Accounting'}
          />
          {/* <SideLink ac 
				tivePath={path} icon={<img width={"30px"} src={"./sample.png"}/>} path={""} title={"Link A"}/>
				<SideLink activePath={path} icon={<img width={"30px"} src={"./sample.png"}/>} path={""} title={"Link B"}/> */}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Sidebar;
