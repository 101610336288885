import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { ColorType } from '../Interface/Pages';
import { GetColorType } from '../styles/background';
import { GetColor } from '../styles/color';
import style from './table4.module.css';

interface Table3Type extends ColorType {
  header: string[] | JSX.Element[];
  children: ReactNode;
  width?: string;
  height?: string;
}

export const TableRow4 = ({ info, padding, colspan }: { info: ReactElement[]; padding?: string; colspan?: number }) => (
  <tr className={style.row}>
    {info.map((data, i) => (
      <td colSpan={colspan} key={i} className={style.data} style={{ padding: padding }}>
        {data}
      </td>
    ))}
  </tr>
);

interface TableRow3HeaderType {
  info: ReactElement[];
}

export const TableRow4Header = ({ info }: TableRow3HeaderType) => (
  <tr className={`${style.rowHeader}`}>
    {info.map((data, i) => (
      <td key={i} className={style.data}>
        {data}
      </td>
    ))}
  </tr>
);

interface TableAction3Type {
  icon: ReactElement;
  onClick: MouseEventHandler;
}

export const TableAction4 = ({ icon, onClick }: TableAction3Type) => (
  <button className={style.actionButton} onClick={onClick}>
    {icon}
  </button>
);

const Table4 = ({ header, success, secondary, primary, warning, danger, width, height, children }: Table3Type) => (
  <div className={style.container} style={width ? { maxWidth: width } : {}}>
    <table className={GetColor({ success, secondary, primary, warning, danger })} style={{ width: '100%' }}>
      <thead className={`${style.header} ${GetColorType({ success, secondary, primary, warning, danger })}`}>
        <tr>
          {header.map((head, i) => (
            <th key={i} className={style.head}>
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={style.body} style={height ? { maxHeight: height } : {}}>
        {children}
      </tbody>
    </table>
  </div>
);

export default Table4;
