import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './pages/login';
import Home from './pages/home';
import Churches from './pages/churches';
import Entries from './pages/entries';
import Reports from './pages/reports';
import AccountingList from './pages/AccountingList';
import Efiles from './pages/EFiles';
import Voucher from './pages/voucher';
import ChartOfAccounts from './pages/ChartOfAccounts';
import { AuthProvider } from './context/AuthContext';
import TestPage from './pages/TestPage';
import UserProvider, { AppContext } from './utils/context';
import NewReports from './pages/newReports';
import AdminRoute from './utils/PrivateRoute';
import ForgotPassword from './pages/ForgotPassword';
import CashReceipts from './pages/CashReceipts';
import IncomeAndExpense from './pages/IncomeAndExpense';
import BalanceSheet from './pages/BalanceSheet';
import ProfDash from './components/Dashboard/ProfDash';
import UserProfile from './pages/UserProfile';
import JournalVoucher from './pages/journalVoucher';
import PettyCashVoucher from './pages/pettyCashVoucher';
import ErrorPage from './components/Pages/ErrorPage';

function App() {
  return (
    <div className="App">
      {/* <Routes>
        <Route path='/'
            element={<Dashboard Component={Login} noBurger noNavbar noSidebar noTitle/>}/>
        <Route path='/home'
            element={<Dashboard Component={Home} title={"Dashboard"}/>}>
        </Route>
        <Route path='/accounting-list'
            element={<PrivateRoute><Dashboard Component={AccountingList} title={"AccountingList"}/></PrivateRoute>}>
        </Route>
        <Route path='/churches'
            element={<PrivateRoute><Dashboard Component={Churches} title={"Churches"}/></PrivateRoute>}>
        </Route>
        <Route path='/entries'
          element={<PrivateRoute><Dashboard Component={Entries} title="Entries"/></PrivateRoute>}>
        </Route>
        <Route path='/reports'
          element={<PrivateRoute><Dashboard Component={Reports} title="Reports"/></PrivateRoute>}>
        </Route>
        <Route path='/e-files'
          element={<PrivateRoute><Dashboard Component={Efiles} title="Shared Files"/></PrivateRoute>}>
        </Route>
        <Route path='/voucher'
          element={<PrivateRoute><Dashboard Component={Voucher} title="Voucher"/></PrivateRoute>}>
        </Route>
        
      </Routes> */}
      <UserProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/password-reset/complete" element={<ForgotPassword />}></Route>
          <Route path="super-profile" element={<ProfDash Component={UserProfile} title={'List of Parishes Profile'} />}></Route>
          <Route path="user-profile" element={<Dashboard Component={UserProfile} title={'Parish Profile'} />}></Route>
          <Route path="/home" element={<Dashboard Component={Home} title={'Dashboard'} />}></Route>
          <Route path="/chart-of-accounts" element={<Dashboard Component={ChartOfAccounts} title={'Chart of Accounts'} />}></Route>
          <Route path="/accounting-list" element={<Dashboard Component={AccountingList} title={'AccountingList'} />}></Route>
          <Route
            path="/churches"
            element={
              <AdminRoute>
                <Dashboard Component={Churches} title={'Parishes'} />
              </AdminRoute>
            }
          ></Route>
          <Route path="/cash-receipts" element={<Dashboard Component={CashReceipts} title="Cash Receipts" />}></Route>
          <Route path="/income-and-expense" element={<Dashboard Component={IncomeAndExpense} title="Income and Expense" />}></Route>
          <Route path="/balance-sheet" element={<Dashboard Component={BalanceSheet} title="Balance Sheet" />}></Route>
          {/* 
          <Route path='/reports'
            element={<Dashboard Component={Reports} title="Reports" />}>
          </Route>
           */}
          <Route path="/reports" element={<Dashboard Component={NewReports} title="Reports" />}></Route>
          <Route
            path="/e-files"
            // element={<Dashboard Component={TestPage} title="Test Page" />}>
            element={<Dashboard Component={Efiles} title="E - files" />}
          ></Route>
          <Route path="/voucher" element={<Dashboard Component={Voucher} title="Voucher" />}></Route>
          <Route path="/journal-voucher" element={<Dashboard Component={JournalVoucher} title="Journal Voucher" />}></Route>
          <Route path="/petty-cash-voucher" element={<Dashboard Component={PettyCashVoucher} title="Petty Cash Voucher" />}></Route>
          <Route path="*" element={<ErrorPage code={404} message={'PAGE NOT FOUND'} />} />
        </Routes>
      </UserProvider>
    </div>
  );
}

export default App;
