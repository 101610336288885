import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import Container, { Content } from '../../components/Container/Container';
import { TableLoader, TableLoader2 } from '../../components/Loader/Spinner';
import Modal from '../../components/Modal/Modal';
import Select, { Option } from '../../components/Select/Select';
import Table2, { TableRow2 } from '../../components/Table/Table2';
import Table3 from '../../components/Table/Table3';
import Table4 from '../../components/Table/Table4';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReportsType, Me, VoucherItemListType, VoucherItemType, VoucherType } from '../../utils/Interface';

type GeneralLedgerTableType = {
  generalLedger: GeneralLedgerType | null;
  showGeneralLedger: boolean;
  isLoading: boolean;
  setShowGeneralLedger: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTable: React.Dispatch<React.SetStateAction<GeneralLedgerCategoryType | undefined>>;
  activeTable: GeneralLedgerCategoryType | undefined;
  CloseGeneralLedgerModal: () => void;
};

const GeneralLedgerTable = ({
  generalLedger,
  showGeneralLedger,
  isLoading,
  setShowGeneralLedger,
  setActiveTable,
  activeTable,
  CloseGeneralLedgerModal,
}: GeneralLedgerTableType) => {
  const ledgerData = useMemo(() => {
    const list = generalLedger ? generalLedger.general_ledger : [];

    const debits = list.map((item) => item.debit);
    const credits = list.map((item) => item.credit);

    const totalDebit = debits.reduce((total, currentVal) => (total = total + currentVal), 0);
    const totalCredit = credits.reduce((total, currentVal) => (total = total + currentVal), 0);

    const count = generalLedger ? generalLedger.transactions_count : 0;

    return {
      list,
      totalDebit,
      totalCredit,
      count,
    };
  }, [generalLedger]);

  return (
    <>
      <Modal
        title={generalLedger?.name}
        success
        show={showGeneralLedger}
        width="800px"
        onClose={() => {
          CloseGeneralLedgerModal();
        }}
        name={''}
      >
        <Content margin=" 0.3em 0.3em 0.7em 0">
          <Select
            name=""
            placeHolder={'Choose Category'}
            onChange={({ target }: BaseSyntheticEvent) => {
              setActiveTable(target.value);
            }}
          >
            <Option value={'cash-receipt'} title={'Cash Receipt'} />
            <Option value={'cheque-voucher'} title={'Cheque Voucher'} />
            <Option value={'journal-voucher'} title={'Journal Voucher'} />
            <Option value={'petty-cash-voucher'} title={'Petty Cash Voucher'} />
          </Select>
        </Content>
        <Table2 isLoading={isLoading} width="800px" header={['Date', 'Account Title', 'Debit', 'Credit']} success>
          {activeTable === undefined && (
            <TableRow2 colspan={4} info={[<h4 className="text-center font-normal">Please Select A Category!</h4>]} />
          )}
          {
            // GeneralLedgerPart?
            ledgerData.list.length > 0 ? (
              ledgerData.list.map((item, i) => (
                <React.Fragment key={item.id}>
                  <TableRow2
                    info={[
                      <h4 className="text-center font-normal">{item.date}</h4>,
                      <h4 className="text-start font-normal">{item.name}</h4>,
                      <h4 className="text-end font-normal">{ParseToPHP(item.debit)}</h4>,
                      <h4 className="text-end font-normal">{ParseToPHP(item.credit)}</h4>,
                    ]}
                  />
                </React.Fragment>
              ))
            ) : (
              <TableRow2
                colspan={4}
                info={[
                  <h4 className="text-center font-normal">
                    {activeTable !== undefined && ledgerData.count === 0 ? 'No General Ledger Found!' : ''}
                  </h4>,
                ]}
              />
            )
            // :
            // <TableLoader2 colSpan={4} type={"dual_ring"}/>
          }
          <tr style={{ backgroundColor: '#D9D9D9' }}>
            <td style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>Total</td>
            <td></td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', paddingRight: '0.9em' }}></td>
            <td style={{ textAlign: 'end', fontWeight: 'bolder', paddingRight: '0.9em' }}>
              {ParseToPHP(ledgerData.totalDebit - ledgerData.totalCredit)}
            </td>
          </tr>
        </Table2>
      </Modal>
    </>
  );
};

export default GeneralLedgerTable;
