import axiosInstance from '../utils/axiosInstance';

interface PostSpecialParticularType {
  chart_of_account: string | undefined;
  code: string;
  church: string;
  name: string;
  particular_tag?: string;
}

export const PostSpecialParticularApi = async ({ church, name, code, particular_tag, chart_of_account }: PostSpecialParticularType) =>
  await axiosInstance
    .post('/parish/api/specials/particulars/create/', {
      church,
      name,
      code,
      particular_tag,
      chart_of_account,
    })
    .then((res) => res.data as Particular)
    .catch((err) => null);
