import { useState } from 'react';
import { Loader } from '../../components/Loader/Spinner';
import Table2, { TableRow2Header } from '../../components/Table/Table2';
import { ParseToPHP } from '../../utils/Formatter';

type Reports_ByYearType = {
  list: ReportByYear;
  isLoading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const Reports_ByYear = ({ list, isLoading }: Reports_ByYearType) => {
  const income = list.results.find((coa) => coa.chart_of_account === 'Receipts');
  const expense = list.results.find((coa) => coa.chart_of_account === 'Disbursements');

  return (
    <>
      {
        <Table2 isLoading={isLoading} success header={['ACCOUNTS', 'Current Month', 'Year-to-Date']}>
          {income ? (
            <>
              <TableRow2Header info={[<h3>Income</h3>, <></>, <></>]} />
              <CoaRenderer {...income} />
              <TableRow2Header
                info={[
                  <h3>Total Income</h3>,
                  <></>,
                  <h3 className="text-end pr-2 print-right" style={{ paddingRight: '1.5rem' }}>
                    {ParseToPHP(list.total_income)}
                  </h3>,
                ]}
              />
            </>
          ) : (
            <TableRow2Header info={[<h3>Income (Chart of Account doesn't exist!)</h3>, <></>, <></>]} />
          )}
          {expense ? (
            <>
              <TableRow2Header info={[<h3>Expense</h3>, <></>, <></>]} />
              <CoaRenderer {...expense} />
              <TableRow2Header
                info={[
                  <h3>Total Expense</h3>,
                  <></>,
                  <h3 className="text-end pr-2 print-right" style={{ paddingRight: '1.5rem' }}>
                    {ParseToPHP(list.total_expense)}
                  </h3>,
                ]}
              />
            </>
          ) : (
            <TableRow2Header info={[<h3>Expense (Chart of Account doesn't exist!)</h3>, <></>, <></>]} />
          )}
          <tr className="border-t border-green-800 print-row-header">
            <td className="pl-8" colSpan={2}>
              <h3>
                <b>Net Income</b>
              </h3>
            </td>
            <td className="text-end pr-10 print-right">
              <h3 className="text-end print-right">
                <b>{ParseToPHP(parseFloat(list.net_income.toString()))}</b>
              </h3>
            </td>
          </tr>
        </Table2>
      }
    </>
  );
};

const CoaRenderer = (coa: ReportByYear_ChartOfAccount) => (
  <>
    {coa.particular_tags.map((tag, i) => {
      const tagName = tag.name !== 'None' ? <small>-{tag.name}</small> : undefined;
      return tag.particulars.map((part) => (
        <tr key={part.id}>
          <td className="pl-10">{part.name}</td>
          <td className="text-end pr-10 print-right">{ParseToPHP(parseFloat(part.amount))}</td>
          <td className="text-end pr-10 print-right">{ParseToPHP(parseFloat(part.year_to_date))}</td>
        </tr>
      ));
    })}
  </>
);

export default Reports_ByYear;
