// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".test_noBorder__TnKeB {\n  border: 0 !important;\n}\n\n.test_container__gAW2l {\n  border-radius: 2em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Swal/test.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".noBorder {\n  border: 0 !important;\n}\n\n.container {\n  border-radius: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noBorder": "test_noBorder__TnKeB",
	"container": "test_container__gAW2l"
};
export default ___CSS_LOADER_EXPORT___;
