import React, { useEffect, useState, useContext, createContext } from 'react';

type Session = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;

  church: string;
  church_account: string;
  church_location: string;
  church_name: string;
  email: string;
  role: string;
  username: string;
};

export type AppContextType = {
  session?: Session | null;
  removeSession: () => void;
  saveSession: () => void;
};

export const AppContext = createContext<AppContextType>({
  removeSession: () => {},
  saveSession: () => {},
});

export function useAppContext() {
  return useContext(AppContext);
}

type UserProviderType = {
  children: React.ReactNode;
};

const tryParseToken = (token: any) => {
  try {
    return JSON.parse(token);
  } catch {
    return false;
  }
};

export const isSessionValid = (token: any): token is Session => {
  if (!token) return false;
  if (typeof token !== 'object') return false;
  if (!token.hasOwnProperty('access_token')) return false;
  if (!token.hasOwnProperty('expires_in')) return false;
  if (!token.hasOwnProperty('refresh_token')) return false;
  if (!token.hasOwnProperty('scope')) return false;
  if (!token.hasOwnProperty('token_type')) return false;
  if (!token.hasOwnProperty('church')) return false;
  if (!token.hasOwnProperty('church_account')) return false;
  if (!token.hasOwnProperty('church_location')) return false;
  if (!token.hasOwnProperty('church_name')) return false;
  if (!token.hasOwnProperty('email')) return false;
  if (!token.hasOwnProperty('role')) return false;
  if (!token.hasOwnProperty('username')) return false;

  return token !== undefined;
};

const UserProvider = ({ children }: UserProviderType) => {
  const GetSessionFromSessionStorage = () => {
    const authtokens_plain = sessionStorage.getItem('authTokens');
    if (!authtokens_plain) return null;

    const parsedToken = tryParseToken(authtokens_plain);

    if (!parsedToken) return null;
    if (!isSessionValid(parsedToken)) return null;

    return parsedToken;
  };

  const [session, setSession] = useState(GetSessionFromSessionStorage());

  const removeSession = () => {
    sessionStorage.removeItem('authTokens');
    sessionStorage.removeItem('ProxyKey');
    sessionStorage.removeItem('ChurchName');
    setSession(null);
  };

  const saveSession = () => {
    setSession(GetSessionFromSessionStorage());
  };

  return <AppContext.Provider value={{ session, removeSession, saveSession }}>{children}</AppContext.Provider>;
};

export default UserProvider;
