import { ChangeEventHandler, ReactNode } from 'react';
import style from './select.module.css';

interface Option {
  value: string;
  title: string;
}

interface Select {
  className?: string;
  placeHolder?: string;
  children: ReactNode;
  onChange: ChangeEventHandler;
  width?: string;
  name: string;
  title?: string | JSX.Element;
  disabled?: boolean;
  required?: boolean;
  value?: string | number;
}

export const Option = ({ value, title }: Option) => <option value={value}>{title}</option>;

const Select = ({ children, onChange, placeHolder, width, name, title, className, disabled, required, value }: Select) => (
  <div style={{ width: width, textAlign: 'start' }}>
    {title && <div className={style.title}>{title}</div>}
    <select
      name={name}
      className={`${style.select} ${className}`}
      onChange={onChange}
      defaultValue={placeHolder}
      required={required}
      placeholder={placeHolder}
      value={value}
      disabled={disabled}
    >
      {placeHolder && <option disabled>{placeHolder}</option>}
      {children}
    </select>
  </div>
);

export default Select;
