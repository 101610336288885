import { MouseEvent, MouseEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import Container, { Content } from '../Container/Container';
import { FormButton } from '../Form/Form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Responsive } from '../Interface/Pages';
import Modal from '../Modal/Modal';
import Table, { TableRow } from '../Table/Table';
import style from './navbar.module.css';
import { BaseListView, Me, Parish } from '../../utils/Interface';
import { GetGenericList, GetMe, GetMyAccInfo } from '../../utils/GetApi';
import axiosInstance, { GetTokens, isTokenValid } from '../../utils/axiosInstance';
import { SwalFailed, SwalUnderCons } from '../Swal/Swal';

interface Navbar extends Responsive {
  media: string;
}

const Navbar = ({ view, expander, media }: Navbar) => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [expand, setExpand] = useState(true);
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [parish, setParish] = useState<BaseListView<Parish> | null>(null);
  const [myInfo, setMyInfo] = useState<Me | null>(null);

  // #region forChangingChurchButton
  const [showButton, setShowButton] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [proxyKey, setProxyKey] = useState(sessionStorage.getItem('ProxyKey'));
  const [proxyChurchName, setProxyChurchName] = useState(sessionStorage.getItem('ChurchName'));
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkProxyKey = () => {
      const currentProxyKey = sessionStorage.getItem('ProxyKey');
      const currentChurchName = sessionStorage.getItem('ChurchName');
      if (currentProxyKey !== proxyKey) {
        setProxyKey(currentProxyKey);
        setProxyChurchName(currentChurchName);
      }
    };
    intervalRef.current = setInterval(checkProxyKey, 1000);
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const churchName = parish?.results.find((item) => item.id === myAccInfo?.church);

  // #endregion

  const newView = view ? style.hide : style.show;
  const newExpand = expand ? style.mainExpand : style.mainCollapse;

  const OnExpand = () => {
    setExpand((prev) => !prev);
    expander && expander();
  };

  const [open, setOpen] = useState(false);
  const MenuHandler = () => setOpen((prev) => !prev);
  const CloseMenu = () => {
    setOpen(false);
  };

  const [profileDropdown, setProfileDropdown] = useState(false);
  const [modals, setModals] = useState({
    eFiles: false,
  });

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setShowButton(true);
      }
      setMyAccInfo(tokens);
      return;
    }
    SwalFailed('OOPS!, Something went wrong...');
  };

  useEffect(() => {
    SetMe();
    GetMyAccInfo().then((res) => {
      setMyInfo(res);
    });
  }, []);

  interface ButtonShared {
    churchName: string;
  }

  const _ButtonShared = ({ churchName }: ButtonShared) => (
    <button style={{ background: 'none', border: 'none', textAlign: 'start' }}>
      {churchName} shared a report. "message from shared file description"
    </button>
  );

  const ModalOpenHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: false }));

  const navigate = useNavigate();

  const Logout = () => {
    sessionStorage.removeItem('authTokens');
    sessionStorage.removeItem('ProxyKey');
    sessionStorage.removeItem('ChurchName');
    navigate('/', { preventScrollReset: true });
  };

  const handleSetSessionStorage = () => {
    sessionStorage.removeItem('ProxyKey');
    sessionStorage.removeItem('ChurchName');
    window.location.reload();
  };

  // please use new line if the code to too long

  return (
    <>
      <div className={`${style.container}`}>
        <div className={style.mainBar}>
          <div style={view ? { display: 'none' } : { display: 'flex' }} className={style.logoDiv}>
            <img src="./newLogo2v2.png" className="object-cover" style={{ padding: '.5em', height: 60 }} />
            <b style={{ margin: '1.2em 0.1em 1em 1em', color: 'white' }}>PARISH ACCOUNTING</b>
          </div>
          <div className={`${style.menuButton} ${open && style.open} ${media}`} onClick={MenuHandler}>
            <div className={style.top}></div>
            <div className={style.mid}></div>
            <div className={style.down}></div>
          </div>
          <div className={style.profile}>
            {showButton && (
              <button
                className={`${!proxyKey ? style.churchButton : style.notChurchButton} ${style.tooltip} items-center`}
                onClick={() => {
                  handleSetSessionStorage();
                }}
              >
                {`${!proxyKey ? 'Roman Catholic Bishop of Iba' : `Viewing : ${proxyChurchName}`}`}
                <span className={style.tooltiptext}>{!proxyKey ? 'Roman Catholic Bishop of Iba' : proxyChurchName}</span>
              </button>
            )}
            <button
              style={{ border: 'none', background: 'none', color: 'white', fontSize: '2em' }}
              onClick={() => {
                SwalUnderCons('This feature is under development.');
                // ModalOpenHandler("eFiles")
              }}
            >
              <i className="fa-solid fa-folder"></i>
            </button>
            <div className={style.imgContainer}>
              <img src="./profileIcon.png" width="30px" height="30px" />
            </div>
            <button
              className={style.nameContainer}
              onClick={() => {
                setProfileDropdown((prev) => !prev);
              }}
            >
              <div style={{ marginRight: '.5em' }}>{myInfo?.username}</div>
              <div className={style.arrow}>
                <i className="fas fa-chevron-down" />
              </div>
            </button>
            <div className={`${style.profileDropdown} ${profileDropdown ? style.profShow : style.profHide}`}>
              <Link to={showButton ? '/super-profile' : '/user-profile'}>
                <div className={style.icon}>
                  <i className="fas fa-user-circle"></i>
                </div>
                Profile
              </Link>
              {/* <Link to={"/reports"}>
							<div className={style.icon}><i className="fas fa-user-friends"></i></div>
							Super Users</Link>
						<Link to={"/reports"}>
							<div className={style.icon}><i className="fas fa-question-circle"></i></div>
							Help Center</Link> */}
              <button onClick={Logout}>
                <div className={style.icon}>
                  <i className="fas fa-sign-out-alt"></i>
                </div>
                Logout{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modals.eFiles}
        success
        background="rgb(183,207,199)"
        width="500px"
        onClose={() => {
          ModalCloseHandler('eFiles');
        }}
        title={'Shared Files'}
        name={''}
        footer={[
          <Container padding=".4em" flex justify="end" width="100%">
            <Content padding="0 1em 0 0">
              <FormButton
                key={1}
                secondary
                title={'CLOSE'}
                name={''}
                onClick={() => {
                  ModalCloseHandler('eFiles');
                }}
                type={'button'}
              />
            </Content>
          </Container>,
        ]}
      >
        <Table>
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
          <TableRow info={[<_ButtonShared churchName="St. Augustine Church of Ibarra" />]} />
        </Table>
      </Modal>
    </>
  );
};

interface SideLinkBase {
  icon: ReactElement;
  title: string;
}

interface SideLink extends SideLinkBase {
  path: string;
  activePath: string;
}
interface SideDropdown extends SideLinkBase {
  links: ReactElement[];
}

const SideLink = ({ icon, title, path, activePath }: SideLink) => {
  const active = path === activePath ? style.active : style.inactive;
  return (
    <div className={`${style.link} ${active}`}>
      <div className={style.icon}>{icon}</div>
      <Link className={style.title} to={`/${path}`}>
        {title}
      </Link>
    </div>
  );
};

const SideDropdown = ({ icon, title, links }: SideDropdown) => {
  const [isDropped, setIsDropped] = useState(false);

  return (
    <div className={style.dropdown}>
      <div className={style.link}>
        <button
          onClick={() => {
            setIsDropped((prev) => !prev);
          }}
        >
          <div className={style.icon}>{icon}</div>
          <a className={style.title}>
            {title}
            <img className={isDropped ? style.arrUnhidden : style.arrHidden} width={'20px'} height={'20px'} src={'./arrow-1.png'} />
          </a>
        </button>
      </div>
      <div className={`${style.dropdownLinks} ${isDropped ? style.unhidden : style.hidden}`}>{links.map((link) => link)}</div>
    </div>
  );
};

export default Navbar;
