import { BaseSyntheticEvent, MouseEventHandler } from 'react';
import ReactLoading from 'react-loading';
import { SweetAlertResult } from 'sweetalert2';
import Container, { Content } from '../../components/Container/Container';
import Form, { FormButton, FormInput } from '../../components/Form/Form';
import { TableAction } from '../../components/Table/Table';
import Table2, { TableRow2 } from '../../components/Table/Table2';
import { ParseToPHP } from '../../utils/Formatter';
import {
  PettyCashListType,
  SelectedPettyVoucherFormItem,
  VoucherItem,
  VoucherPartType,
  PettyVoucherFormStatusType,
  BaseListView,
  Particulars,
  CashInBankType,
} from '../../utils/Interface';
import style from '../../components/Form/form.module.css';
import { Value } from 'sass';

type PettyCashVoucherFormType = {
  disableAll: boolean;
  errorOccured: boolean;
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  cv: string | undefined;
  payee: string;
  setPayee: React.Dispatch<React.SetStateAction<string>>;
  amountInPeso: string;
  noScroll: (e: BaseSyntheticEvent) => void;
  SubmitBulkAddVoucherForm: (e: BaseSyntheticEvent) => Promise<SweetAlertResult<any> | undefined>;
  voucherItemHandler: (index: number, name: 'particular_code' | 'particular' | 'debit' | 'credit', { target }: BaseSyntheticEvent) => void;
  voucherPartList: BaseListView<CashInBankType> | null;
  ReSubmitBulkAddVoucherForm: (e: BaseSyntheticEvent) => Promise<SweetAlertResult<any> | undefined>;
  selectedPettyCashList?: (SelectedPettyVoucherFormItem & PettyVoucherFormStatusType)[];
  totalDebit: number;
  totalCredit: number;
  setShowGeneratePettyForm: (value: React.SetStateAction<boolean>) => void;
};

const PettyCashVoucherForm = ({
  disableAll,
  errorOccured,
  date,
  setDate,
  description,
  setDescription,
  cv,
  payee,
  setPayee,
  amountInPeso,
  noScroll,
  SubmitBulkAddVoucherForm,
  voucherItemHandler,
  voucherPartList,
  ReSubmitBulkAddVoucherForm,
  selectedPettyCashList,
  totalDebit,
  totalCredit,
  setShowGeneratePettyForm,
}: PettyCashVoucherFormType) => {
  const writtenNumber = require('written-number');
  const toAmountWords = writtenNumber(amountInPeso);

  return (
    <>
      <Content flex justify="end">
        <FormButton
          width="300px"
          success
          title={'PETTY CASH VOUCHER LIST'}
          name={''}
          onClick={() => {
            setShowGeneratePettyForm(false);
          }}
          disabled={disableAll}
          type={'button'}
        />
      </Content>
      <Container flex justify="space-between">
        <Content grow="1" padding="0em 0.5em">
          <FormInput
            required
            title={
              <>
                <span className="text-red-500">*</span>Date:
              </>
            }
            type={'date'}
            name={''}
            disabled={disableAll}
            value={date}
            onChange={({ target }: BaseSyntheticEvent) => {
              setDate(target.value);
            }}
          />
          <FormInput
            margin="0.6em 0 0 0"
            required
            title={
              <>
                <span className="text-red-500">*</span>Description:
              </>
            }
            disabled={disableAll}
            name={''}
            value={description}
            onChange={({ target }: BaseSyntheticEvent) => {
              setDescription(target.value);
            }}
          />
        </Content>

        <Content grow="1" padding="0 0.5em">
          <FormInput
            disabled
            title={
              <>
                <span className="text-red-500">*</span>CV#:
              </>
            }
            name={''}
            value={cv ? cv : ''}
            onChange={({ target }: BaseSyntheticEvent) => {}}
          />
          <FormInput
            disabled
            title={'Amount in words:'}
            name={''}
            value={`${toAmountWords} peso only`}
            onChange={({ target }: BaseSyntheticEvent) => {}}
          />
        </Content>
        <Content grow="1" padding="0 0.5em">
          <FormInput
            required
            title={
              <>
                <span className="text-red-500">*</span>Pay To:
              </>
            }
            name={''}
            disabled={disableAll}
            value={payee}
            onChange={({ target }: BaseSyntheticEvent) => {
              setPayee(target.value);
            }}
          />
          <FormInput
            required
            disabled
            title={
              <>
                <span className="text-red-500">*</span>Amount in Peso:
              </>
            }
            name={''}
            type="number"
            onWheel={noScroll}
            minValue={1}
            value={amountInPeso}
            onChange={({ target }: BaseSyntheticEvent) => {}}
          />
        </Content>
      </Container>

      <Form id={''} handleSubmit={SubmitBulkAddVoucherForm}>
        <Table2 success header={['Code', 'Account Title', 'Debit', 'Credit']}>
          {selectedPettyCashList?.map((item, i) => (
            <TableRow2
              colspan={4}
              key={i}
              padding=".4em .5em"
              info={[
                <div style={{ display: 'flex', margin: '.2em 0' }}>
                  {item.isPosting && (
                    <span style={{ marginTop: '0.8em' }}>
                      <ReactLoading width={25} height={25} type="spin" color="#00b894" />
                    </span>
                  )}
                  {item.isSuccess ? (
                    <span style={{ padding: '0.2em', fontSize: '1.5em' }}>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                  ) : (
                    <></>
                  )}
                  <FormInput
                    key={`a-${i}`}
                    {...(i === 0 && { title: '' })}
                    disabled
                    name={''}
                    value={item.particular_code}
                    padding={'.2em'}
                    onChange={(e) => {}}
                  />

                  <div className={style.inputContainer} style={{ padding: '0.2em' }}>
                    <input
                      key={`b-${i}`}
                      type={'text'}
                      {...(i === 0 && { title: '' })}
                      name={''}
                      value={item.particular}
                      required
                      disabled={i !== 0}
                      onChange={(e) => {
                        voucherItemHandler(i, 'particular', e);
                      }}
                      list={'partList'}
                    />
                    <datalist id={'partList'}>
                      {voucherPartList?.results.map((part, i) => (
                        <option key={i} value={part.name}></option>
                      ))}
                    </datalist>
                  </div>

                  <FormInput
                    key={`c-${i}`}
                    type={'number'}
                    placeHolder={'0'}
                    {...(i === 0 && { title: '' })}
                    name={''}
                    value={item.amount}
                    padding={'.2em'}
                    onWheel={noScroll}
                    disabled
                    onChange={(e) => {
                      voucherItemHandler(i, 'debit', e);
                    }}
                    textAlign={'right'}
                  />

                  <FormInput
                    key={`d-${i}`}
                    type={'number'}
                    placeHolder={'0'}
                    {...(i === 0 && { title: '' })}
                    name={''}
                    value={item.credit}
                    padding={'.2em'}
                    onWheel={noScroll}
                    disabled={i !== 0}
                    onChange={(e) => {
                      voucherItemHandler(i, 'credit', e);
                    }}
                    textAlign={'right'}
                  />
                  <span style={{ paddingLeft: '0.5em' }}></span>
                </div>,
              ]}
            />
          ))}
          <TableRow2
            padding=".4em .5em"
            info={[
              <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Total :</h4>,
              <h4></h4>,
              <h4 style={{ textAlign: 'end', fontWeight: 'bold', paddingRight: '0.7em', color: '#055437' }}>{ParseToPHP(totalDebit)}</h4>,
              <h4 style={{ textAlign: 'end', fontWeight: 'bold', paddingRight: '1em', color: '#055437' }}>{ParseToPHP(totalCredit)}</h4>,
            ]}
          />
        </Table2>
        {errorOccured ? (
          <Content flex justify="right">
            <FormButton
              secondary
              width="20em"
              disabled={disableAll}
              success
              title={'RETRY CHEQUE VOUCHER'}
              name={''}
              onClick={(e) => {
                ReSubmitBulkAddVoucherForm(e);
              }}
              type={'submit'}
            ></FormButton>
          </Content>
        ) : (
          <Content flex justify="right">
            <FormButton
              width="18em"
              disabled={disableAll}
              success
              title={'CREATE CHEQUE VOUCHER'}
              name={''}
              onClick={() => {}}
              type={'submit'}
            ></FormButton>
          </Content>
        )}
      </Form>
    </>
  );
};

export default PettyCashVoucherForm;
