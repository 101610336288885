import { SetStateAction } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import style from './Paginate.module.scss';

export const GET_API_LIMIT = 5;

type PaginationType = {
  disabled: boolean;
  next: string | undefined | null;
  prev: string | undefined | null;
  count?: number;
  limit: number;
  currentPage: number;
  decrement: () => void;
  increment: () => void;
};

const Pagination = ({ disabled, next, prev, count, limit, currentPage, decrement, increment }: PaginationType) => {
  const counts = count ?? 0;
  const pages = Math.ceil(counts / limit);

  return (
    <div className={style.container}>
      <span style={{ margin: '0.5em', paddingTop: '0.2em' }}>
        Page{' '}
        <strong>
          {currentPage} of {pages === 0 ? 1 : pages}
        </strong>
      </span>
      <button
        disabled={currentPage === 1 || disabled ? true : false}
        onClick={decrement}
        style={prev === null || disabled ? { background: 'gray' } : {}}
      >
        Previous
      </button>
      <button
        disabled={next === null || disabled ? true : false}
        onClick={increment}
        style={next === null || disabled ? { background: 'gray' } : {}}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
