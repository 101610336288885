import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardContainer, { Card, CardButton } from '../components/Card/Card';
import Container from '../components/Container/Container';
import { FieldContainer, FormButton, FormFieldContainer } from '../components/Form/Form';
import { TableLoader } from '../components/Loader/Spinner';
import Modal from '../components/Modal/Modal';
import Table, { TableEmpty, TableRow } from '../components/Table/Table';
import axiosInstance, { GetTokens, isAdmin, isTokenValid } from '../utils/axiosInstance';
import { FormatDate } from '../utils/Formatter';
import { GetFilesShare } from '../utils/GetApi';
import { BaseListView, FileShareType, Me, Parish } from '../utils/Interface';
import UserSideFileShared from './EFilePages/userSide';

const Efiles = () => {
  const [filesShare, setFilesShare] = useState<BaseListView<FileShareType> | null>(null);
  const [selectedFilesShare, setSelectedFilesShare] = useState<BaseListView<FileShareType> | null>(null);
  const [parishList, setParishList] = useState<BaseListView<Parish> | null>(null);
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [parish, setParish] = useState<Parish[] | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<Parish | null>(null);
  const [show, setShow] = useState(false);

  const SetMe = async () => {
    const token = GetTokens();
    if (!isTokenValid(token)) return;
    setMyAccInfo(token);
  };

  useEffect(() => {
    SetMe();
  }, []);

  const GetFiles = () => {
    if (isAdmin(myAccInfo)) {
      return axiosInstance.get('api/church/').then((res) => {
        setParishList(res.data);
      });
    }
    if (!isAdmin(myAccInfo)) {
      return GetFilesShare(setFilesShare).then((res) => {
        setFilesShare(res);
      });
    }
  };

  const ModalCloseButton = () => {
    setShow(false);
    setSelectedFilesShare(null);
  };

  useEffect(() => {
    if (!myAccInfo) return;
    GetFiles();
  }, [myAccInfo]);

  useEffect(() => {
    if (!parishList) return;
    setParish(parishList.results);
  }, [parishList]);

  const SetSelectedFolder = (index: number) => {
    if (!parishList) return;
    const folderIndex = parishList.results[index];
    setSelectedFolder(folderIndex);
    GetFilesShare(setFilesShare, folderIndex.id).then((res) => {
      setSelectedFilesShare(res);
    });
    setShow(true);
  };

  const handleClick = (url: string) => {
    window.open(url, '_blank');
  };

  if (!isAdmin(myAccInfo)) return <UserSideFileShared filesShare={filesShare} />;
  return (
    <>
      <CardContainer justify="start">
        {parish?.map((item, i) => (
          <CardButton
            border="none"
            key={item.id}
            title={item.name}
            content={<i style={{ fontSize: '50px' }} className="fa-regular fa-folder-open"></i>}
            onClick={() => {
              SetSelectedFolder(i);
            }}
          />
        ))}
      </CardContainer>
      <Modal
        title={`${selectedFolder?.name} Folder`}
        success
        show={show}
        onClose={() => {
          ModalCloseButton();
        }}
        name={''}
        width={'800px'}
      >
        <Table header={['File Name', '', 'Date Shared', 'Shared By']} success>
          {selectedFilesShare ? (
            selectedFilesShare.results.length > 0 ? (
              selectedFilesShare.results.map((file, i) => (
                <TableRow
                  pointer
                  onClick={() => {
                    handleClick(file.file);
                  }}
                  key={i}
                  info={[
                    <>{`${FormatDate(file.date_shared)}-${file.name}`}</>,
                    <></>,
                    <>{FormatDate(file.date_shared)}</>,
                    <>{file.shared_by}</>,
                  ]}
                />
              ))
            ) : (
              <TableEmpty colSpan={4}>No File/s Found!</TableEmpty>
            )
          ) : (
            <TableLoader colSpan={4} type={'dual_ring'} />
          )}
        </Table>
      </Modal>
    </>
  );
};

export default Efiles;
