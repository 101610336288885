import style from './voidSticker.module.css';

type VoidStickerType = {
  isVoid: boolean | undefined;
  id?: string;
};

const VoidSticker = ({ isVoid, id }: VoidStickerType) => (
  <>
    {isVoid ? (
      <div className={style.container} id={id}>
        <div className={style.centeredDiv}>
          <p className={style.slanted}>VOID</p>
        </div>
      </div>
    ) : (
      <></>
    )}
  </>
);

export default VoidSticker;
