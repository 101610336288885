import { access } from 'fs';
import React, { BaseSyntheticEvent, ChangeEvent, FormEvent, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { isTemplateExpression, parseJsonText } from 'typescript';
import CardContainer, { CardBox } from '../components/Card/Card';
import Container, { Content } from '../components/Container/Container';
import Form, { FormButton, FormInput } from '../components/Form/Form';
import { Loader, TableLoader } from '../components/Loader/Spinner';
import Modal from '../components/Modal/Modal';
import Select, { Option } from '../components/Select/Select';
import { SwalFailed, SwalLoading, SwalSuccess } from '../components/Swal/Swal';
import { TableAction, TableEmpty } from '../components/Table/Table';
import Table2, { TableRow2, TableRow2Header } from '../components/Table/Table2';
import axiosInstance, { GetTokens } from '../utils/axiosInstance';
import { ParseToPHP } from '../utils/Formatter';
import { GetCoAList, GetGenericList, GetMe } from '../utils/GetApi';
import { BaseListView, ChartOfAccounts, CoAListType, Me, Particulars, ParticularTags } from '../utils/Interface';
import { AddChartOfAccount, PostParticularName, PostParticularTag } from '../utils/PostApi';

const ChartOfAccountsPage = () => {
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [chartOfAccounts, setChartOfAccounts] = useState<BaseListView<ChartOfAccounts> | null>(null);
  const [cOAName, setCOAName] = useState('');
  const [rerender, setRerender] = useState(true);
  const [rerender2, setRerender2] = useState(true);
  const [coAList, setCoAList] = useState<CoAListType[]>();
  const [partTag, setPartTag] = useState<BaseListView<ParticularTags> | null>(null);
  const [particular, setParticular] = useState<BaseListView<Particulars> | null>(null);
  const [noAvail, setNoAvail] = useState(false);

  // #region useEffect List

  const OnGetAccFail = () => {
    Swal.fire({
      icon: 'warning',
      title: `No Parish assigned!`,
      text: 'You are not asssigned to any parish!',
    });
  };

  const Empty = () => {
    if (coAList?.length === 0) {
      return setNoAvail(true);
    }

    return setNoAvail(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    // GetCoAList()
    // .then((res) =>{
    // 	if(res === null) return
    // 	if(res === undefined) return
    // 	setCoAList(res)
    // 	setIsDoneLoading(true)
    // 	setIsLoading(false)
    // })

    GetGenericList<ParticularTags>({
      apiUrl: '/parish/api/particular-tags/',
      setList: setPartTag,
    });

    GetGenericList<Particulars>({
      apiUrl: '/parish/api/particulars/',
      setList: setParticular,
    });
  }, [rerender2, rerender]);

  useEffect(() => {
    Empty();
  }, [coAList]);

  useEffect(() => {
    SetMe();
  }, []);

  // #endregion

  const SetMe = async () => {
    setMyAccInfo(GetTokens());
  };

  const [isDoneLoading, setIsDoneLoading] = useState(false);

  // #region Post Buttons

  const SubmitAddCoA = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (cOAName.trim() === '') {
      SwalFailed('No CoA Name!');
      return;
    }
    if (coAList?.find((item) => item.name.toUpperCase() === cOAName.toUpperCase())) {
      SwalFailed('No CoA Name already exists!');
      return;
    }
    const onSuccess = () => {
      SwalSuccess('Chart of Account Added!');
      setCOAName('');
      setRerender((prev) => !prev);
      ModalCloseHandler('addCoA');
    };
    const onFail = () => {
      SwalFailed('OOPS! something went wrong.');
      setCOAName('');
      setRerender((prev) => !prev);
    };
    if (!myAccInfo) return;
    if (myAccInfo.church === 'N/A') {
      SwalFailed('No Parish assigned to your account!');
      return;
    }
    SwalLoading();
    await AddChartOfAccount({
      church: myAccInfo.church,
      name: cOAName,
      onSuccess,
      onFail,
    });
  };

  const AddCoA = () => {
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === myAccInfo?.church || pKey === '' || !pKey) {
      return ModalOpenHandler('addCoA');
    }
    if (pKey !== '') return SwalFailed('This is not your parish!');
  };

  // #endregion

  const [modals, setModals] = useState({
    addIncome: false,
    addExpense: false,
    addCoA: false,
  });

  const ModalOpenHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: false }));

  return (
    <>
      <Container flex padding="0">
        <CardContainer isLoading={isLoading && coAList !== undefined}>
          <CardBox title={noAvail ? 'No Available Accounts.' : 'Account List:'} width="20em">
            {coAList ? (
              coAList.map((fund, i) => (
                // if(i%2===1){
                // 	return <React.Fragment key={i}>
                // 		<div className={"w-1/2 my-1 flex"}>
                // 			<div className="pr-1">&#x2022;</div>
                // 			{fund.name}
                // 		</div><hr className={"w-full my-1"}/>
                // 	</React.Fragment>
                // }
                <div key={i} className={'w-full my-1 flex'}>
                  <div className="pr-1">&#x2022;</div>
                  {fund.name}
                </div>
              ))
            ) : (
              <div className="flex justify-center w-full">
                <Loader type={'dual_ring'} />
              </div>
            )}
            <FormButton
              success
              icon={<i className="fa-solid fa-plus"></i>}
              title={'Add Chart of Account'}
              name={''}
              disabled={!isDoneLoading || isLoading}
              onClick={() => {
                AddCoA();
              }}
              type={'button'}
            />
          </CardBox>
        </CardContainer>
        {coAList?.map((CoA) => (
          <EachCoA
            Me={myAccInfo}
            key={CoA.id}
            CoA={CoA}
            partTag={partTag ? partTag.results : []}
            particular={particular ? particular?.results : []}
            isDoneLoading={isDoneLoading}
            setRerender2={setRerender2}
          />
        ))}
      </Container>

      <Modal
        success
        title={'Add Chart of Account'}
        show={modals.addCoA}
        onClose={() => {
          ModalCloseHandler('addCoA');
        }}
        name={''}
      >
        <Form id={''} handleSubmit={SubmitAddCoA}>
          <Container flex textAlign="start" width="90%">
            <Content padding="0 1em">
              <FormInput
                title="Chart of Account Name:"
                name={''}
                value={cOAName}
                onChange={(e: BaseSyntheticEvent) => {
                  setCOAName(e.target.value);
                }}
              />
            </Content>
          </Container>
          <Container flex>
            <Content padding="0 1em">
              <FormButton width="8em" success title={'ADD'} name={''} onClick={() => {}} type={'submit'} />
            </Content>
            <Content padding="0 1em">
              <FormButton
                width="8em"
                secondary
                success
                title={'CANCEL'}
                name={''}
                onClick={() => {
                  ModalCloseHandler('addCoA');
                }}
                type={'button'}
              />
            </Content>
          </Container>
        </Form>
      </Modal>
    </>
  );
};

interface EachCoAType {
  CoA: CoAListType;
  Me: Me | null;
  partTag: ParticularTags[];
  particular: Particulars[];
  isDoneLoading: boolean;
  setRerender2: React.Dispatch<React.SetStateAction<boolean>>;
}

const EachCoA = ({ Me, CoA, isDoneLoading, setRerender2, partTag, particular }: EachCoAType) => {
  const AddButton = (
    onClick: MouseEventHandler,
    text: string,
    color: string,
    align: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
    cursor: string
  ) => (
    <div style={{ textAlign: align }}>
      <button
        style={{ border: 'none', background: 'none', fontWeight: '500', color: color, cursor: cursor }}
        onClick={onClick}
        type={'button'}
      >
        <i className="fa-solid fa-plus"></i>
        {text}
      </button>
    </div>
  );

  const AddPart = () => {
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === Me?.church || pKey === '' || !pKey) {
      return setShowAdd(true);
    }
    if (pKey !== '') return SwalFailed('This is not your parish!');
  };
  interface Particular {
    name: string;
    code: string;
  }

  const [rerender, setRerender] = useState(true);

  const [particularList, setParticularList] = useState<Particular[]>([{ name: '', code: '' }]);

  const [particularTagName, setParticularTagName] = useState('');

  const particularHandler = (index: number, name: 'name' | 'code', { target }: BaseSyntheticEvent) => {
    particularList[index][name] = target.value;
    setRerender((prev) => !prev);
  };

  const AddNewPart = () => {
    const code = particular.map((item) => item.code);
    const latestParticular = particularList[particularList.length - 1];
    if (latestParticular.code !== '' && latestParticular.name === '') {
      return SwalFailed('Name is empty');
    }
    if (latestParticular.code === '' && latestParticular.name !== '') {
      return SwalFailed('Code is empty');
    }
    if (latestParticular.code === '' && latestParticular.name === '') {
      return SwalFailed('Text Field is empty');
    }
    if (code.includes(latestParticular.code)) {
      return SwalFailed('The code already exists!');
      return SwalFailed('The recent code inputed is already existing');
    }

    particularList.push({ name: '', code: '' });
    setRerender((prev) => !prev);
    setFieldColor(false);
  };

  useEffect(() => {
    setParticularList(particularList);
  }, [rerender]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAdd2, setShowAdd2] = useState(false);
  const [showAdd3, setShowAdd3] = useState(false);

  const SubmitAddParticularHandler = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const code = particular.map((item) => item.code);

    const latestParticular = particularList[particularList.length - 1];
    const secondlatestParticular = particularList[particularList.length - 2];
    if (latestParticular.code !== '' && latestParticular.name === '') {
      return SwalFailed('Name is empty');
    }
    if (latestParticular.code === '' && latestParticular.name !== '') {
      return SwalFailed('Code is empty');
    }
    if (latestParticular.code === '' && latestParticular.name === '') {
      return SwalFailed('Text Field is empty');
    }
    if (code.includes(latestParticular.code)) {
      return SwalFailed('The recent code inputed is already existing');
    }
    SubmitAddpaticular();
  };

  const formatAmount = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const [subnum1, setSubNum1] = useState('');

  const SubmitAddpaticular = async () => {
    if (!Me) return;
    if (Me.church === 'N/A') {
      Swal.fire({
        icon: 'warning',
        title: `No Parish assigned to your account!`,
      });
      return;
    }
    // Swal Loading -> start post api
    SwalLoading();
    let tagData: ParticularTags | null = null;
    if (particularTagName !== '')
      tagData = await PostParticularTag({
        name: particularTagName,
        church: Me.church,
        subsidiary_no: subnum1,
      });
    // console.log("TAG DATA", tagData)
    const particulartPostList = particularList.map((item) =>
      PostParticularName({
        church: Me.church,
        name: item.name,
        code: item.code,
        particular_tag: tagData?.id,
        chart_of_account: CoA.id,
      })
    );
    await Promise.allSettled(particulartPostList)
      .then((res) => {
        // Swal Success Here
        SwalSuccess('Particular Added!');
        setParticularTagName('');
        setParticularList([{ name: '', code: '' }]);
        setShowAdd(false);
        setRerender2((prev) => !prev);
      })
      .catch((err) => {
        // Swal Failed Here
        SwalFailed('Failed to add particular!');
      });
  };

  // #region adding particular in tag and delete particular

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedRIndex, setSelectedPartRIndex] = useState(-1);
  const [selectedCIndex, setSelectedPartCIndex] = useState(-1);
  const [fieldColor, setFieldColor] = useState(false);

  const showButtonPart = (index: number) => {
    setShowAdd2(true);
    setSelectedIndex(index);
  };

  const AddButtonPart = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!Me) return;
    if (Me.church === 'N/A') {
      Swal.fire({
        icon: 'warning',
        title: `No Parish assigned to your account!`,
      });
      return;
    }

    const code = particular.map((item) => item.code);
    const latestParticular = particularList[particularList.length - 1];
    if (latestParticular.code !== '' && latestParticular.name === '') {
      return SwalFailed('Name is empty');
    }
    if (latestParticular.code === '' && latestParticular.name !== '') {
      return SwalFailed('Code is empty');
    }
    if (latestParticular.code === '' && latestParticular.name === '') {
      return SwalFailed('Text Field is empty');
    }
    if (code.includes(latestParticular.code)) {
      return SwalFailed('The recent code inputed is already existing');
    }
    SwalLoading();
    // let CoAIndex = CoA.particular_tags[selectedIndex].name
    // let tag = partTag.find((item) => item.name === CoAIndex)
    const particulartPostList = particularList.map((item) =>
      PostParticularName({
        church: Me.church,
        name: item.name,
        code: item.code,
        // particular_tag : tag?.id,
        chart_of_account: CoA.id,
      })
    );
    await Promise.allSettled(particulartPostList)
      .then((res) => {
        // Swal Success Here
        SwalSuccess('Particular Added!');
        setParticularTagName('');
        setParticularList([{ name: '', code: '' }]);
        setShowAdd2(false);
        setRerender2((prev) => !prev);
      })
      .catch(() => {
        SwalFailed('OOPS!, something went wrong!');
      });
  };

  const CloseButton = () => {
    setShowAdd2(false);
    setFieldColor(false);
    setParticularTagName('');
    setParticularList([{ name: '', code: '' }]);
    setShowAdd2(false);
    setRerender2((prev) => !prev);
  };

  const DeleteShowModal = (rIndex: number, cIndex: number) => {
    setShowAdd3(true);
    setSelectedPartRIndex(rIndex);
    setSelectedPartCIndex(cIndex);
  };

  // const DeletePart = async () => {
  // 	const Coapart = CoA.particular_tags.map((tag) => tag.particulars.map((partName) => partName.name))
  // 	let part = particular.find((item) => item.name === Coapart[selectedRIndex][selectedCIndex])
  // 	await axiosInstance.delete(`/parish/api/particulars/${part?.id}`)
  // 	.then((res) => {
  // 		SwalSuccess("Particular Deleted")
  // 		setShowAdd3(false)
  // 		setRerender2(prev=>!prev)
  // 	})
  // }

  // #endregion

  return (
    <>
      <Table2
        success
        header={[
          <div className="text-start">Code</div>,
          <div className="text-start">{CoA.name}</div>,
          <div style={{ textAlign: 'end' }}>Amount</div>,
          AddButton(
            () => {
              AddPart();
            },
            `Add ${CoA.name}`,
            'white',
            'center',
            'pointer'
          ),
        ]}
      >
        {/* {isDoneLoading ?
			CoA.particular_tags.length >= 1 ?
			CoA.particular_tags.map((tags , t) =>{
				if(tags.name === "None"){
					return(
						<React.Fragment key={tags.id}>
						{tags.particulars.map((part,p) => {
						return(
							<TableRow2 key={p} info={[
								<h4 style={{ fontWeight : "normal" , fontFamily: '"IBM Plex Sans", sans-serif'}}>{part.code}</h4>,
								<h4 style={{fontWeight : "bolder"  }}>{part.name}</h4>,

								<h4 style={{ textAlign : "end" , fontWeight : "normal" }}>{ParseToPHP(part.amount)}</h4>,

								<div style={{textAlign : "center" ,}}>
									<TableAction icon={<i className="fa-solid fa-trash"></i>} 
										onClick={()=>{DeleteShowModal(t,p)}}/>
								</div>]}/>
						)
						})}
						</React.Fragment>
					)
				}
				else if(tags.name !== "None"){
					return(
						<React.Fragment key={tags.id}>
						<TableRow2 key={t} info={[
								<h4></h4>,
								<h3 style={{fontWeight : "bolder" , fontFamily: '"IBM Plex Sans", sans-serif'}}>
									{tags.name}
								</h3>,
								<h4></h4>,
								<div style={{textAlign : "center" ,}}>
									<TableAction icon={<i className="fa-solid fa-plus"></i>}
										name={"Add Particular"}
										onClick={()=>{showButtonPart(t)}}/>
								</div>
								]}/>
						{tags.particulars.map((part,p) => {
							return(
								<TableRow2 key={p} info={[
									<h4 style={{ fontWeight : "normal" , fontFamily: '"IBM Plex Sans", sans-serif'}}>
										{part.code}
									</h4>,
									<h4 style={{ fontWeight : "normal"  }}>
										{part.name}
									</h4>,
									<h4 style={{	textAlign : "end" , fontWeight : "normal" }}>
										{ParseToPHP(part.amount)}
									</h4>,
									<div style={{textAlign : "center" ,}}>
										<TableAction icon={<i className="fa-solid fa-trash"></i>}
											onClick={()=>{DeleteShowModal(t,p)}}/>
									</div>
									]}/>
							)
						})}
						</React.Fragment>
					)
				}
			}
		)
		:
			<TableRow2 colspan={4} info={[
				<h4 className="text-center py-3"
					style={{fontWeight : "normal" , fontFamily: '"IBM Plex Sans", sans-serif'}}>
					No Particular/s Available!
				</h4>
				]}/>
		:
			<TableLoader type={"dual_ring"}/>
		} */}
      </Table2>

      <Modal
        title={`Add ${CoA.name} Particular`}
        success
        show={showAdd}
        onClose={() => {
          setShowAdd(false);
        }}
        name={''}
      >
        <Form id={''} handleSubmit={SubmitAddParticularHandler}>
          <Container flex textAlign="start" width="100%" padding="0">
            <Content padding="0 1em">
              <FormInput
                title="Tag Name:"
                name={''}
                value={particularTagName}
                onChange={(e: BaseSyntheticEvent) => {
                  setParticularTagName(e.target.value);
                }}
              />
            </Content>
          </Container>
          <Container flex direction="column">
            {particularList.map((item, i) => (
              <div key={i} style={{ display: 'flex', margin: '.2em 0' }}>
                <FormInput
                  key={`a-${i}`}
                  {...(i === 0 && { title: 'Code:' })}
                  name={''}
                  value={item.code}
                  padding={'.2em'}
                  onChange={(e) => {
                    particularHandler(i, 'code', e);
                  }}
                />

                <FormInput
                  key={`b-${i}`}
                  {...(i === 0 && { title: 'Particular Name:' })}
                  name={''}
                  value={item.name}
                  padding={'.2em'}
                  onChange={(e) => {
                    particularHandler(i, 'name', e);
                  }}
                />
              </div>
            ))}
          </Container>
          {AddButton(
            () => {
              AddNewPart();
            },
            'Add Particular',
            '#00b894',
            'left',
            'pointer'
          )}
          <Container flex>
            <Content padding="0 1em">
              <FormButton width="8em" success title={'ADD'} name={''} onClick={() => {}} type={'submit'} />
            </Content>
            <Content padding="0 1em">
              <FormButton
                width="8em"
                secondary
                success
                title={'CANCEL'}
                name={''}
                onClick={() => {
                  setShowAdd(false);
                }}
                type={'button'}
              />
            </Content>
          </Container>
        </Form>
      </Modal>

      <Modal
        title={`Add ${CoA.name} Particular`}
        success
        show={showAdd2}
        onClose={() => {
          setShowAdd2(false);
        }}
        name={''}
      >
        <Form id={''} handleSubmit={AddButtonPart}>
          <Container flex direction="column">
            {particularList.map((item, i) => (
              <div key={i} style={{ display: 'flex', margin: '.2em 0' }}>
                <FormInput
                  key={`a-${i}`}
                  {...(i === 0 && { title: 'Code:' })}
                  name={''}
                  value={item.code}
                  padding={'.2em'}
                  onChange={(e) => {
                    particularHandler(i, 'code', e);
                  }}
                />

                <FormInput
                  key={`b-${i}`}
                  {...(i === 0 && { title: 'Particular Name:' })}
                  name={''}
                  value={item.name}
                  padding={'.2em'}
                  onChange={(e) => {
                    particularHandler(i, 'name', e);
                  }}
                />
              </div>
            ))}
          </Container>
          {AddButton(
            () => {
              AddNewPart();
            },
            'Add Particular',
            '#00b894',
            'left',
            'pointer'
          )}
          <Container flex>
            <Content padding="0 1em">
              <FormButton width="8em" success title={'ADD'} name={''} onClick={() => {}} type={'submit'} />
            </Content>
            <Content padding="0 1em">
              <FormButton
                width="8em"
                secondary
                success
                title={'CANCEL'}
                name={''}
                onClick={() => {
                  CloseButton();
                }}
                type={'button'}
              />
            </Content>
          </Container>
        </Form>
      </Modal>

      <Modal show={showAdd3} onClose={() => {}} name={'addChurch'} title={'Delete Particular'} width="400px" success>
        <div>
          <p style={{ color: '#298D69', fontWeight: 'bolder' }}>Are you sure you want to proceed?</p>
        </div>
        <Content flex justify="space-around" padding="1em 0 0 0">
          <div>
            <FormButton
              success
              title={'Confirm'}
              name={''}
              // onClick={() => {DeletePart()}}
              type={'submit'}
            />
          </div>
          <div>
            <FormButton
              success
              title={'Cancel'}
              secondary
              name={'addNewModal'}
              onClick={() => {
                setShowAdd3(false);
              }}
              type={'button'}
            />
          </div>
        </Content>
      </Modal>
    </>
  );
};

export default ChartOfAccountsPage;
