import { ReactNode, useContext } from 'react';
import { AppContext } from '../../utils/context';

type ErrorPageType = {
  code: number;
  message: string;
  children?: ReactNode;
};

const ErrorPage = ({ code, message, children }: ErrorPageType) => {
  const user = useContext(AppContext);

  const GoToLink = () => {
    window.location.replace(process.env.REACT_APP_FRONT_URL ?? '');
    sessionStorage.removeItem('authTokens');
    sessionStorage.removeItem('ProxyKey');
    sessionStorage.removeItem('ChurchName');
    if (user?.removeSession) {
      user?.removeSession();
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div>
        <div className="flex text-2xl">
          <div>{code}</div>
          <div className="mx-2 border border-black"></div>
          <div>{message}</div>
        </div>
        {children && <hr className="mt-2" />}
        {children}
        <hr className="my-4" />
        <div>
          Go to{' '}
          <button onClick={GoToLink} className="text-blue-600 hover:text-blue-300 hover:underline">
            Home Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
