import axios from 'axios';
import { BaseSyntheticEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetTokenApi, { GetMeApi } from '../api/AuthApi';
import Container, { Content } from '../components/Container/Container';
import Form, { FormButton, FormInput } from '../components/Form/Form';
import Modal from '../components/Modal/Modal';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess, SwalSuccessCustom } from '../components/Swal/Swal';
import AuthContext from '../context/AuthContext';
import axiosInstance from '../utils/axiosInstance';
import { GetGenericList, GetMe } from '../utils/GetApi';
import { BaseListView, Me, Parish } from '../utils/Interface';

const Login = () => {
  const navigate = useNavigate();

  // const {userLogin} = useContext(AuthContext)

  const HandleSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();
    if (!navigator.onLine) {
      SwalFailedCustom({
        title: 'No Internet Connection!',
        text: 'Please check you internet connection.',
      });
      return;
    }

    SwalLoading();

    const { username, password } = loginData;

    const token = await GetTokenApi({ username, password });
    if (!token) {
      SwalFailedCustom({
        title: 'Log-in Failed',
        text: 'You have entered invalid credentials.',
      });
      return;
    }

    const user = await GetMeApi(token.access_token);
    if (!user) {
      SwalFailedCustom({
        title: 'Log-in Failed',
        text: "There's an error with the account.",
      });
      return;
    }

    const newData = {
      ...token,
      ...user,
    };

    sessionStorage.setItem('authTokens', JSON.stringify(newData));

    sessionStorage.setItem('ProxyKey', '');
    sessionStorage.setItem('ChurchName', '');

    SwalSuccessCustom({
      title: 'Log-in Successful',
      text: 'Welcome to Lampara Parish Accounting!',
    });

    navigate('/home', { preventScrollReset: true });
  };

  const HandleSubmitForgot = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (email.trim() === '' || username.trim() === '') {
      return SwalFailed('All fields are required!');
    }
    SwalLoading();
    await axiosInstance
      .post('password-reset/', {
        email: email,
        user: username,
      })
      .then((res) => {
        SwalSuccess('The link sent successfuly please check your inbox/spam');
        setEmail('');
        setUserName('');
      })
      .catch((error) => {
        SwalFailed('Username and email does not match');
        // to do : error message validation correct input correct output
      });
  };

  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const LoginDataHandler = ({ target: { name, value } }: BaseSyntheticEvent) => {
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  const [modals, setModals] = useState({
    forgotPassword: false,
  });

  const ModalOpenHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: false }));

  const [email, setEmail] = useState('');
  const [username, setUserName] = useState('');

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        background: 'url(img/bgAmsnewDark.png) center center / cover no-repeat fixed',
        height: '100vh',
      }}
    >
      <Container flex minWidth="320px" maxWidth="900px" justify="space-around" bgColor="rgba(41, 141, 105, .66)">
        <Content flex direction="column" minWidth="200px" maxWidth="450px" padding="0 1em">
          <img src="./newLogo2.png" style={{ margin: 'auto', height: '300px', marginBottom: '-50px' }} />
          <h1 style={{ color: 'white' }}>Welcome to LAMPARA PARISH ACCOUNTING</h1>
          <small className={'text-left text-white'}>v.0.2</small>
        </Content>

        <Content flex justify="center" minWidth="300px" direction="column" maxWidth="450px" padding="0 1em">
          <Form id={'loginForm'} handleSubmit={HandleSubmit}>
            <h1 className="text-white">Account Login</h1>
            <hr />
            <small className="text-white mr-auto">Please login into your Account</small>

            <FormInput
              required
              margin=".2em 0"
              name={'username'}
              value={loginData.username}
              onChange={LoginDataHandler}
              placeHolder="USERNAME"
            />
            <FormInput required margin=".2em 0" type="password" name={'password'} onChange={LoginDataHandler} placeHolder="PASSWORD" />

            <FormButton success title={'Login'} name={'loginBTN'} type={'submit'} />
            <FormButton secondary title={'Forgot Password'} name={'forgotPassword'} onClick={ModalOpenHandler} type={'button'} />
          </Form>
        </Content>
      </Container>
      <Modal
        show={modals.forgotPassword}
        onClose={ModalCloseHandler}
        name={'forgotPassword'}
        background={'rgba(0, 184, 148, 0.9)'}
        width="500px"
      >
        <Form id={'loginForm'} handleSubmit={HandleSubmitForgot}>
          <i className="fa-solid fa-lock" style={{ color: 'white', fontSize: '2em' }}></i>
          <h2 style={{ color: 'white' }}>Forgot Password</h2>
          <small style={{ color: 'white' }}>Enter your email before we reset your password.</small>
          <hr style={{ width: '100%', border: '1px solid #107D56' }} />
          <FormInput
            padding="0.2em"
            name={'email'}
            value={email}
            onChange={(e: BaseSyntheticEvent) => {
              setEmail(e.target.value);
            }}
            placeHolder="sample@email.com"
          />
          <FormInput
            padding="0.2em"
            name={'username'}
            value={username}
            onChange={(e: BaseSyntheticEvent) => {
              setUserName(e.target.value);
            }}
            placeHolder="Username"
          />
          <Container flex justify="space-around" width="90%">
            <FormButton width="40%" title={'Submit'} success name={'forgotPassword'} onClick={HandleSubmitForgot} type={'submit'} />
            <FormButton width="40%" title={'Cancel'} secondary name={'forgotPassword'} onClick={ModalCloseHandler} type={'button'} />
          </Container>
        </Form>
      </Modal>
    </div>
  );
};

export default Login;
