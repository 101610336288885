import axios from 'axios';
import { BaseSyntheticEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Container, { Content } from '../components/Container/Container';
import Form, { FormButton, FormInput } from '../components/Form/Form';
import Modal from '../components/Modal/Modal';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess, SwalSuccessCustom } from '../components/Swal/Swal';
import AuthContext from '../context/AuthContext';
import axiosInstance from '../utils/axiosInstance';
import { GetGenericList, GetMe } from '../utils/GetApi';
import { BaseListView, Me, Parish } from '../utils/Interface';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = useLocation();

  // const {userLogin} = useContext(AuthContext)

  const HandleSubmit = async (ev: BaseSyntheticEvent) => {
    ev.preventDefault();
  };

  const HandleSubmitForgot = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (newPass !== confirmPass) {
      return SwalFailed('Password does not match!');
    }
    if (newPass.trim() === '' || confirmPass.trim() === '') {
      return SwalFailed('All fields are required!');
    }
    axiosInstance
      .patch(`${url.pathname}${url.search}`, {
        password: newPass,
        confirm_password: confirmPass,
      })
      .then((response) => {
        SwalSuccess('Your password has been changed!').then(() => {
          navigate('/', { preventScrollReset: true });
        });
      })
      .catch((error) => {
        SwalFailed('Oops!..Error encountered.');
      });
  };

  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const LoginDataHandler = ({ target: { name, value } }: BaseSyntheticEvent) => {
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  const [modals, setModals] = useState({
    forgotPassword: false,
  });

  const ModalOpenHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: false }));

  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const BackToLogin = () => {
    navigate('/', { preventScrollReset: true });
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        background: 'url(img/bgAmsnewDark.png) center center / cover no-repeat fixed',
        height: '100vh',
      }}
    >
      <Container flex minWidth="320px" maxWidth="900px" justify="space-around" bgColor="rgba(41, 141, 105, .66)">
        <Content flex direction="column" minWidth="200px" maxWidth="450px" padding="0 1em">
          <img src="./churchLogo.png" width={180} style={{ margin: 'auto' }} />
          <h1 style={{ color: 'white' }}>Welcome to LAMPARA PARISH ACCOUNTING</h1>
          <small style={{ textAlign: 'left', color: 'white' }}>v.0.2</small>
        </Content>
        <Content flex justify="center" minWidth="300px" direction="column" maxWidth="450px" padding="0 1em">
          <Form id={'loginForm'} handleSubmit={HandleSubmit}>
            <h1 style={{ color: 'white' }}>Reset Password</h1>
            <hr style={{ width: '100%' }} />
            <small style={{ color: 'white', marginRight: 'auto' }}>Please input new password</small>
            <FormInput
              margin=".2em 0"
              type="password"
              name={'password'}
              value={newPass}
              onChange={(e: BaseSyntheticEvent) => {
                setNewPass(e.target.value);
              }}
              placeHolder="New Password"
            />
            <FormInput
              margin=".2em 0"
              type="password"
              name={'password'}
              value={confirmPass}
              onChange={(e: BaseSyntheticEvent) => {
                setConfirmPass(e.target.value);
              }}
              placeHolder="Confirm Password"
            />
            <FormButton success title={'Reset Password'} name={'loginBTN'} onClick={HandleSubmitForgot} type={'submit'} />
            <FormButton secondary title={'Back to Login Page'} name={'forgotPassword'} onClick={BackToLogin} type={'button'} />
          </Form>
        </Content>
      </Container>
      {/* <Modal show={modals.forgotPassword} 
				onClose={ModalCloseHandler} 
				name={"forgotPassword"}
				background={'rgba(0, 184, 148, 0.9)'}
				width="500px"
				footer={[
				]}>
				<Form id={"loginForm"} handleSubmit={HandleSubmitForgot}>
					<i className="fa-solid fa-lock" style={{color : "white", fontSize : "2em"}}></i>
					<h2 style={{color : 'white'}}>Forgot Password</h2>
					<small style={{color : 'white'}}>Enter your email before we reset your password.</small>
					<hr style={{width: "100%", border : "1px solid #107D56"}}/>
					<FormInput padding="0.2em"
						name={"email"}
						value={email} onChange={(e : BaseSyntheticEvent)=>{setEmail(e.target.value)}} placeHolder="sample@email.com"/>
					<FormInput padding="0.2em"
						name={"username"}
						value={username} onChange={(e : BaseSyntheticEvent)=>{setUserName(e.target.value)}} placeHolder="Username"/>
					<Container flex justify="space-around" width="90%">
						<FormButton width="40%" title={"Submit"} success name={"forgotPassword"} onClick={HandleSubmitForgot} type={"submit"}/>
						<FormButton width="40%" title={"Cancel"} secondary name={"forgotPassword"} onClick={ModalCloseHandler} type={"button"}/>
					</Container>
				</Form>
			</Modal> */}
    </div>
  );
};

export default ForgotPassword;
