// export type IncomeExpenseType = {
//     January? : {income: string , expense: string , total_amount: string },
//     February? : {income: string , expense: string , total_amount: string },
//     March? : {income: string , expense: string , total_amount: string },
//     April? : {income: string , expense: string , total_amount: string },
//     May? : {income: string , expense: string , total_amount: string },
//     June? : {income: string , expense: string , total_amount: string },
//     July? : {income: string , expense: string , total_amount: string },
//     August? : {income: string , expense: string , total_amount: string },
//     September? : {income: string , expense: string , total_amount: string },
//     October? : {income: string , expense: string , total_amount: string },
//     November? : {income: string , expense: string , total_amount: string },
//     December? : {income: string , expense: string , total_amount: string },
// }

export type IncomeExpenseType = {
  month: MonthListType;
  income: string;
  expense: string;
}[];

export type EachMonth = { income: number; expense: number; month: MonthListType };
const MonthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
type MonthListType =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

export const ProcessDashboardIncomeExpense = (list: IncomeExpenseType) => {
  const newMonths = MonthList;
  const processedMonths = newMonths.map((month) => {
    const newMonth = list.find((item) => item.month === month);
    if (newMonth) {
      return {
        income: parseFloat(newMonth.income ?? 0),
        expense: parseFloat(newMonth.expense ?? 0),
        month: newMonth.month,
      };
    }
    return {
      income: 0,
      expense: 0,
      month: month as MonthListType,
    };
  });
  return processedMonths;
};
