import { Type } from 'typescript';
import { SwalFailed } from '../components/Swal/Swal';
import axiosInstance from './axiosInstance';
import {
  ArNo,
  CvNo,
  BaseListView,
  CoAListType,
  Entry,
  ExpenseBreakoutType,
  Me,
  ReportsList,
  VoucherPartType,
  ParishWithDetailsType,
  CashReportsType,
  CashReceiptsReportsType,
  VoucherItemType,
  FileShareType,
  VoucherPartReportsType,
  VoucherReportsType,
  UserListType,
  Parish,
  PCVTYpe,
  PettyBalanceType,
  PettyCurrentBalanceType,
  JVType,
  JournalVoucherListType,
} from './Interface';
import { SetStateAction } from 'react';

interface GetGenericList<Type> {
  apiUrl: string;
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
  onSuccess?: Function;
  onFail?: Function;
  callback?: Function;
}

export const GetGenericList = async <Type,>({ apiUrl, setList, onSuccess, onFail, callback }: GetGenericList<Type>) =>
  await axiosInstance
    .get(apiUrl)
    .then((res) => {
      if (res) {
        setList(res.data);
        if (onSuccess) onSuccess();
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => {
      if (onFail) onFail();
      return null;
    })
    .finally(() => {
      if (callback) callback();
    });

export const GetMe = async (token: string) =>
  await axiosInstance
    .get('api/me/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data as Me)
    .catch((err) => null);

interface GetParticularList<Type> {
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
}

export const GetParticularList = async <Type,>({ setList }: GetParticularList<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/particulars/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

interface GetCoA<Type> {
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
}

export const GetCoA = async <Type,>({ setList }: GetCoA<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/chart-of-accounts/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res: { data: SetStateAction<BaseListView<Type> | null> }) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch(() => null);
};

interface GetCoAListType<Type> {
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
  type: string;
}

export const GetCoAListType = async <Type,>({ setList, type }: GetCoAListType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/chart-of-accounts-tags-particulars/${type}/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetCoAList = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/coa-list/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as CoAListType[])
    .catch((err) => null);
};

interface GetEntriesList<Type> {
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
}

export const GetEntriesList = async <Type,>({ setList }: GetEntriesList<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/entries/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      setList(res.data);
      return res.data as BaseListView<Type>;
    })
    .catch((err) => null);
};

interface GetVoucher<Type> {
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
  pageNum: number;
  limit: number;
}

export const GetVoucher = async <Type,>({ setList, pageNum, limit }: GetVoucher<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/vouchers/?limit=${limit}&page=${pageNum}${getPK ? `&filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetVoucherItem = async <Type,>({ setList }: GetVoucher<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/voucher-items/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetExpenseBreakout = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/dashboard-expense-breakout/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as ExpenseBreakoutType[])
    .catch((err) => null);
};

export const GetReportList = async (dateFrom: string, dateTo: string, type: string) =>
  await axiosInstance
    .get(`/parish/api/reports/${type}?start=${dateFrom}&end=${dateTo}`)
    .then((res) => {
      if (res) {
        return res.data as ReportsList;
      }
      return null;
    })
    .catch((err) => null);

export const GetCvNo = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/cv-no-next${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as CvNo)
    .catch((err) => null);
};

interface GetGenericType<Type> {
  setList: React.Dispatch<React.SetStateAction<BaseListView<Type> | null>>;
  pageNum?: number;
  limit?: number;
  has_req?: boolean;
}

export const GetCurrentAssets = async <Type,>({ setList }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/current-asset-particulars${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetIncomePart = async <Type,>({ setList }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/income-particulars${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetCashReceipt = async <Type,>({ setList, pageNum, limit }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`parish/api/cash-receipts/list?limit=${limit}&page=${pageNum}${getPK ? `&filter=${getPK}` : ''}`)
    .then((res) => {
      if (res === undefined) return;
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetAR = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/ar-no-next${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as ArNo)
    .catch((err) => null);
};

export const GetVoucherPart = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/voucher/particulars/list${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as VoucherPartType[])
    .catch((err) => null);
};

export const GetChurchWithDetails = async (setList: React.Dispatch<React.SetStateAction<BaseListView<ParishWithDetailsType> | null>>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/api/church-account-with-detail/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<ParishWithDetailsType>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetCashReports = async (dateFrom: string, dateTo: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(
      `/parish/api/cash-receipt-reports${dateFrom ? `?start=${dateFrom}` : ''}${dateTo ? `&end=${dateTo}` : ''}${
        getPK ? `&filter=${getPK}` : ''
      }`
    )
    .then((res) => {
      if (res) {
        return res.data as CashReportsType;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetCodes = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/particulars-codes/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        return res.data;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetVoucherItemPart = async (voucher_id: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/vouchers/items/${voucher_id}${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        return res.data as VoucherItemType;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetFilesShare = async (setList: React.Dispatch<React.SetStateAction<BaseListView<FileShareType> | null>>, filter?: string) =>
  await axiosInstance
    .get(`parish/api/file-share/list/${filter ? `?filter=${filter}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<FileShareType>;
      }
      return null;
    })
    .catch((err) => null);

export const GetVoucherReports = async (dateFrom?: string, dateTo?: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(
      `/parish/api/voucher-report/${dateFrom ? `?start=${dateFrom}` : ''}${dateTo ? `&end=${dateTo}` : ''}${
        getPK ? `&filter=${getPK}` : ''
      }`
    )
    .then((res) => {
      if (res) {
        return res.data as VoucherReportsType;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetUserList = async (
  setList: React.Dispatch<React.SetStateAction<BaseListView<UserListType> | null>>,
  limit?: number,
  pageNum?: number
) =>
  await axiosInstance
    .get(`/api/user-profile/list?limit=${limit}&page=${pageNum}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<UserListType>;
      }
      return null;
    })
    .catch((err) => null);

export const GetMyAccInfo = async () =>
  await axiosInstance
    .get(`/api/me/`)
    .then((res) => {
      if (res) {
        return res.data as Me;
      }
      return null;
    })
    .catch((err) => null);

export const GetChurch = async (setList: React.Dispatch<React.SetStateAction<BaseListView<Parish> | null>>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/api/church/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Parish>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetPCV = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/pcv-no-next${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as PCVTYpe)
    .catch((err) => null);
};

export const GetPettyCashList = async <Type,>({ setList, pageNum, limit, has_req }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`parish/api/petty-cash-voucher/list?has_check_voucher=${has_req}&limit=${limit}&page=${pageNum}${getPK ? `&filter=${getPK}` : ''}`)
    .then((res) => {
      if (res === undefined) return;
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetPettyCashReqList = async <Type,>({ setList, pageNum, limit, has_req }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`parish/api/petty-cash-voucher/list?has_check_voucher=${has_req}&limit=${limit}&page=${pageNum}${getPK ? `&filter=${getPK}` : ''}`)
    .then((res) => {
      if (res === undefined) return;
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetPettyBalance = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/petty-cash-voucher/balance${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as PettyBalanceType)
    .catch((err) => null);
};

export const GetCurrentBalPetty = async <Type,>({ setList }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/particulars/view/Petty Cash Fund/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res === undefined) return;
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetCurrentBalPetty2 = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/particulars/view/Petty Cash Fund/${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as BaseListView<PettyCurrentBalanceType>)
    .catch((err) => null);
};

export const GetJV = async () => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/jv-no-next${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => res.data as JVType)
    .catch((err) => null);
};

export const GetJournalVoucherList = async <Type,>({ setList, pageNum, limit }: GetVoucher<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/journal-voucher/list?limit=${limit}&page=${pageNum}${getPK ? `&filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetJournalVoucherView = async (voucher_id: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/journal-voucher/view/${voucher_id}${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res) {
        return res.data as JournalVoucherListType;
      }
      return null;
    })
    .catch((err) => null);
};

export const GetCashInBankPart = async <Type,>({ setList }: GetGenericType<Type>) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`parish/api/cash-in-bank/list${getPK ? `?filter=${getPK}` : ''}`)
    .then((res) => {
      if (res === undefined) return;
      if (res) {
        setList(res.data);
        return res.data as BaseListView<Type>;
      }
      return null;
    })
    .catch((err) => null);
};
