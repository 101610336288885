import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement, ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { BarChart as BarChartType, PieChart as PieChartType } from './ChartInterface';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export const BarChart = ({ labels, datasets, id }: BarChartType) => (
  <Bar
    datasetIdKey={id}
    options={{
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
    }}
    data={{
      labels: labels,
      datasets: datasets,
    }}
  />
);

export const PieChart = ({ labels, datasets, id }: PieChartType) => (
  <Pie
    height={550}
    width={500}
    datasetIdKey={id}
    options={{
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
    }}
    data={{
      labels: labels,
      datasets: datasets,
    }}
  />
);
