import { ReactNode } from 'react';
import { Loader } from '../Loader/Spinner';
import style from './container.module.css';

interface BaseContainer {
  flex?: Boolean;
  grid?: Boolean;
  width?: string;
  height?: string;
  maxWidth?: string;
  minWidth?: string;
  justify?: string;
  children: ReactNode;
  bgColor?: string;
  opacity?: string;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  padding?: string;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  margin?: string;
}

interface Container extends BaseContainer {}

interface Content extends BaseContainer {
  alignContent?: string;
  grow?: string;
  isLoading?: boolean;
}

export const Content = ({
  children,
  flex,
  grid,
  width,
  justify,
  bgColor,
  opacity,
  direction,
  maxWidth,
  minWidth,
  padding,
  alignContent,
  textAlign,
  margin,
  height,
  grow,
  isLoading,
}: Content) => (
  <div
    className={`${GetDisplayType(flex, grid)} ${style.contentContainer}`}
    style={{
      width,
      justifyContent: justify,
      maxWidth,
      minWidth,
      background: bgColor,
      padding,
      opacity,
      flexDirection: direction,
      alignContent,
      textAlign,
      margin,
      flexGrow: grow,
      height,
    }}
  >
    {isLoading && <div className={style.contentLoader}></div>}
    {children}
  </div>
);

const GetDisplayType = (flex: Boolean | undefined, grid: Boolean | undefined) => {
  if (flex) return style.displayFlex;
  if (grid) return style.displayGrid;
};

const GetStyleType = (
  width: string | undefined,
  justify: string | undefined,
  bgColor: string | undefined,
  opacity: string | undefined,
  direction: string | undefined,
  maxWidth?: string | undefined,
  minWidth?: string | undefined,
  padding?: string | undefined,
  alignContent?: string | undefined,
  textAlign?: string | undefined,
  margin?: string,
  grow?: string
) => {
  let base = {};
  if (width) base = { ...base, width: width };
  if (maxWidth) base = { ...base, maxWidth: maxWidth };
  if (minWidth) base = { ...base, minWidth: minWidth };
  if (justify) base = { ...base, justifyContent: justify };
  if (bgColor) base = { ...base, background: bgColor };
  if (opacity) base = { ...base, opacity: opacity };
  if (direction) base = { ...base, flexDirection: direction };
  if (padding) base = { ...base, padding: padding };
  if (alignContent) base = { ...base, alignContent: alignContent };
  if (textAlign) base = { ...base, textAlign: textAlign };
  if (margin) base = { ...base, margin: margin };
  if (grow) base = { ...base, flexGrow: grow };
  return base;
};

const Container = ({
  children,
  flex,
  grid,
  width,
  justify,
  bgColor,
  opacity,
  direction,
  maxWidth,
  minWidth,
  padding,
  textAlign,
}: Container) => (
  <div
    className={`${style.container} ${GetDisplayType(flex, grid)}`}
    style={GetStyleType(width, justify, bgColor, opacity, direction, maxWidth, minWidth, padding, undefined, textAlign)}
  >
    {children}
  </div>
);

type ContainerFadeType = {
  children: ReactNode;
  message: string;
  icon?: ReactNode;
};

export const ContainerFade = ({ children, message, icon }: ContainerFadeType) => (
  <div className={style.containerFade}>
    <div className={style.foreground}>
      <div className={style.message}>
        <div className={style.icon}>{icon}</div>
        {message}
      </div>
    </div>
    {children}
  </div>
);

export default Container;
