import { useEffect, useState } from 'react';
import { GetTokens, isTokenValid } from '../../utils/axiosInstance';
import { Me } from '../../utils/Interface';
import { IsTablet } from '../Functions/mediaQuery';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import SidebarProf from '../Sidebar/SidebarProf';
import style from './dashboard.module.css';
import Header from './Header';

interface ProfileDashboardType {
  Component: Function;
  plain?: boolean;
  title?: string;
  noTitle?: Boolean;
  noSidebar?: Boolean;
  noBurger?: Boolean;
  noNavbar?: Boolean;
}

const ProfileDashboard = ({ Component, title, noTitle, noSidebar, noNavbar, plain }: ProfileDashboardType) => {
  const ViewHandler = IsTablet();

  const [expand, setExpand] = useState(false);

  const Expander = () => {
    setExpand((prev) => !prev);
  };

  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setIsAdmin(true);
      }
      setMyAccInfo(tokens);
    }
  };
  useEffect(() => {
    SetMe();
  }, []);

  const View = ViewHandler ? style.none : expand ? style.collapse : style.expand;
  const navView = ViewHandler ? style.none : expand ? style.collapseNav : style.expandNav;

  return (
    <>
      {!noNavbar && <Navbar view={ViewHandler} media={navView} />}
      <div>
        <div className={style.container}>
          {isAdmin ? (
            <SidebarProf view={ViewHandler} expander={Expander} hidden={noSidebar} />
          ) : (
            <Sidebar view={ViewHandler} expander={Expander} hidden={noSidebar} />
          )}
          <div className={`${plain ? '' : style.main} ${plain ? '' : View}`}>
            {!noTitle && title && <Header title={title} />}
            <Component />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
