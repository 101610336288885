import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { ColorType } from '../Interface/Pages';
import { Loader } from '../Loader/Spinner';
import { GetColorType } from '../styles/background';
import { GetColor } from '../styles/color';
import style from './table2.module.css';

interface Table2Type extends ColorType {
  header: (string | JSX.Element)[];
  children: ReactNode;
  width?: string;
  height?: string;
  tableWidth?: string | number;
  isLoading?: boolean;
  className?: string;
  headerPadding?: string | number;
}

export const TableRow2 = ({ info, padding, colspan }: { info: ReactElement[]; padding?: string; colspan?: number }) => (
  <tr className={style.row}>
    {info.map((data, i) => (
      <td colSpan={colspan} key={i} className={style.data} style={{ padding: padding }}>
        {data}
      </td>
    ))}
  </tr>
);

interface TableRow2HeaderType {
  info: ReactElement[];
  tdWidth?: string | number;
}

export const TableRow2Header = ({ info, tdWidth }: TableRow2HeaderType) => (
  <tr className={`${style.rowHeader} border-t border-green-800 print-row-header`}>
    {info.map((data, i) => (
      <td
        key={i}
        className={style.data}
        style={{
          width: tdWidth,
        }}
      >
        {data}
      </td>
    ))}
  </tr>
);

interface TableAction2Type {
  icon: ReactElement;
  onClick: MouseEventHandler;
}

export const TableAction2 = ({ icon, onClick }: TableAction2Type) => (
  <button className={style.actionButton} onClick={onClick}>
    {icon}
  </button>
);

const Table2 = ({
  header,
  success,
  secondary,
  primary,
  warning,
  danger,
  width,
  height,
  children,
  tableWidth,
  isLoading,
  className,
  headerPadding,
}: Table2Type) => (
  <div className={`${style.container} ${className}`} style={width ? { maxWidth: width } : {}}>
    {isLoading && (
      <div className={style.containerLoader}>
        <Loader type={'roller'} />
      </div>
    )}
    <table className={GetColor({ success, secondary, primary, warning, danger })} style={{ width: tableWidth }}>
      <thead className={`${style.header} ${GetColorType({ success, secondary, primary, warning, danger })}`}>
        <tr>
          {header.map((head, i) => (
            <th key={i} className={style.head} style={{ padding: headerPadding }}>
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={style.body} style={height ? { maxHeight: height } : {}}>
        {children}
      </tbody>
    </table>
  </div>
);

export default Table2;
