import React, { BaseSyntheticEvent, FormEvent, MouseEventHandler, useEffect, useRef, useState } from 'react';
import Container, { Content } from '../components/Container/Container';
import Form, { FieldContainer, FormButton, FormInput, FormTextArea } from '../components/Form/Form';
import Modal, { ModalButton, ModalFooterButton } from '../components/Modal/Modal';
import { SwalFailed, SwalLoading, SwalSuccess, SwalUnderCons } from '../components/Swal/Swal';
import Table, { TableAction, TableEmpty, TableRow } from '../components/Table/Table';
import Table2, { TableRow2 } from '../components/Table/Table2';
import { FormatDate, ParseToPHP } from '../utils/Formatter';
import {
  GetCvNo,
  GetGenericList,
  GetJournalVoucherList,
  GetJournalVoucherView,
  GetJV,
  GetMe,
  GetVoucher,
  GetVoucherItem,
  GetVoucherItemPart,
  GetVoucherPart,
  GetVoucherReports,
} from '../utils/GetApi';
import {
  BaseListView,
  CvNo,
  JournalVoucherListType,
  JournalVoucherPartItemType,
  Me,
  Particulars,
  ParticularTags,
  VoucherItemListType,
  VoucherItemType,
  VoucherPartReportsType,
  VoucherPartType,
  VoucherReportsType,
  VoucherType,
} from '../utils/Interface';
import {
  PostJournalVoucher,
  PostJournalVoucherItem,
  PostVoucher,
  PostVoucherItem,
  PostVoucherItemType,
  PostVoucherType,
} from '../utils/PostApi';
import style from '../components/Form/form.module.css';
import style3 from '../components/styles/cashreceipts.module.css';
import axiosInstance, { GetTokens } from '../utils/axiosInstance';
import printJS from 'print-js';
import { TableLoader } from '../components/Loader/Spinner';
import VoucherView from './VoucherPages/voucherView';
import VoucherRePrint from './VoucherPages/VoucherRePrint';
import ChequeVoucherPrint from './VoucherPages/ChequeVoucherPrint';
import VoidSticker from '../components/Modal/VoidSticker';
import VoucherReportsTable from './VoucherPages/VoucherReports';
import VoucherReportPrint from './VoucherPages/VoucherReportsPrint';
import Pagination from '../components/Pagination/paginate';
import GoToPage from '../components/Pagination/GoToPage';
import { v4 as uuid } from 'uuid';
import ReactLoading from 'react-loading';
import JournalVoucherView from './VoucherPages/JournalVoucherView';
import JournalVoucherRePrint from './VoucherPages/JournalVoucherReprint';

const JournalVoucher = () => {
  const GetVoucherList = async () => {
    setDisableNextPrev(true);

    GetJournalVoucherList<JournalVoucherListType>({
      setList: setJournalVoucherList,
      pageNum: currentPage,
      limit: apiLimit,
    }).then((res) => {
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });
  };

  const [date, setDate] = useState('');
  const [jv, setJV] = useState<string | undefined>();
  const [amountInPeso, setAmountInPeso] = useState('');
  const [description, setDescription] = useState('');
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const writtenNumber = require('written-number');
  const toAmountWords = writtenNumber(amountInPeso);
  const [voucherPartList, setVoucherPartList] = useState<VoucherPartType[] | null>(null);
  const [voucherList, setVoucherList] = useState<PostVoucherType | null>(null);
  const [voucherItemList, setVoucherItemList] = useState<JournalVoucherListType | null>(null);

  const [journalVoucherList, setJournalVoucherList] = useState<BaseListView<JournalVoucherListType> | null>(null);
  const [selectedCVoucher, setSelectedCVouchert] = useState<JournalVoucherListType | null>(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showRePrint, setShowRePrint] = useState(false);
  const [showVoid, setShowVoid] = useState(false);
  const [rerender3, setRerender3] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPrevLoad, setNextPrevLoad] = useState(true);
  const [apiLimit, setApiLimit] = useState(25);
  // eslint-disable-next-line prefer-const
  let [jumpToPage, setJumpToPage] = useState(1);
  const [disableNextPrev, setDisableNextPrev] = useState(true);
  const [message, setMessage] = useState('');

  const SetMe = async () => {
    const res = GetTokens();
    setMyAccInfo(res);
  };

  useEffect(() => {
    SetMe();
    GetVoucherList();
  }, [rerender3, currentPage, apiLimit]);

  // #region Voucher Item

  interface VoucherItem {
    id: string;
    code: string;
    name: string;
    particular: string;
    debit: string;
    credit: string;
  }

  const [voucherItem, setVoucherItem] = useState<(VoucherItem & VoucherStatusType)[]>([
    { id: uuid(), code: '', name: '', particular: '', isSuccess: false, debit: '', credit: '' },
  ]);
  const voucherID = uuid();
  const [rerender, setRerender] = useState(true);
  const [rerender2, setRerender2] = useState(true);

  const voucherItemHandler = (index: number, name: 'code' | 'name' | 'debit' | 'credit', { target }: BaseSyntheticEvent) => {
    voucherItem[index][name] = target.value;
    if (name === 'name') {
      const code = getMatchingParticularCode(target.value, voucherPartList);
      const id = getMatchingParticularID(target.value, voucherPartList);
      voucherItem[index].code = code ?? 'Invalid Particular';
      voucherItem[index].particular = id ?? '';
    }
    if (name === 'debit') {
      voucherItem[index].credit = '';
    }
    if (name === 'credit') {
      voucherItem[index].debit = '';
    }
    GetTotalAmount();
    setRerender((prev) => !prev);
  };

  const getMatchingParticularCode = (name: string, possibleParticulars?: VoucherPartType[] | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.find((item) => item.name.toLowerCase() === name.toLowerCase())?.code;
  };

  const getMatchingParticularID = (name: string, possibleParticulars?: VoucherPartType[] | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.find((item) => item.name.toLowerCase() === name.toLowerCase())?.id;
  };

  useEffect(() => {
    setVoucherItem(voucherItem);
    setVoucherList(voucherList);
    // setVoucherItemContainer(voucherItem)
  }, [rerender]);

  const AddButton = (
    onClick: MouseEventHandler,
    text: string,
    color: string,
    align: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
    disable?: boolean
  ) => (
    <div style={{ textAlign: align }}>
      <button
        style={{ border: 'none', background: 'none', fontWeight: '500', color: color }}
        onClick={onClick}
        disabled={disable}
        type={'button'}
      >
        <i className="fa-solid fa-plus"></i>
        {text}
      </button>
    </div>
  );

  const AddNewPart = () => {
    const lastVoucherItem = voucherItem[voucherItem.length - 1];
    if (lastVoucherItem.name.trim() === '') {
      SwalFailed('No Account Title!');
      return;
    }
    if (lastVoucherItem.debit === '' && lastVoucherItem.credit === '') {
      SwalFailed('No Debit or Credit Amount!');
      return;
    }
    setVoucherItem((prev) => [...prev.concat({ id: uuid(), code: '', name: '', particular: '', isSuccess: false, debit: '', credit: '' })]);

    // TO BE DELETED
  };

  const GetTotalAmount = () => {
    const debit = voucherItem.map((item) => parseFloat(item.debit === '' ? '0' : item.debit));
    const credit = voucherItem.map((item) => parseFloat(item.credit === '' ? '0' : item.credit));
    const totaldebit = debit.reduce((total, currentval) => (total = total + currentval), 0);
    const totalcredit = credit.reduce((total, currentval) => (total = total + currentval), 0);

    setTotalDebit(totaldebit);
    setTotalCredit(totalcredit);
    if (totaldebit > totalcredit) {
      setAmountInPeso(totaldebit.toString());
    }
    if (totaldebit < totalcredit) {
      setAmountInPeso(totalcredit.toString());
    }
  };
  // #endregion Voucher List

  // #region try bulk adding
  const [errorOccured, setErrorOccured] = useState(false);
  const [lastIdPosted, setLastIdPost] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  interface VoucherStatusType {
    isPosting?: boolean;
    isSuccess?: boolean;
    isDone?: boolean;
  }

  const HandleVoucherChangeOnStatus = (id: string | undefined, isPosting: boolean, isSuccess: boolean, isDone: boolean) => {
    setVoucherItem((prev) => [
      ...prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isPosting,
            isSuccess,
            isDone,
          };
        }
        return item;
      }),
    ]);
  };

  const SubmitBulkAddJournalVoucher = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (!myAccInfo) return;

    const lastVoucherItem = voucherItem[voucherItem.length - 1];
    const id = voucherItem.find((item) => item.id);
    if (date === '') {
      SwalFailed('Date is Empty');
      return;
    }
    if (description.trim() === '') {
      SwalFailed('Description is Empty');
      return;
    }
    if (id?.particular === '') {
      SwalFailed('Invalid Account Title');
      return;
    }
    if (lastVoucherItem.name.trim() === '') {
      SwalFailed('No Account Title!');
      return;
    }
    if (totalDebit !== totalCredit || totalCredit !== totalDebit) {
      SwalFailed('Debit and Credit is not equal');
      return;
    }

    let error = false;

    setIsLoading(true);
    const filteredVoucherItem = voucherItem.filter((item) => item.isSuccess === false);
    if (filteredVoucherItem.length <= 0) {
      setIsLoading(false);
      return SwalFailed('Something went wrong!');
    }
    setLastIdPost(voucherID);
    setDisableAll(true);
    const JournalVoucher = await PostJournalVoucher({
      id: voucherID,
      church: myAccInfo.church,
      church_account: myAccInfo.church_account,
      date: date,
      description: description,
      jv_no: jv,
      amount: amountInPeso,
    });

    if (!JournalVoucher) {
      SwalFailed('Something went Wrong!');
      return;
    }
    for (let i = 0; i < filteredVoucherItem.length; i++) {
      const voucherType = filteredVoucherItem[i];
      HandleVoucherChangeOnStatus(voucherType.id, true, false, false);
      const isSuccess = await PostJournalVoucherItem({
        id: voucherType.id,
        church: myAccInfo.church,
        particular: voucherType.particular,
        code: voucherType.code,
        journal_voucher: JournalVoucher.id,
        debit: parseFloat(voucherType.debit === '' ? '0' : voucherType.debit),
        credit: parseFloat(voucherType.credit === '' ? '0' : voucherType.credit),
      });
      if (isSuccess) {
        HandleVoucherChangeOnStatus(voucherType.id, false, true, true);
      } else {
        HandleVoucherChangeOnStatus(voucherType.id, false, false, true);
        error = true;
        setErrorOccured(true);
      }
    }

    setIsLoading(false);
    if (error) return SwalFailed('Something went wrong!');
    SwalSuccess('Particular/s Added!').then((res) => {
      setShowAdd(true);
      setDisableAll(false);
    });
  };

  const ReSubmitBulkAddJournalVoucher = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (!myAccInfo) return;

    let error = false;

    setIsLoading(true);
    const filteredVoucherItem = voucherItem.filter((item) => item.isSuccess === false);
    if (filteredVoucherItem.length <= 0) {
      setIsLoading(false);
      return SwalFailed('Something went wrong!');
    }
    setDisableAll(true);
    // Check if jVoucher is already existing
    await axiosInstance
      .get(`/parish/api/journal-voucher/update/${lastIdPosted}`)
      .then((res) => {})
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') return null;
        error = true;
        setErrorOccured(true);
      });

    await axiosInstance
      .get(`/parish/api/journal-voucher-item/update/${filteredVoucherItem[0].id}`)
      .then((res) => {})
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') return null;
        error = true;
        setErrorOccured(true);
      });

    // Handle the result of updating the first item
    if (error) {
      SwalFailed('Something went wrong!');
      return;
    }
    // HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, true, false, false)
    const journalIsUpdated = await axiosInstance.put(`/parish/api/journal-voucher/update/${lastIdPosted}`, {
      id: lastIdPosted,
      church: myAccInfo.church,
      church_account: myAccInfo.church_account,
      date: date,
      description: description,
      jv_no: jv,
      amount: amountInPeso,
    });
    if (!journalIsUpdated) {
      SwalFailed('Something went wrong!');
      return;
    }
    HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, true, false, false);
    const isUpdated = await axiosInstance.put(`/parish/api/journal-voucher-item/update/${filteredVoucherItem[0].id}`, {
      id: filteredVoucherItem[0].id,
      church: myAccInfo.church,
      particular: filteredVoucherItem[0].particular,
      code: filteredVoucherItem[0].code,
      journal_voucher: journalIsUpdated.data.id,
      debit: parseFloat(filteredVoucherItem[0].debit === '' ? '0' : filteredVoucherItem[0].debit),
      credit: parseFloat(filteredVoucherItem[0].credit === '' ? '0' : filteredVoucherItem[0].credit),
    });
    if (isUpdated) {
      HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, false, true, true);
    } else {
      HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, false, false, true);
      error = true;
      setErrorOccured(true);
    }

    // // Post the rest of the items
    for (let i = 1; i < filteredVoucherItem.length; i++) {
      const voucherType = filteredVoucherItem[i];
      HandleVoucherChangeOnStatus(voucherType.id, true, false, false);
      const isSuccess = await PostJournalVoucherItem({
        id: voucherType.id,
        church: myAccInfo.church,
        particular: voucherType.particular,
        code: voucherType.code,
        journal_voucher: journalIsUpdated.data.id,
        debit: parseFloat(voucherType.debit === '' ? '0' : voucherType.debit),
        credit: parseFloat(voucherType.credit === '' ? '0' : voucherType.credit),
      });
      if (isSuccess) HandleVoucherChangeOnStatus(voucherType.id, false, true, true);
      else {
        HandleVoucherChangeOnStatus(voucherType.id, false, false, true);
        error = true;
        setErrorOccured(true);
      }
    }

    setIsLoading(false);
    if (error) return SwalFailed('Something went wrong!');
    SwalSuccess('Particular/s Added!').then((res) => {
      setShowAdd(true);
      setDisableAll(false);
    });
  };
  const PrintJournal = () => {
    Print();
    setDate('');
    setDescription('');
    setAmountInPeso('');
    setVoucherItem([{ id: uuid(), code: '', name: '', particular: '', isSuccess: false, debit: '', credit: '' }]);
    setTotalDebit(0);
    setTotalCredit(0);
    setErrorOccured(false);
    setShowAdd(false);
    setRerender2((prev) => !prev);
  };
  // #endregion

  // #region Voucher View

  const [view, setView] = useState<'table' | 'form'>('table');
  const AddPart = () => {
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === '' || !pKey) {
      return setView((prev) => (prev === 'table' ? 'form' : 'table'));
    }
    if (pKey !== '') return SwalFailed('This is not your parish!');
  };

  useEffect(() => {
    if (view === 'form') {
      GetJV().then((res) => {
        if (res?.next_jv === undefined) return;
        setJV(res.next_jv);
      });

      GetVoucherPart().then((res) => {
        setVoucherPartList(res);
      });
    }
  }, [rerender2, view]);

  // #endregion

  // #region Voucher Item View
  const [loading, setLoading] = useState(false);

  const ViewVoucherItem = (index: number) => {
    setLoading(false);
    if (!journalVoucherList) return;
    const itemIndex = journalVoucherList.results[index];
    setSelectedCVouchert(itemIndex);
    GetJournalVoucherView(itemIndex.id).then((res) => {
      setLoading(true);
      setVoucherItemList(res);
    });
    setShowView(true);
  };

  const ViewPrintVoucherItem = (index: number) => {
    setIsLoading(false);
    if (!journalVoucherList) return;
    const itemIndex = journalVoucherList.results[index];
    setSelectedCVouchert(itemIndex);
    GetJournalVoucherView(itemIndex.id).then((res) => {
      setIsLoading(true);
      setVoucherItemList(res);
    });
    setShowRePrint(true);
  };

  const ShowVoidVoucher = (index: number) => {
    if (!journalVoucherList) return;
    const itemIndex = journalVoucherList.results[index];
    setSelectedCVouchert(itemIndex);
    setShowVoid(true);
  };

  const VoidVoucher = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    axiosInstance
      .patch(`/parish/api/journal-voucher/update/${selectedCVoucher?.id}`, {
        void: true,
        purpose_of_void: message,
      })
      .then((res) => {
        setShowVoid(false);
        setRerender3((prev) => !prev);
        SwalSuccess('Void Successful!');
        setMessage('');
      });
  };

  // #endregion

  const CloseModal = () => {
    setDate('');
    setDescription('');
    setAmountInPeso('');
    setVoucherItem([{ id: uuid(), code: '', name: '', particular: '', isSuccess: false, debit: '', credit: '' }]);
    setTotalDebit(0);
    setTotalCredit(0);
    setErrorOccured(false);
    setShowAdd(false);
    setRerender2((prev) => !prev);
  };

  const decrement = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage - 1);
  };

  const increment = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage + 1);
  };

  const JumpToButton = () => {
    if (!journalVoucherList) return;
    setNextPrevLoad(false);
    setDisableNextPrev(true);
    const lastPage = Math.round(journalVoucherList?.count / apiLimit);
    if (jumpToPage > lastPage) {
      return GetVoucher<JournalVoucherListType>({
        setList: setJournalVoucherList,
        pageNum: (jumpToPage = lastPage),
        limit: apiLimit,
      }).then((res) => {
        setCurrentPage(jumpToPage);
        setNextPrevLoad(true);
        setDisableNextPrev(false);
      });
    }
    if (jumpToPage < 1) {
      return GetVoucher<JournalVoucherListType>({
        setList: setJournalVoucherList,
        pageNum: 1,
        limit: apiLimit,
      }).then((res) => {
        setCurrentPage(1);
        setNextPrevLoad(true);
        setDisableNextPrev(false);
      });
    }
    GetVoucher<JournalVoucherListType>({
      setList: setJournalVoucherList,
      pageNum: jumpToPage,
      limit: apiLimit,
    }).then((res) => {
      setCurrentPage(jumpToPage);
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });
  };

  const DeleteCoaShowModal = (e: BaseSyntheticEvent, index: number) => {
    e.preventDefault();
    if (voucherItem.length > 1) {
      setVoucherItem((prev) => prev.filter((_, i) => i !== index));
      const indexDebit = totalDebit - parseFloat(voucherItem[index].debit === '' ? '0' : voucherItem[index].debit);
      const indexCredit = totalCredit - parseFloat(voucherItem[index].credit === '' ? '0' : voucherItem[index].credit);
      setTotalDebit(indexDebit);
      setTotalCredit(indexCredit);
      if (indexDebit >= indexCredit) {
        setAmountInPeso(indexDebit.toString());
      }
      if (indexDebit <= indexCredit) {
        setAmountInPeso(indexCredit.toString());
      }
    }
  };

  const noScroll = (e: BaseSyntheticEvent) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      {/* <div className={style3.container}>
                <div className={style3.content}  onClick={() => {setShowReports(true)}}>
                    <i className="fa-solid fa-gears" style={{fontSize : "30px"}}></i>
                    <p className={style3.title}>GENERATE VOUCHER REPORTS</p>
                </div>  
            </div> */}
      <Content flex justify="right">
        <FormButton
          width="300px"
          success
          title={view === 'table' ? 'ADD JOURNAl VOUCHER' : 'JOURNAL VOUCHER LIST'}
          name={''}
          disabled={journalVoucherList === null || disableAll}
          onClick={() => {
            AddPart();
          }}
          type={'button'}
        />
      </Content>
      {view === 'table' ? (
        <>
          <Table header={['JV#', 'Date', 'Amount', 'Action']} success>
            {nextPrevLoad ? (
              journalVoucherList?.results ? (
                journalVoucherList.results.length > 0 ? (
                  journalVoucherList.results.map((jVoucher, i) => (
                    <TableRow
                      voidd={jVoucher.void}
                      key={i}
                      info={[
                        <>{jVoucher.jv_no}</>,
                        <>{FormatDate(jVoucher.date)}</>,
                        <>{jVoucher.amount}</>,
                        <>
                          <TableAction
                            icon={<i className="fa-solid fa-magnifying-glass"></i>}
                            onClick={() => {
                              ViewVoucherItem(i);
                            }}
                          />
                          <span style={{ padding: '0.3em' }}></span>
                          <TableAction
                            icon={<i className="fa-solid fa-print"></i>}
                            onClick={() => {
                              ViewPrintVoucherItem(i);
                            }}
                          />
                          <span style={{ padding: '0.3em' }}></span>
                          <TableAction
                            icon={<i className="fa-solid fa-file-circle-minus"></i>}
                            disabled={jVoucher.void === true}
                            onClick={() => {
                              ShowVoidVoucher(i);
                            }}
                          />
                        </>,
                      ]}
                    />
                  ))
                ) : (
                  <TableEmpty colSpan={4}>No Voucher/s Found!</TableEmpty>
                )
              ) : (
                <TableLoader colSpan={4} type={'dual_ring'} />
              )
            ) : (
              <TableLoader colSpan={4} type={'dual_ring'} />
            )}
          </Table>
          <Content flex justify={'space-between'} padding={'0.5em'}>
            <GoToPage
              disabled={disableNextPrev}
              next={journalVoucherList?.next}
              prev={journalVoucherList?.previous}
              jumpTo={JumpToButton}
              currentPage={jumpToPage}
              setCurrentPage={setJumpToPage}
              count={journalVoucherList?.count}
              limit={apiLimit}
            />
            <Pagination
              limit={apiLimit}
              next={journalVoucherList?.next}
              prev={journalVoucherList?.previous}
              currentPage={currentPage}
              increment={increment}
              decrement={decrement}
              count={journalVoucherList?.count}
              disabled={disableNextPrev}
            />
          </Content>
        </>
      ) : (
        <>
          <Container flex justify="space-between">
            <Content grow="1" padding="0em 0.5em">
              <FormInput
                required
                disabled={disableAll}
                title={
                  <>
                    <span className="text-red-500">*</span>Date:
                  </>
                }
                type={'date'}
                name={''}
                value={date}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setDate(target.value);
                }}
              />
              <FormInput
                margin="0.6em 0 0 0"
                required
                disabled={disableAll}
                title={
                  <>
                    <span className="text-red-500">*</span>Description:
                  </>
                }
                name={''}
                value={description}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setDescription(target.value);
                }}
              />
            </Content>
            <Content grow="1" padding="0 0.5em">
              <FormInput
                disabled
                title={
                  <>
                    <span className="text-red-500">*</span>JV#:
                  </>
                }
                name={''}
                value={jv ? jv : ''}
                onChange={({ target }: BaseSyntheticEvent) => {}}
              />
              <FormInput
                disabled
                title={'Amount in words:'}
                name={''}
                value={`${toAmountWords} peso only`}
                onChange={({ target }: BaseSyntheticEvent) => {}}
              />
            </Content>
            <Content grow="1" padding="0 0.5em">
              <FormInput
                required
                disabled
                title={
                  <>
                    <span className="text-red-500">*</span>Amount in Peso:
                  </>
                }
                name={''}
                type="number"
                minValue={1}
                value={amountInPeso}
                onChange={({ target }: BaseSyntheticEvent) => {}}
              />
            </Content>
          </Container>

          <Form id={''} handleSubmit={(e) => SubmitBulkAddJournalVoucher(e)}>
            <Table2 success header={['Code', 'Account Title', 'Debit', 'Credit']}>
              {voucherItem.map((item, i) => (
                <TableRow2
                  colspan={4}
                  key={i}
                  padding=".4em .5em"
                  info={[
                    <div style={{ display: 'flex', margin: '.2em 0' }}>
                      {item.isPosting && (
                        <span style={{ marginTop: '0.8em' }}>
                          <ReactLoading width={25} height={25} type="spin" color="#00b894" />
                        </span>
                      )}
                      {item.isSuccess ? (
                        <span style={{ padding: '0.2em', fontSize: '1.5em' }}>
                          <i className="fa-solid fa-circle-check"></i>
                        </span>
                      ) : (
                        <></>
                      )}
                      <FormInput
                        key={`a-${i}`}
                        {...(i === 0 && { title: '' })}
                        name={''}
                        value={item.code}
                        padding={'.2em'}
                        disabled
                        onChange={(e) => {}}
                      />

                      <div className={style.inputContainer} style={{ padding: '0.2em' }}>
                        <input
                          key={`b-${i}`}
                          type={'text'}
                          {...(i === 0 && { title: '' })}
                          name={''}
                          value={item.name}
                          disabled={disableAll}
                          onChange={(e) => {
                            voucherItemHandler(i, 'name', e);
                          }}
                          list={'partList'}
                        />
                        <datalist id={'partList'}>
                          {voucherPartList?.map((part, i) => (
                            <option key={i} value={part.name}></option>
                          ))}
                        </datalist>
                      </div>

                      <FormInput
                        key={`c-${i}`}
                        type={'number'}
                        {...(i === 0 && { title: '' })}
                        name={''}
                        value={item.debit}
                        padding={'.2em'}
                        disabled={disableAll}
                        onWheel={noScroll}
                        placeHolder={'0'}
                        onChange={(e) => {
                          voucherItemHandler(i, 'debit', e);
                        }}
                        textAlign={'right'}
                      />

                      <FormInput
                        key={`d-${i}`}
                        type={'number'}
                        {...(i === 0 && { title: '' })}
                        name={''}
                        value={item.credit}
                        padding={'.2em'}
                        disabled={disableAll}
                        onWheel={noScroll}
                        placeHolder={'0'}
                        onChange={(e) => {
                          voucherItemHandler(i, 'credit', e);
                        }}
                        textAlign={'right'}
                      />

                      <span style={{ paddingLeft: '0.5em' }}></span>
                      <TableAction
                        icon={<i className="fa-solid fa-trash"></i>}
                        disabled={disableAll}
                        onClick={(e) => {
                          DeleteCoaShowModal(e, i);
                        }}
                      />
                    </div>,
                  ]}
                />
              ))}
              <TableRow2
                padding=".4em .5em"
                info={[
                  AddButton(
                    () => {
                      AddNewPart();
                    },
                    'Add Account Title',
                    disableAll ? 'gray' : '#00b894',
                    'left',
                    disableAll
                  ),
                ]}
              />
              <TableRow2
                padding=".4em .5em"
                info={[
                  <h4 style={{ textAlign: 'start', fontWeight: 'bold', paddingLeft: '0.4em', color: '#055437' }}>Total: </h4>,
                  <h4></h4>,
                  <h4 style={{ textAlign: 'end', fontWeight: 'bold', paddingRight: '1.3em', color: '#055437' }}>
                    {ParseToPHP(totalDebit)}
                  </h4>,
                  <h4 style={{ textAlign: 'end', fontWeight: 'bold', paddingRight: '1.7em', color: '#055437' }}>
                    {ParseToPHP(totalCredit)}
                  </h4>,
                ]}
              />
            </Table2>
            {errorOccured ? (
              <Content flex justify="right">
                <FormButton
                  secondary
                  width="20em"
                  success
                  title={'RETRY JOURNAL VOUCHER'}
                  name={''}
                  onClick={(e) => {
                    ReSubmitBulkAddJournalVoucher(e);
                  }}
                  type={'submit'}
                ></FormButton>
              </Content>
            ) : (
              <Content flex justify="right">
                <FormButton width="18em" success title={'CREATE JOURNAL VOUCHER'} name={''} onClick={() => {}} type={'submit'}></FormButton>
              </Content>
            )}
          </Form>
        </>
      )}
      <Modal
        title={`Journal Voucher`}
        success
        show={showAdd}
        onClose={() => {
          CloseModal();
        }}
        name={''}
        width={'800px'}
      >
        <pre id="PrintVoucher">
          <div style={{ display: 'flex', textAlign: 'start' }}>
            <div style={{ marginRight: 'auto', color: '#055437' }}>
              <p>Date: {FormatDate(date ?? 'No Date')}</p>
              <p>Description: {description}</p>
              <p>Amount in Words: {writtenNumber(amountInPeso)} peso only</p>
            </div>
            <div style={{ color: '#055437' }}>
              <p>JV No.: {jv}</p>
              <p>Amount in Peso: {ParseToPHP(parseFloat(amountInPeso ? amountInPeso : '0'))}</p>
            </div>
          </div>
          <div>
            <Table2 header={['Code', 'Particular', 'Debit', 'Credit']} success>
              {voucherItem.map((item, i) => (
                <TableRow2
                  key={i}
                  info={[
                    <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{item.code}</h4>,
                    <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{item.name}</h4>,
                    <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>
                      {ParseToPHP(parseFloat(item.debit === '' ? '0' : item.debit))}
                    </h4>,
                    <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>
                      {ParseToPHP(parseFloat(item.credit === '' ? '0' : item.credit))}
                    </h4>,
                  ]}
                />
              ))}
              <TableRow2
                info={[
                  <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Total :</h4>,
                  <h4></h4>,
                  <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>{ParseToPHP(totalDebit)}</h4>,
                  <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>{ParseToPHP(totalCredit)}</h4>,
                ]}
              />
            </Table2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
            <p>Prepared By:</p>
            <p>Checked By:</p>
            <p>Approved By:</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
            <p>_______________</p>
            <p>_______________</p>
            <p>_______________</p>
          </div>
        </pre>
        <Container flex justify="center">
          <Content padding="0 1em">
            <FormButton
              width="20em"
              success
              title={'PRINT JOURNAL VOUCHER'}
              name={''}
              onClick={() => {
                PrintJournal();
              }}
              type={'submit'}
            />
          </Content>
          <Content padding="0 1em">
            <FormButton
              width="7em"
              secondary
              success
              title={'CLOSE'}
              name={''}
              onClick={() => {
                CloseModal();
              }}
              type={'button'}
            />
          </Content>
        </Container>
      </Modal>

      <Modal
        title={`Journal Voucher`}
        success
        show={showView}
        onClose={() => {
          setShowView(false);
        }}
        name={''}
        width={'800px'}
      >
        <JournalVoucherView
          selectedVoucher={selectedCVoucher}
          processedVoucherItem={voucherItemList}
          myAccInfo={myAccInfo}
          isLoading={loading}
        />
      </Modal>
      <Modal
        title={`Print Journal Voucher`}
        success
        show={showRePrint}
        onClose={() => {
          setShowRePrint(false);
        }}
        name={''}
        width={'800px'}
      >
        <JournalVoucherRePrint
          selectedVoucher={selectedCVoucher}
          processedVoucherItem={voucherItemList}
          id="rePrint"
          myAccInfo={myAccInfo}
          isLoading={isLoading}
        />
        <Content flex justify="center" padding="0 1em">
          <FormButton
            width="20em"
            success
            title={'PRINT JOURNAL VOUCHER'}
            name={''}
            onClick={() => {
              Print2();
            }}
            type={'submit'}
          />
        </Content>
      </Modal>
      <Modal
        show={showVoid}
        onClose={() => {
          setShowVoid(false);
        }}
        name={'Void Voucher'}
        title={`Void Voucher`}
        width="550px"
        success
      >
        <div>
          <p style={{ color: '#298D69', fontWeight: 'bolder' }}>Are you sure you want to void this voucher?</p>
        </div>
        <div>
          <p style={{ color: 'red', fontWeight: 'bolder', textAlign: 'start', marginTop: '2em' }}>Please Type purpose of void*</p>
        </div>
        <Form
          id={''}
          handleSubmit={(e) => {
            VoidVoucher(e);
          }}
        >
          <FormTextArea
            name={'message'}
            value={message}
            rows={2}
            required
            fontSize="1em"
            onChange={({ target }: BaseSyntheticEvent) => {
              setMessage(target.value);
            }}
          />
          <Content flex justify="space-around" padding="1em 0 0 0">
            <div>
              <FormButton success title={'Confirm'} name={''} onClick={() => {}} type={'submit'} />
            </div>
            <div>
              <FormButton
                title={'Cancel'}
                secondary
                name={'addNewModal'}
                onClick={() => {
                  setShowVoid(false);
                }}
                type={'button'}
              />
            </div>
          </Content>
        </Form>
      </Modal>
    </>
  );
};

const Print = async () => {
  printJS({
    printable: 'PrintVoucher',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

const Print2 = async () => {
  printJS({
    printable: 'rePrint',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

export default JournalVoucher;
