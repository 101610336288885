import { useState } from 'react';
import { Loader } from '../../components/Loader/Spinner';
import Table2, { TableRow2Header } from '../../components/Table/Table2';
import Table3, { TableRow3Header } from '../../components/Table/Table3';
import { ParseToPHP } from '../../utils/Formatter';

const monthList: Month[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type Reports_ByMonthType = {
  list: ReportByMonth;
  isLoading?: boolean;
  months: {
    fromIndex: number;
    toIndex: number;
  };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const Reports_ByMonth = ({ list, months, isLoading }: Reports_ByMonthType) => {
  const income = list.results.find((coa) => coa.chart_of_account === 'Receipts');
  const expense = list.results.find((coa) => coa.chart_of_account === 'Disbursements');
  const newMonthList = monthList.slice(months.fromIndex, months.toIndex);

  return (
    <div>
      {
        <Table2
          isLoading={isLoading}
          success
          header={['ACCOUNTS', ...newMonthList.map((month) => month.substring(0, 3)), 'YTD']}
          headerPadding={'10px 40px'}
          tableWidth="unset"
        >
          {income ? (
            <>
              <TableRow2Header info={[<h3>Income</h3>, ...newMonthList.map(() => <></>), <></>]} tdWidth="100vw" />
              <CoaRenderer monthList={newMonthList} coa={income} />
              <CoaRendererTotal monthList={newMonthList} coa={income} list={list} title="Total Income" />
            </>
          ) : (
            <TableRow2Header info={[<h3>Income (Chart of Account doesn't exist!)</h3>, ...newMonthList.map(() => <></>), <></>]} />
          )}
          {expense ? (
            <>
              <TableRow2Header info={[<h3>Expense</h3>, ...newMonthList.map(() => <></>), <></>]} tdWidth="100vw" />
              <CoaRenderer monthList={newMonthList} coa={expense} />
              <CoaRendererTotal monthList={newMonthList} coa={expense} list={list} title="Total Expense" />
            </>
          ) : (
            <TableRow2Header info={[<h3>Expense (Chart of Account doesn't exist!)</h3>, ...newMonthList.map(() => <></>), <></>]} />
          )}
          <tr className="border-t border-green-800 print-row-header">
            <td className="pl-8" colSpan={newMonthList.length + 1}>
              <h3>
                <b>Net Income</b>
              </h3>
            </td>
            <td className="text-end print-right">
              <h3 className="text-end print-right" style={{ paddingRight: '1.5rem', fontWeight: 'bold' }}>
                {ParseToPHP(list.net_income)}
              </h3>
            </td>
          </tr>
        </Table2>
      }
    </div>
  );
};

type CoaRendererType = {
  list?: ReportByMonth;
  coa: ReportByMonth_ChartOfAccount;
  monthList: Month[];
};

const CoaRenderer = ({ coa, monthList }: CoaRendererType) => (
  <>
    {coa.particular_tags.map((tag, i) => {
      const tagName = tag.name !== 'None' ? <small>-{tag.name}</small> : undefined;
      return tag.particulars.map((part) => (
        <tr key={part.id}>
          <td className="pl-10">{part.name}</td>
          {monthList.map((month) => {
            const partForMonth = part.months_filter.find((part) => part.month === month);
            if (partForMonth) {
              return (
                <td key={`${part.id}-${month}`} className="text-end print-right mx-10">
                  {ParseToPHP(parseFloat(partForMonth.amount))}
                </td>
              );
            }
            return (
              <td key={`${part.id}-${month}`} className="text-end print-right mx-10">
                -
              </td>
            );
          })}
          <td className="text-end print-right" style={{ paddingRight: '1.5rem' }}>
            {ParseToPHP(parseFloat(part.year_to_date))}
          </td>
        </tr>
      ));
    })}
  </>
);

const CoaRendererTotal = ({ list, coa, monthList, title }: CoaRendererType & { title: string }) => (
  <>
    <tr>
      <>
        <td className="pl-8">
          <h3>
            <b>{title}</b>
          </h3>
        </td>
        {monthList.map((month) => {
          const monthTotal = coa.particular_tags
            .map((tag) => {
              const TAGS = tag.particulars.map((part) => {
                const MONTH = part.months_filter.filter((partFilter) => partFilter.month === month);
                return MONTH;
              });
              return TAGS.flat();
            })
            .flat();
          return (
            <td key={month} className="text-end print-right">
              <h3 className="text-end print-right" style={{ fontWeight: 'bold' }}>
                {ParseToPHP(monthTotal.reduce((prev, month) => prev + parseFloat(month.amount), 0))}
              </h3>
            </td>
          );
        })}
      </>
      <td>
        <h3 className="text-end print-right" style={{ paddingRight: '1.5rem', fontWeight: 'bold' }}>
          {coa.chart_of_account === 'Receipts' ? ParseToPHP(list?.total_income ?? 0) : ParseToPHP(list?.total_expense ?? 0)}
        </h3>
      </td>
    </tr>
  </>
);

export default Reports_ByMonth;
