import axiosInstance from '../utils/axiosInstance';

type GetReportType<T> = T extends 'month' ? ReportByMonth : T extends 'list' ? ReportByList : T extends 'year' ? ReportByYear : never;

type ReportType = 'list' | 'month' | 'year';

export const GetReportApi = async <T extends ReportType>(dateFrom: string, dateTo: string, type: T) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/reports/${type}?${getPK ? `filter=${getPK}` : ''}&start=${dateFrom}&end=${dateTo}`)
    .then((res) => res.data as GetReportType<T>)
    .catch((err) => null);
};
