import { BaseSyntheticEvent, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance, { GetTokens, isTokenValid } from '../../utils/axiosInstance';
import { GetMe, GetMyAccInfo, GetUserList } from '../../utils/GetApi';
import { BaseListView, Me, UserListType } from '../../utils/Interface';
import Form, { FormInput } from '../Form/Form';
import { Responsive } from '../Interface/Pages';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess, SwalUnderCons } from '../Swal/Swal';
import style from './sidebarProf.module.css';

interface Sidebar extends Responsive {
  hidden?: Boolean | undefined;
}

const SidebarProf = ({ view, expander, hidden }: Sidebar) => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const [expand, setExpand] = useState(true);
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [rerender, setRerender] = useState(true);
  const [myInfo, setMyInfo] = useState<Me | null>(null);
  const [disabledButton, setDisabledButton] = useState(true);

  const newView = view ? style.hide : style.show;
  const newExpand = expand ? style.mainExpand : style.mainCollapse;

  const OnExpand = () => {
    setExpand((prev) => !prev);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    expander && expander();
  };

  const [isChurchEnable, setIsChurchEnable] = useState(false);

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setIsChurchEnable(true);
      }
      setMyAccInfo(tokens);
    }
  };

  useEffect(() => {
    SetMe();
  }, []);

  useEffect(() => {
    GetMyAccInfo().then((res) => {
      setMyInfo(res);
    });
  }, [rerender]);

  const [inputShow, setInputShow] = useState(false);

  const ShowEdit = () => {
    setInputShow(true);
  };

  const SubmitButton = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    SwalLoading();
    axiosInstance
      .patch(`/api/user-profile/update/${myAccInfo?.church_account}`, {
        username: username !== '' ? username : myAccInfo?.username,
        email: email !== '' ? email : myAccInfo?.email,
      })
      .then(() => {
        setInputShow(false);
        setRerender((prev) => !prev);
        SwalSuccess('Changed Successful!');
      })
      .catch(() => {
        SwalFailedCustom({ title: 'Oops!', text: 'Something Went Wrong!' });
      });
  };

  const CancelButton = () => {
    setInputShow(false);
    setEmail('');
    setUsername('');
  };
  return !hidden ? (
    <div className={`${style.container} ${newView}`}>
      <div className={`${style.main} ${newExpand}`}>
        <div className={style.header}>
          <div className={style.imgContainer}>
            <img src="./profileIcon.png" width="200px" height="50px" />
          </div>
          <div className={style.home}>
            <Link to={'/home'}>
              <i className="fa-solid fa-house"></i>
            </Link>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', color: 'white', justifyContent: 'center', fontSize: '24px' }}>
            <div>
              <span style={{ padding: '0.5em' }}>{myInfo?.username}</span>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                ShowEdit();
              }}
            >
              <i className="fa-solid fa-pencil"></i>
            </div>
          </div>
        </div>
        {inputShow ? (
          <Form
            id="changeProfile"
            handleSubmit={(e) => {
              SubmitButton(e);
            }}
          >
            <div className={style.infoDiv}>
              <span style={{ padding: '0.5em' }}>
                UserName:{' '}
                <input
                  type="text"
                  value={username.trim()}
                  onChange={({ target }: BaseSyntheticEvent) => {
                    setUsername(target.value);
                  }}
                />
              </span>
              <span style={{ padding: '0.5em' }}>
                Email:{' '}
                <input
                  type="email"
                  value={email.trim()}
                  onChange={({ target }: BaseSyntheticEvent) => {
                    setEmail(target.value);
                  }}
                />
              </span>
            </div>
            <div className={style.buttonDiv}>
              <button
                type="submit"
                disabled={!username.trim() && !email.trim() ? disabledButton : false}
                style={disabledButton ? { background: 'gray' } : { background: '#055437' }}
              >
                <strong>Submit</strong>
              </button>
              <button
                type="button"
                style={{ background: 'black' }}
                onClick={() => {
                  CancelButton();
                }}
              >
                <strong>Cancel</strong>
              </button>
            </div>
          </Form>
        ) : (
          <div className={style.infoDiv}>
            <span style={{ padding: '0.5em' }}>UserName: {myInfo?.username}</span>
            <span style={{ padding: '0.5em' }}>Email: {myInfo?.email}</span>
          </div>
        )}
        <div></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SidebarProf;
