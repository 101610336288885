import React, { useEffect, useState } from 'react';
import Container, { Content } from '../../components/Container/Container';
import { TableLoader2 } from '../../components/Loader/Spinner';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { ParseToPHP } from '../../utils/Formatter';
import { CashReceiptType, CashReportsType, ProcessedCashReceiptType } from '../../utils/Interface';

type PrintCashReportsType = {
  cashReports: CashReportsType | null;
  id: string;
  processedCashReceipt: CashReceiptType[] | undefined;
  dateFrom: string;
  dateTo: string;
};

const PrintCashReports = ({ cashReports, id, processedCashReceipt, dateFrom, dateTo }: PrintCashReportsType) => {
  const church = processedCashReceipt?.find((item) => item);

  const TotalAmount = () => {
    const sum = cashReports?.results.reduce(
      (accumulator, reports) =>
        accumulator + reports.particulars.reduce((total, particular) => total + particular.debit + particular.credit, 0),
      0
    );

    if (sum === undefined) return;

    const total = sum / 2;

    return total;
  };

  const date = new Date();

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: '-9999em',
          width: '1000px',
        }}
      >
        <pre id={id} style={{ width: '800px' }}>
          <Container flex>
            <Content grid>
              <h4 style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '25px' }}>{church?.church.name}</h4>
              <h4 style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '20px' }}>{church?.church.location}</h4>
            </Content>
          </Container>
          <Table3 success width="850px" header={['', '', '', '', '']}>
            <TableRow3
              info={[
                <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Source : Acknowledgement Receipt</h4>,
                <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>
                  Date Printed :{' '}
                  {date.toLocaleString('en-US', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                    hour12: true,
                  })}
                </h4>,
              ]}
            />
            <TableRow3
              info={[
                <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>
                  From {dateFrom === '' ? '---' : dateFrom}
                  To {dateTo === '' ? '---' : dateTo}
                </h4>,
                <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}></h4>,
              ]}
            />
          </Table3>
          <Table3
            success
            width="850px"
            header={[
              <div className="text-start"></div>,
              <div className="text-center">Acc.Code</div>,
              <div className="text-center">Acc.Title</div>,
              <div className="text-centert">Debit</div>,
              <div className="text-center">Credit</div>,
            ]}
          >
            {cashReports?.results.map((item, i) => (
              <React.Fragment key={i}>
                <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>{item.date}</h4>]} />
                <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'start', fontWeight: 'bolder' }}>AR NO:{item.ar_no}</h4>]} />
                <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{item.payment_for}</h4>]} />
                {item.particulars.map((part, p) => (
                  <TableRow3
                    key={p}
                    info={[
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                      <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{part.code}</h4>,
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{part.name}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.debit)}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(part.credit)}</h4>,
                    ]}
                  />
                ))}
              </React.Fragment>
            ))}
            <tr style={{ backgroundColor: '#D9D9D9' }}>
              <td colSpan={2} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
                Total
              </td>
              <td colSpan={1}></td>
              <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(TotalAmount() ?? 0)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(TotalAmount() ?? 0)}</td>
            </tr>
          </Table3>
          <br />
          <Table4 success width="850px" header={['', '', 'Account Summaries', '', '']}>
            {cashReports?.account_summaries.map((acc, i) => {
              if (acc.type === 'Balance Sheet') {
                return (
                  <TableRow3
                    key={i}
                    info={[
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                      <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{acc.code}</h4>,
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{acc.name}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(acc.total_amount)}</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}></h4>,
                    ]}
                  />
                );
              }
              if (acc.type === 'Income & Expense') {
                return (
                  <TableRow3
                    key={i}
                    info={[
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                      <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{acc.code}</h4>,
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{acc.name}</h4>,
                      <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(acc.total_amount)}</h4>,
                    ]}
                  />
                );
              }
            })}
            <tr></tr>
            <TableRow3 colspan={5} info={[<h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>**Nothing Follows**</h4>]} />
          </Table4>
        </pre>
      </div>
    </>
  );
};

export default PrintCashReports;
