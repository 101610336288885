import Container, { Content } from '../../components/Container/Container';
import VoidSticker from '../../components/Modal/VoidSticker';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReceiptType, Me, ProcessedCashReceiptType } from '../../utils/Interface';

type CashReceiptsPrintType = {
  id: string;
  selectedCashReceipt: CashReceiptType | null;
  myAccInfo: Me | null;
};

const CashReceiptsPrint = ({ id, selectedCashReceipt, myAccInfo }: CashReceiptsPrintType) => (
  <div
    style={{
      position: 'absolute',
      left: '-9999em',
      width: '1000px',
    }}
  >
    <pre id={id} style={{ width: '800px' }}>
      <VoidSticker isVoid={selectedCashReceipt?.void} />
      <Container flex>
        <Content grid>
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '25px' }}>{selectedCashReceipt?.church.name}</h4>
          <h4 style={{ textAlign: 'start', fontWeight: 'bold', fontSize: '20px' }}>{selectedCashReceipt?.church.location}</h4>
        </Content>
      </Container>
      {selectedCashReceipt?.void ? (
        <div style={{ display: 'grid', justifyContent: 'start', paddingLeft: '1em' }}>
          <div style={{ textAlign: 'start' }}>
            <span style={{ color: 'red' }}>This transaction was voided!</span>
          </div>
          <div>
            <span style={{ color: '#055437' }}>
              <strong>Purpose of void:</strong>
              {selectedCashReceipt.purpose_of_void}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Table3 width="800px" header={[]}>
        <TableRow3
          info={[
            <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Received From:</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{selectedCashReceipt?.received_from}</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>Date:</h4>,
            <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{FormatDate(selectedCashReceipt?.date_time ?? '')}</h4>,
          ]}
        />
        <TableRow3
          info={[
            <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Address:</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{selectedCashReceipt?.address}</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>Amount in Peso:</h4>,
            <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</h4>,
          ]}
        />
      </Table3>
      <Table4 width="800px" header={['In Payment of:', 'Amount', 'Form of Payment', 'Total Amount']}>
        <TableRow3
          info={[
            <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{selectedCashReceipt?.payment_for}</h4>,
            <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</h4>,
            <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{selectedCashReceipt?.form_of_payment}</h4>,
            <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</h4>,
          ]}
        />
        <TableRow4
          info={[
            <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Total Amount</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{ParseToPHP(parseFloat(selectedCashReceipt?.amount ?? ''))}</h4>,
          ]}
        />
        <TableRow3
          info={[
            <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>Received by:</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>Acknowledgement Receipt</h4>,
          ]}
        />
        <TableRow3
          info={[
            <h4 style={{ textAlign: 'start', fontWeight: 'bold' }}>{myAccInfo?.username}</h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}></h4>,
            <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>No.{selectedCashReceipt?.ar_no}</h4>,
          ]}
        />
      </Table4>
    </pre>
  </div>
);

export default CashReceiptsPrint;
