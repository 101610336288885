// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".import_container__FL7ym {\n  margin-left: auto;\n  margin-right: 20px;\n  text-align: center;\n  color: #055437;\n  width: 100px;\n}\n\n.import_content__LLuxZ {\n  display: flex;\n  cursor: pointer;\n}\n\n.import_title__w61YU {\n  padding-top: 0.1em;\n  padding-left: 0.5em;\n  text-decoration: none;\n  font-weight: 600;\n  display: flex;\n  width: 100%;\n  font-size: 1.2em;\n  font-family: 'IBM Plex Sans', sans-serif;\n}\n\n.import_coaTitleContainer__Mgv80 {\n  display: flex;\n  margin-top: 10px;\n  justify-content: space-between;\n}\n\n.import_coaTitle__ZNt7t {\n  font-weight: bolder;\n  text-decoration: none;\n  display: flex;\n  font-size: 1.8em;\n  font-family: 'IBM Plex Sans', sans-serif;\n  color: #055437;\n}\n", "",{"version":3,"sources":["webpack://./src/components/styles/import.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,gBAAgB;EAChB,wCAAwC;EACxC,cAAc;AAChB","sourcesContent":[".container {\n  margin-left: auto;\n  margin-right: 20px;\n  text-align: center;\n  color: #055437;\n  width: 100px;\n}\n\n.content {\n  display: flex;\n  cursor: pointer;\n}\n\n.title {\n  padding-top: 0.1em;\n  padding-left: 0.5em;\n  text-decoration: none;\n  font-weight: 600;\n  display: flex;\n  width: 100%;\n  font-size: 1.2em;\n  font-family: 'IBM Plex Sans', sans-serif;\n}\n\n.coaTitleContainer {\n  display: flex;\n  margin-top: 10px;\n  justify-content: space-between;\n}\n\n.coaTitle {\n  font-weight: bolder;\n  text-decoration: none;\n  display: flex;\n  font-size: 1.8em;\n  font-family: 'IBM Plex Sans', sans-serif;\n  color: #055437;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "import_container__FL7ym",
	"content": "import_content__LLuxZ",
	"title": "import_title__w61YU",
	"coaTitleContainer": "import_coaTitleContainer__Mgv80",
	"coaTitle": "import_coaTitle__ZNt7t"
};
export default ___CSS_LOADER_EXPORT___;
