import React, { useEffect, useState } from 'react';
import { FormatDate, ParseToPHP } from '../../utils/Formatter';
import { CashReportsType, JournalVoucherListType, Me, VoucherItemListType, VoucherItemType, VoucherType } from '../../utils/Interface';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { TableLoader2 } from '../../components/Loader/Spinner';
import VoidSticker from '../../components/Modal/VoidSticker';

type VoucherViewType = {
  selectedVoucher: JournalVoucherListType | null;
  processedVoucherItem: JournalVoucherListType | null;
  id: string;
  myAccInfo: Me | null;
  isLoading: boolean;
};

const JournalVoucherRePrint = ({ selectedVoucher, processedVoucherItem, id, myAccInfo, isLoading }: VoucherViewType) => {
  const writtenNumber = require('written-number');
  const debit = processedVoucherItem?.journal_voucher_items.map((item) => item.debit);
  const credit = processedVoucherItem?.journal_voucher_items.map((item) => item.credit);
  const totaldebit = debit?.reduce((total, currentval) => (total = total + currentval), 0);
  const totalcredit = credit?.reduce((total, currentval) => (total = total + currentval), 0);

  return (
    <>
      <pre id={id}>
        <VoidSticker isVoid={selectedVoucher?.void} />
        {selectedVoucher?.void ? (
          <div style={{ display: 'grid', justifyContent: 'start' }}>
            <div style={{ textAlign: 'start' }}>
              <span style={{ color: 'red' }}>This transaction was voided!</span>
            </div>
            <div>
              <span style={{ color: '#055437' }}>
                <strong>Purpose of void:</strong>
                {selectedVoucher.purpose_of_void}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ display: 'flex', textAlign: 'start' }}>
          <div style={{ marginRight: 'auto', color: '#055437' }}>
            <p>Date: {FormatDate(selectedVoucher?.date ?? 'No Date')}</p>
            <p>Description: {selectedVoucher?.description}</p>
            <p>Amount in Words: {writtenNumber(selectedVoucher?.amount ?? '')} peso only</p>
          </div>
          <div style={{ color: '#055437' }}>
            <p>JV #: {selectedVoucher?.jv_no}</p>
            <p>Amount in Peso: {ParseToPHP(parseFloat(selectedVoucher?.amount ?? ''))}</p>
          </div>
        </div>
        <div>
          <Table4 header={['Code', 'Account Title', 'Debit', 'Credit']} success>
            {isLoading ? (
              processedVoucherItem?.journal_voucher_items.map((item, i) => (
                <TableRow4
                  key={i}
                  info={[
                    <h4 className="text-center font-normal">{item.code}</h4>,
                    <h4 className="text-center font-normal">{item.account_title}</h4>,
                    <h4 className="text-end font-normal">{ParseToPHP(item.debit)}</h4>,
                    <h4 className="text-end font-normal">{ParseToPHP(item.credit)}</h4>,
                  ]}
                />
              ))
            ) : (
              <tr>
                <TableLoader2 colSpan={4} type="boxbox" />
              </tr>
            )}
            <TableRow4
              info={[
                <h4 className="text-center font-bold">Total:</h4>,
                <h4 className="text-center font-normal"></h4>,
                <h4 className="text-end font-bold">{isLoading ? ParseToPHP(totaldebit ? totaldebit : 0) : ParseToPHP(0)}</h4>,
                <h4 className="text-end font-bold">{isLoading ? ParseToPHP(totalcredit ? totalcredit : 0) : ParseToPHP(0)}</h4>,
              ]}
            />
          </Table4>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
          <p>Prepared By:</p>
          <p>Checked By:</p>
          <p>Approved By:</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
          <p>{myAccInfo?.username}</p>
          <p>---------------</p>
          <p>---------------</p>
        </div>
      </pre>
    </>
  );
};

export default JournalVoucherRePrint;
