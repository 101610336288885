import { BaseSyntheticEvent, MouseEvent, useEffect, useState } from 'react';
import CardContainer, { Card, CardButton } from '../components/Card/Card';
import Modal from '../components/Modal/Modal';
import Form, { FormButton, FormInput } from '../components/Form/Form';
import Container, { Content } from '../components/Container/Container';
import axiosInstance, { GetTokens, isAdmin, isTokenValid } from '../utils/axiosInstance';
import Swal from 'sweetalert2';
import { AddAccountForChurch, PostAccountForChurch, PostChurch } from '../utils/PostApi';
import { ToggleView } from '../components/ToggleView/ToggleView';
import { BaseListView, Me, Parish } from '../utils/Interface';
import { GetGenericList } from '../utils/GetApi';
import { SwalFailed, SwalLoading, SwalNotAuthorized, SwalSuccess } from '../components/Swal/Swal';
import { Navigate, useNavigate } from 'react-router-dom';
import { Loader } from '../components/Loader/Spinner';

const Churches = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [modals, setModals] = useState({
    addChurch: false,
    addNewModal: false,
  });

  interface ParishType {
    id: string;
    name: string;
    location: string;
    logo: string | null;
  }

  const [rerender, setRerender] = useState(true);

  const ModalOpenHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: false }));
  const navigate = useNavigate();

  const [churchName, setChurchName] = useState('');
  const [churchAddress, setChurchAddress] = useState('');
  const [userName, setUserName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const [parishList, setParishList] = useState<BaseListView<Parish> | null>(null);
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [parish, setParish] = useState<Parish[] | null>(null);
  const [selectedParish, setSelectedParish] = useState<Parish | null>(null);
  const [yourChurch, setYourChurch] = useState(false);
  const [asd, setAsd] = useState('');

  // const me = GetTokens()
  // console.log("me",me)

  const SetMe = async () => {
    const token = GetTokens();
    if (!isTokenValid(token)) return;
    if (!isAdmin(token)) return;
    setMyAccInfo(token);
  };

  useEffect(() => {
    SetMe();
  }, []);

  const SetSelectedParish = (index: number) => {
    if (!parish) return;
    const parishIndex = parish[index];
    setSelectedParish(parishIndex);
    setModals((prev) => ({ ...prev, addNewModal: true }));
  };

  const AddChurchSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (churchName === '') {
      return SwalFailed('Parish Name is Empty');
    }
    if (churchAddress === '') {
      return SwalFailed('Parish Address is Empty');
    }
    if (userName === '') {
      return SwalFailed('Username is Empty');
    }
    if (emailAddress === '') {
      return SwalFailed('Email Address is Empty');
    }
    if (churchName === '' && churchAddress === '' && userName === '' && emailAddress === '') {
      return SwalFailed('Text Field Empty');
    }
    AddChurchHandler();
  };

  const AddChurchHandler = async () => {
    if (churchName === '') return;

    SwalLoading();

    const church = new FormData();
    church.append('name', churchName);
    church.append('location', churchAddress);
    if (file) church.append('logo', file);

    const data = await PostChurch({
      data: church,
    });
    if (!data) return;
    const user = {
      username: userName,
      password: 'password',
      email: emailAddress,
    };
    AddAccountForChurch({
      user: user,
      church: data.id,
    })
      .then((res) => {
        SwalSuccess('Parish Added!');
        setChurchName('');
        setChurchAddress('');
        setUserName('');
        setEmailAddress('');
        setRerender((prev) => !prev);
        setModals((prev) => ({ ...prev, addChurch: false }));
      })
      .catch((err) => {});
  };

  const GetChurchList = async () => {
    await axiosInstance
      .get('api/church/')
      .then((res) => {
        setParishList(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          SwalNotAuthorized('Only superadmin can access this feature!');
          navigate('/home', { preventScrollReset: true });
          return;
        }
        SwalFailed('OOPS! something went wrong.');
        setParish([]);
      });
  };

  const handleSetSessionStorage = () => {
    if (selectedParish === null) return;
    sessionStorage.setItem('ProxyKey', selectedParish?.id);
    sessionStorage.setItem('ChurchName', selectedParish.name);
    ModalCloseHandler('addNewModal');
    navigate('/home');
  };

  const RemoveSetSessionStorage = () => {
    sessionStorage.removeItem('ProxyKey');
    sessionStorage.removeItem('ChurchName');
    navigate('/home');
  };

  const AddChurch = () => {
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === myAccInfo?.church || pKey === '' || !pKey) {
      return setModals((prev) => ({ ...prev, addChurch: true }));
    }
    if (pKey !== '') return SwalFailed('This is not your parish!');
  };

  const ParishKey = sessionStorage.getItem('ProxyKey');

  const [file, setFile] = useState<File>();

  useEffect(() => {
    GetChurchList();
  }, [rerender]);

  useEffect(() => {
    if (!parishList) return;
    setParish(parishList.results);
  }, [parishList]);

  if (!isAdmin(myAccInfo)) return <></>;

  return (
    <>
      <CardContainer justify="start">
        <CardButton
          title={'ADD Parish'}
          content={<i style={{ fontSize: '50px' }} className="fa-solid fa-church"></i>}
          onClick={() => {
            AddChurch();
          }}
        />
        {parish ? (
          parish.map((item, i) => (
            <div key={i}>
              {
                <CardButton
                  key={item.id}
                  border={
                    !ParishKey
                      ? myAccInfo.church === item.id
                        ? '2px solid red'
                        : undefined
                      : ParishKey === item.id
                      ? '2px solid red'
                      : undefined
                  }
                  title={item.name}
                  content={
                    item.logo ? (
                      <img src={item.logo} width={50} height={50} alt="" />
                    ) : (
                      <i className="fa-solid fa-church" style={{ fontSize: '50px' }}></i>
                    )
                  }
                  onClick={() => {
                    if (myAccInfo.church === item.id) {
                      RemoveSetSessionStorage();
                      return;
                    }
                    SetSelectedParish(i);
                  }}
                />
              }
            </div>
          ))
        ) : (
          <div style={{ justifyContent: 'center', margin: 'auto' }}>
            <Loader type={'ring'} />
            <h2 className="text-2xl">LOADING PARISHES...</h2>
          </div>
        )}
      </CardContainer>

      <Modal
        show={modals.addChurch}
        onClose={() => {
          ModalCloseHandler('addChurch');
        }}
        name={'addChurch'}
        title={'CHURCH INFORMATION'}
        width="700px"
        success
      >
        <Form id={'addChurchForm'} handleSubmit={AddChurchSubmit}>
          <Container>
            <Content flex>
              <FormInput
                margin="0 1em"
                width="280px"
                title={'Parish Name:'}
                name={'churchName'}
                value={churchName}
                onChange={(e: BaseSyntheticEvent) => {
                  setChurchName(e.target.value);
                }}
              />
              <FormInput
                margin="0 1em"
                width="280px"
                title={'Parish Address:'}
                name={'churchAddress'}
                value={churchAddress}
                onChange={(e: BaseSyntheticEvent) => {
                  setChurchAddress(e.target.value);
                }}
              />
            </Content>

            <Content flex>
              <FormInput
                margin="0 1em"
                width="280px"
                title={'User Name:'}
                name={'userName'}
                value={userName}
                onChange={(e: BaseSyntheticEvent) => {
                  setUserName(e.target.value);
                }}
              />
              <FormInput
                margin="0 1em"
                width="280px"
                type="email"
                title={'Email Address:'}
                name={'emailAddress'}
                value={emailAddress}
                onChange={(e: BaseSyntheticEvent) => {
                  setEmailAddress(e.target.value);
                }}
              />
              <FormInput
                margin="0 1em"
                width="280px"
                title={'Logo:'}
                type={'file'}
                name={'logo'}
                onChange={(e: BaseSyntheticEvent) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Content>

            <Content flex justify="space-around" padding="1em 0 0 0">
              <div style={{ width: '200px' }}>
                <FormButton title={'Submit'} success name={'addChurchForm'} onClick={() => {}} type={'submit'} />
              </div>
              <div style={{ width: '200px' }}>
                <FormButton
                  title={'Cancel'}
                  secondary
                  name={'addChurch'}
                  onClick={() => {
                    ModalCloseHandler('addChurch');
                  }}
                  type={'button'}
                />
              </div>
            </Content>
          </Container>
        </Form>
      </Modal>

      <Modal
        show={modals.addNewModal}
        onClose={() => {
          ModalCloseHandler('addNewModal');
        }}
        name={'addChurch'}
        title={'VIEWING PARISH'}
        width="400px"
        success
      >
        <div>
          <p style={{ color: '#298D69', fontWeight: 'bolder' }}>Are you sure you want to proceed?</p>
        </div>
        <Content flex justify="space-around" padding="1em 0 0 0">
          <div>
            <FormButton
              success
              title={'Confirm'}
              name={''}
              onClick={() => {
                handleSetSessionStorage();
              }}
              type={'submit'}
            />
          </div>
          <div>
            <FormButton
              success
              title={'Cancel'}
              secondary
              name={'addNewModal'}
              onClick={() => {
                ModalCloseHandler('addNewModal');
              }}
              type={'button'}
            />
          </div>
        </Content>
      </Modal>
    </>
  );
};

export default Churches;
