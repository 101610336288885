import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import CardContainer, { CardButton } from '../components/Card/Card';
import { GetChurch, GetMyAccInfo, GetUserList } from '../utils/GetApi';
import { BaseListView, Me, Parish, UserListType } from '../utils/Interface';
import style from '../components/styles/userProfile.module.css';
import Container, { Content } from '../components/Container/Container';
import GoToPage from '../components/Pagination/GoToPage';
import Pagination from '../components/Pagination/paginate';
import { TableLoader, TableLoader3 } from '../components/Loader/Spinner';
import axiosInstance, { GetTokens, isTokenValid } from '../utils/axiosInstance';
import Modal from '../components/Modal/Modal';
import Form, { FormButton, FormInput } from '../components/Form/Form';
import Select, { Option } from '../components/Select/Select';
import { AddAccountForChurch } from '../utils/PostApi';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess } from '../components/Swal/Swal';

const UserProfile = () => {
  const [userList, setUserList] = useState<BaseListView<UserListType> | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiLimit, setApiLimit] = useState(4);
  const [jumpToPage, setJumpToPage] = useState(1);
  const [nextPrevLoad, setNextPrevLoad] = useState(true);
  const [disableNextPrev, setDisableNextPrev] = useState(true);
  const [userName, setUserName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [selectedParish, setSelectedParish] = useState<Parish>();
  const [church, setChurch] = useState<BaseListView<Parish> | null>(null);

  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [inputShow, setInputShow] = useState(false);
  const [myInfo, setMyInfo] = useState<Me | null>(null);
  const [rerender, setRerender] = useState(true);
  const [rerender2, setRerender2] = useState(true);
  // eslint-disable-next-line prefer-const
  let [disabledButton, setDisabledButton] = useState(true);

  const ChangeChurchHandler = ({ target: { value } }: BaseSyntheticEvent) => {
    const SelectedParish = church?.results.find((church) => church.id === value);
    if (SelectedParish === undefined) return;
    setSelectedParish(SelectedParish);
  };

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setIsAdmin(true);
      }
      setMyAccInfo(tokens);
    }
  };
  useEffect(() => {
    SetMe();
  }, []);

  useEffect(() => {
    if (!isAdmin) return;
    setDisableNextPrev(true);
    GetUserList(setUserList, apiLimit, currentPage).then((res) => {
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });
  }, [apiLimit, currentPage, isAdmin, rerender]);

  useEffect(() => {
    GetMyAccInfo().then((res) => {
      setMyInfo(res);
    });
  }, [rerender2]);

  const decrement = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage - 1);
  };

  const increment = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage + 1);
  };

  const JumpToButton = () => {
    if (!userList) return;
    setNextPrevLoad(false);
    setDisableNextPrev(true);
    const lastPage = Math.round(userList?.count / apiLimit);
    if (jumpToPage > lastPage) {
      return GetUserList(setUserList, apiLimit, lastPage).then((res) => {
        setCurrentPage(lastPage);
        setNextPrevLoad(true);
        setDisableNextPrev(false);
      });
    }
    if (jumpToPage < 1) {
      return GetUserList(setUserList, apiLimit, currentPage).then((res) => {
        setCurrentPage(1);
        setNextPrevLoad(true);
        setDisableNextPrev(false);
      });
    }
    GetUserList(setUserList, apiLimit, currentPage).then((res) => {
      setCurrentPage(jumpToPage);
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });
  };

  const AddUserButton = () => {
    setShowAdd(true);
    GetChurch(setChurch).then((res) => {
      setSelectDisabled(false);
    });
  };

  const CloseButton = () => {
    setShowAdd(false);
    setEmailAddress('');
    setUserName('');
    setSelectedParish(undefined);
  };

  const CancelButton = () => {
    setInputShow(false);
    setEmailAddress('');
    setUserName('');
  };

  const SubmitAddUser = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (!selectedParish || selectedParish === undefined) {
      SwalFailed('Please select a Parish!');
      return;
    }
    if (!selectedParish) return;
    const user = {
      username: userName,
      password: 'password',
      email: emailAddress,
    };
    SwalLoading();
    AddAccountForChurch({
      user: user,
      church: selectedParish?.id,
    })
      .then((res) => {
        setShowAdd(false);
        SwalSuccess('Changed Succesfully!');
        setEmailAddress('');
        setUserName('');
        setSelectedParish(undefined);
        setRerender((prev) => !prev);
      })
      .catch((err) => {
        SwalFailedCustom({ title: 'Oops!', text: 'Something Went Wrong!' });
      });
  };

  const SubmitButton = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    SwalLoading();
    axiosInstance
      .patch(`/api/user-profile/update/${myAccInfo?.church_account}`, {
        username: userName !== '' ? userName : myAccInfo?.username,
        email: emailAddress !== '' ? emailAddress : myAccInfo?.email,
      })
      .then((res) => {
        setInputShow(false);
        setUserName('');
        setEmailAddress('');
        setRerender2((prev) => !prev);
        SwalSuccess('Changed Successful!');
      })
      .catch((err) => {
        SwalFailedCustom({ title: 'Oops!', text: 'Something Went Wrong!' });
      });
  };

  return (
    <>
      {isAdmin ? (
        <>
          <div className={style.addContainer}>
            <div
              className={style.content}
              onClick={() => {
                AddUserButton();
              }}
            >
              <span style={{ fontSize: '20px' }}>
                <i className="fa-solid fa-plus"></i>
              </span>
              <span className={style.title}>Add User Profile</span>
            </div>
          </div>
          <CardContainer>
            {nextPrevLoad ? (
              userList?.results ? (
                userList.results.length > 0 ? (
                  userList?.results.map((item, i) => (
                    <React.Fragment key={i}>
                      <div className={style.container}>
                        <div className={style.contentOne}>
                          <span style={{ padding: '1em' }}>
                            <strong>Name:</strong> {item.church_name}
                          </span>
                          <span style={{ padding: '1em' }}>
                            <strong>UserName:</strong> {item.username}
                          </span>
                        </div>
                        <div className={style.contentTwo}>
                          <span style={{ padding: '1em' }}>
                            <strong>Email:</strong> {item.email}
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div style={{ paddingTop: '1em', textAlign: 'center', width: '100%', color: '#055437' }}>
                    <strong>No Parishes!</strong>
                  </div>
                )
              ) : (
                <TableLoader3 type={'dual_ring'} />
              )
            ) : (
              <TableLoader3 type={'dual_ring'} />
            )}
          </CardContainer>
          <Content flex justify="space-between" padding="0.5em">
            <GoToPage
              disabled={disableNextPrev}
              next={userList?.next}
              prev={userList?.previous}
              jumpTo={JumpToButton}
              currentPage={jumpToPage}
              setCurrentPage={setJumpToPage}
              count={userList?.count}
              limit={apiLimit}
            />
            <Pagination
              limit={apiLimit}
              next={userList?.next}
              prev={userList?.previous}
              currentPage={currentPage}
              increment={increment}
              decrement={decrement}
              count={userList?.count}
              disabled={disableNextPrev}
            />
          </Content>
          <Modal
            show={showAdd}
            onClose={() => {
              CloseButton();
            }}
            name={'addChurch'}
            title={'CHURCH INFORMATION'}
            width="600px"
            success
          >
            <Form
              id={'addChurchForm'}
              handleSubmit={(e) => {
                SubmitAddUser(e);
              }}
            >
              <Container>
                <Content padding="0 1em">
                  <Select
                    width="100%"
                    title={
                      <>
                        <span className="text-red-500">*</span>Parishes:
                      </>
                    }
                    disabled={selectDisabled}
                    name="account"
                    required
                    onChange={ChangeChurchHandler}
                    placeHolder={'Choose Parish'}
                    value={selectedParish?.id}
                  >
                    {church?.results.map((item) => (
                      <Option key={item.id} value={item.id} title={item.name} />
                    ))}
                  </Select>
                </Content>
                <Content flex>
                  <FormInput
                    margin="0 1em"
                    width="238px"
                    required
                    title={
                      <>
                        <span className="text-red-500">*</span>Username:
                      </>
                    }
                    name={'userName'}
                    value={userName.trim()}
                    onChange={(e: BaseSyntheticEvent) => {
                      setUserName(e.target.value);
                    }}
                  />
                  <FormInput
                    margin="0 1em"
                    width="238px"
                    required
                    type="email"
                    title={
                      <>
                        <span className="text-red-500">*</span>Email Address:
                      </>
                    }
                    name={'emailAddress'}
                    value={emailAddress.trim()}
                    onChange={(e: BaseSyntheticEvent) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                </Content>

                <Content flex justify="space-around" padding="1em 0 0 0">
                  <div style={{ width: '200px' }}>
                    <FormButton title={'Submit'} success name={'addChurchForm'} onClick={() => {}} type={'submit'} />
                  </div>
                  <div style={{ width: '200px' }}>
                    <FormButton
                      title={'Cancel'}
                      secondary
                      name={'addChurch'}
                      onClick={() => {
                        CloseButton();
                      }}
                      type={'button'}
                    />
                  </div>
                </Content>
              </Container>
            </Form>
          </Modal>
        </>
      ) : (
        <div className={style.main}>
          <div className={style.userContent}>
            <div className={style.imgContainer}>
              <img src="./profileIcon.png" width="200px" height="50px" />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginLeft: '5.3em' }}>
                <strong>{myInfo?.username}</strong>
              </div>
              <div
                style={{ cursor: 'pointer', marginLeft: '0.5em' }}
                onClick={() => {
                  setInputShow(true);
                }}
              >
                <i className="fa-solid fa-pencil"></i>
              </div>
            </div>
          </div>
          {inputShow ? (
            <div className={style.userContentTwo}>
              <Form
                id="changeProfile"
                handleSubmit={(e) => {
                  SubmitButton(e);
                }}
              >
                <span style={{ padding: '0.5em' }}>
                  UserName:{' '}
                  <input
                    type="text"
                    value={userName.trim()}
                    onChange={({ target }: BaseSyntheticEvent) => {
                      setUserName(target.value);
                    }}
                  />
                </span>
                <span style={{ padding: '0.5em' }}>
                  Email:{' '}
                  <input
                    style={{ marginLeft: '2.3em' }}
                    type="email"
                    value={emailAddress.trim()}
                    onChange={({ target }: BaseSyntheticEvent) => {
                      setEmailAddress(target.value);
                    }}
                  />
                </span>
                <div className={style.buttonDiv}>
                  <button
                    type="submit"
                    disabled={!userName.trim() && !emailAddress.trim() ? disabledButton : (disabledButton = false)}
                    style={disabledButton ? { background: 'gray' } : { background: '#055437' }}
                  >
                    <strong>Submit</strong>
                  </button>
                  <button
                    type="button"
                    style={{ background: 'black' }}
                    onClick={() => {
                      CancelButton();
                    }}
                  >
                    <strong>Cancel</strong>
                  </button>
                </div>
              </Form>
            </div>
          ) : (
            <div className={style.userContentTwo}>
              <div style={{ padding: '0.5em' }}>
                <strong>UserName:</strong> {myInfo?.username}
              </div>
              <div style={{ padding: '0.5em' }}>
                <strong>Email:</strong> {myInfo?.email}
              </div>
              <div style={{ padding: '0.5em' }}>
                <strong>Location:</strong> {myInfo?.church_location}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserProfile;
