import { TableLoader } from '../../components/Loader/Spinner';
import Table, { TableRow, TableEmpty } from '../../components/Table/Table';
import { FormatDate } from '../../utils/Formatter';
import { BaseListView, FileShareType } from '../../utils/Interface';
import SelectedReceiptTable from '../CashReceiptsPages/SelectedReceiptTable';

type UserSideFileSharedType = {
  filesShare: BaseListView<FileShareType> | null;
};

const UserSideFileShared = ({ filesShare }: UserSideFileSharedType) => {
  const handleClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Table header={['File Name', '', 'Date Shared', 'Shared By']} success>
      {filesShare ? (
        filesShare.results.length > 0 ? (
          filesShare.results.map((file, i) => (
            <TableRow
              pointer
              onClick={() => {
                handleClick(file.file);
              }}
              key={i}
              info={[
                <>{`${FormatDate(file.date_shared)}-${file.name}`}</>,
                <></>,
                <>{FormatDate(file.date_shared)}</>,
                <>{file.shared_by}</>,
              ]}
            />
          ))
        ) : (
          <TableEmpty colSpan={4}>No File/s Found!</TableEmpty>
        )
      ) : (
        <TableLoader colSpan={4} type={'dual_ring'} />
      )}
    </Table>
  );
};

export default UserSideFileShared;
