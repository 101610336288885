import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

type GetTokenApiType = {
  username: string;
  password: string;
};

const GetTokenApi = async ({ username, password }: GetTokenApiType) =>
  await fetch(`${BASE_URL}/o/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `grant_type=password&username=${username}&password=${password}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
  })
    .then((res) => {
      if (res.status === 200) return res.json();
    })
    .then((data) => {
      if (!data) return null;
      return data as SessionToken;
    })
    .catch((err) => null);

export const GetMeApi = async (token: string) =>
  await axios
    .get(`${BASE_URL}/api/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data as SessionUser)
    .catch((err) => null);

export default GetTokenApi;
