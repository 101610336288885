import React, { useEffect, useState } from 'react';
import Container, { Content } from '../../components/Container/Container';
import { TableLoader2 } from '../../components/Loader/Spinner';
import Table3, { TableRow3 } from '../../components/Table/Table3';
import Table4, { TableRow4 } from '../../components/Table/Table4';
import { ParseToPHP } from '../../utils/Formatter';
import { CashReceiptType, CashReportsType, ProcessedCashReceiptType, VoucherReportsType } from '../../utils/Interface';

type VoucherReportPrintType = {
  voucherReports: VoucherReportsType | null;
  id: string;
  dateFrom?: string;
  dateTo?: string;
};

const VoucherReportPrint = ({ voucherReports, id, dateFrom, dateTo }: VoucherReportPrintType) => {
  const debitArray = voucherReports?.results.map((entry) => entry.debit);
  const creditArray = voucherReports?.results.map((entry) => entry.credit);

  const debitTotal = debitArray?.reduce((total, amount) => total + amount, 0);
  const creditTotal = creditArray?.reduce((total, amount) => total + amount, 0);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: '-9999em',
          width: '1000px',
        }}
      >
        <pre id={id} style={{ width: '800px' }}>
          <Table4
            success
            width="850px"
            header={[
              <div className="text-center">Code</div>,
              <div className="text-center">Account Summaries</div>,
              <div className="text-end">Debit</div>,
              <div className="text-end">Credit</div>,
            ]}
          >
            {voucherReports?.results ? (
              voucherReports?.results.map((acc, i) => {
                if (acc.debit > 0 || acc.credit > 0) {
                  return (
                    <TableRow3
                      key={i}
                      info={[
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{acc.code}</h4>,
                        <h4 style={{ textAlign: 'start', fontWeight: 'normal' }}>{acc.name}</h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{acc.debit === 0 ? '---' : ParseToPHP(acc.debit)}</h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>{acc.credit === 0 ? '---' : ParseToPHP(acc.credit)}</h4>,
                      ]}
                    />
                  );
                }
              })
            ) : (
              <TableRow3 colspan={4} info={[<h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>No Cash Reports!</h4>]} />
            )}
            <tr style={{ backgroundColor: '#D9D9D9' }}>
              <td colSpan={2} style={{ textAlign: 'start', padding: '.5em 2em', fontWeight: 'bolder' }}>
                Total
              </td>

              <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(debitTotal ?? 0)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bolder', padding: '.5em 2em' }}>{ParseToPHP(creditTotal ?? 0)}</td>
            </tr>
          </Table4>
        </pre>
      </div>
    </>
  );
};

export default VoucherReportPrint;
