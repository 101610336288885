import axiosInstance from '../utils/axiosInstance';

export const GetGeneralLedgerAccountApi = async (id: string, category: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/specials/general-ledger/account/${id}?category=${category}&${getPK ? `filter=${getPK}` : ''}`)
    .then((res) => res.data as GeneralLedgerType)
    .catch((err) => null);
};

export const GetGeneralLedgerAccountTagApi = async (id: string, category: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/specials/general-ledger/account-tag/${id}?category=${category}&${getPK ? `filter=${getPK}` : ''}`)
    .then((res) => res.data as GeneralLedgerType)
    .catch((err) => null);
};

export const GetGeneralLedgerAccountTitleApi = async (id: string, category: string) => {
  const getPK = sessionStorage.getItem('ProxyKey');
  return await axiosInstance
    .get(`/parish/api/specials/general-ledger/account-title/${id}?category=${category}&${getPK ? `filter=${getPK}` : ''}`)
    .then((res) => res.data as GeneralLedgerType)
    .catch((err) => null);
};
