const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const ToDays = (index: number) => DAYS[index];
const ToMonths = (index: number) => MONTHS[index];

export const FormatDate = (date: string) => {
  const bD = new Date(date);
  if (isNaN(bD.getDate())) return null;
  const finalDate = `${bD.getDate()} ${ToMonths(bD.getMonth())} ${bD.getFullYear()}`;
  return finalDate;
};

export const ParseToPHP = (number: number) => Intl.NumberFormat('fil-PH', { style: 'currency', currency: 'PHP' }).format(number);
