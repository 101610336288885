// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Paginate_container__xaGes {\n  display: flex;\n}\n.Paginate_container__xaGes button {\n  border: none;\n  display: flex;\n  transition-duration: 200ms;\n  margin: 0.2em;\n  padding: 0.5em;\n  border-radius: 10px;\n  color: white;\n  background-color: #22835f;\n}\n\n.Paginate_pages__kBL40 {\n  display: flex;\n  overflow: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/Pagination/Paginate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,YAAA;EACA,aAAA;EACA,0BAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,cAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  button {\n    border: none;\n    display: flex;\n    transition-duration: 200ms;\n    margin: 0.2em;\n    padding: 0.5em;\n    border-radius: 10px;\n    color: #ffff;\n    background-color: #22835f;\n  }\n}\n\n.pages {\n  display: flex;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Paginate_container__xaGes",
	"pages": "Paginate_pages__kBL40"
};
export default ___CSS_LOADER_EXPORT___;
