import { useState } from 'react';
import { Loader } from '../../components/Loader/Spinner';
import Table2, { TableRow2Header } from '../../components/Table/Table2';
import { ParseToPHP } from '../../utils/Formatter';

type Reports_ByListType = {
  list: ReportByList;
  isLoading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const Reports_ByList = ({ list, isLoading }: Reports_ByListType) => {
  const income = list.results.find((coa) => coa.chart_of_account === 'Receipts');
  const expense = list.results.find((coa) => coa.chart_of_account === 'Disbursements');

  return (
    <>
      {
        <Table2 isLoading={isLoading} success header={['ACCOUNTS', 'AMOUNT']}>
          {income ? (
            <>
              <TableRow2Header info={[<h3>Income</h3>, <></>]} />
              <CoaRenderer {...income} />
              <TableRow2Header
                info={[
                  <h3>Total Income</h3>,
                  <h3 className="text-end pr-2 print-right" style={{ paddingRight: '1.5rem' }}>
                    {ParseToPHP(list.total_income)}
                  </h3>,
                ]}
              />
            </>
          ) : (
            <TableRow2Header info={[<h3>Income (Chart of Account doesn't exist!)</h3>]} />
          )}
          {expense ? (
            <>
              <TableRow2Header info={[<h3>Expense</h3>, <></>]} />
              <CoaRenderer {...expense} />
              <TableRow2Header
                info={[
                  <h3>Total Expense</h3>,
                  <h3 className="text-end pr-2 print-right" style={{ paddingRight: '1.5rem' }}>
                    {ParseToPHP(list.total_expense)}
                  </h3>,
                ]}
              />
            </>
          ) : (
            <TableRow2Header info={[<h3>Expense (Chart of Account doesn't exist!)</h3>]} />
          )}
          <TableRow2Header
            info={[
              <h3>Net Income</h3>,
              <h3 className="text-end pr-2 print-right" style={{ paddingRight: '1.5rem' }}>
                {ParseToPHP(list.net_income)}
              </h3>,
            ]}
          />
        </Table2>
      }
    </>
  );
};

const CoaRenderer = (coa: ReportByList_ChartOfAccount) => (
  <>
    {coa.particular_tags.map((tag, i) => {
      const tagName = tag.name !== 'None' ? <small>-{tag.name}</small> : undefined;
      return tag.particulars.map((part) => (
        <tr key={part.id}>
          <td className="pl-10">{part.name}</td>
          <td className="text-end pr-10 print-right">{ParseToPHP(parseFloat(part.amount))}</td>
        </tr>
      ));
    })}
  </>
);

export default Reports_ByList;
