import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { ColorType } from '../Interface/Pages';
import { Loader } from '../Loader/Spinner';
import style from './table.module.css';

interface TableType extends ColorType {
  header?: string[];
  children: ReactNode;
  width?: string;
  height?: string;
  m?: string | number;
  p?: string | number;
  isLoading?: boolean;
}

const GetColorType = ({ primary, secondary, success, warning, danger }: ColorType) => {
  if (primary) return style.primary;
  if (secondary) return style.secondary;
  if (success) return style.success;
  if (warning) return style.warning;
  if (danger) return style.danger;
  return style.primary;
};

export const TableRow = ({
  info,
  voidd,
  onClick,
  pointer,
}: {
  info: ReactElement[];
  voidd?: boolean;
  onClick?: MouseEventHandler;
  pointer?: boolean;
}) => (
  <tr className={pointer ? style.rowPointer : style.row} onClick={onClick}>
    {info.map((data, i) => (
      <td key={i} className={voidd ? style.voidTd : style.data}>
        {data}
      </td>
    ))}
  </tr>
);

interface TableAction {
  icon: ReactElement;
  onClick: MouseEventHandler;
  name?: string;
  disabled?: boolean;
}

export const TableAction = ({ icon, onClick, name, disabled }: TableAction) => (
  <button disabled={disabled} className={disabled ? style.disableActionButton : style.actionButton} onClick={onClick}>
    {icon} {name}
  </button>
);

interface TableEmpty {
  children?: ReactNode;
  colSpan?: number;
}

export const TableEmpty = ({ children, colSpan }: TableEmpty) => (
  <tr style={{ justifyContent: 'center' }}>
    <th colSpan={colSpan} style={{ color: '#055437' }}>
      {children}
    </th>
  </tr>
);

const Table = ({ header, success, secondary, primary, warning, danger, width, height, children, m, p, isLoading }: TableType) => (
  <div className={style.container} style={{ maxWidth: width, margin: m, padding: p }}>
    {isLoading && (
      <div className={style.containerLoader}>
        <Loader type={'roller'} />
      </div>
    )}
    <table>
      {header && (
        <thead className={`${style.header} ${GetColorType({ success, secondary, primary, warning, danger })}`}>
          <tr>
            {header.map((head) => (
              <th key={head} className={style.head}>
                {head}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody className={style.body} style={height ? { maxHeight: height } : {}}>
        {children}
      </tbody>
    </table>
  </div>
);

export default Table;
