// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard_container__I50mH {\n  /* display : grid; */\n  /* grid-template-columns: auto 1fr; */\n  position: relative;\n  /* height: 80vh; */\n}\n\n.dashboard_main__fa\\+Oq {\n  margin: 3em 2em 1em 6em;\n}\n\n.dashboard_expand__BWWGl {\n  padding-left: 16em;\n  transition-duration: 200ms;\n}\n.dashboard_collapse__OJu7x {\n  padding-left: 2.2em;\n  transition-duration: 200ms;\n}\n.dashboard_expandNav__tgHWN {\n  display: none;\n  transition-duration: 200ms;\n}\n.dashboard_collapseNav__AHeNy {\n  display: block;\n  transition-duration: 200ms;\n}\n\n.dashboard_none__9lF-N {\n  transition-duration: 200ms;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qCAAqC;EACrC,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,0BAA0B;AAC5B;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".container {\n  /* display : grid; */\n  /* grid-template-columns: auto 1fr; */\n  position: relative;\n  /* height: 80vh; */\n}\n\n.main {\n  margin: 3em 2em 1em 6em;\n}\n\n.expand {\n  padding-left: 16em;\n  transition-duration: 200ms;\n}\n.collapse {\n  padding-left: 2.2em;\n  transition-duration: 200ms;\n}\n.expandNav {\n  display: none;\n  transition-duration: 200ms;\n}\n.collapseNav {\n  display: block;\n  transition-duration: 200ms;\n}\n\n.none {\n  transition-duration: 200ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dashboard_container__I50mH",
	"main": "dashboard_main__fa+Oq",
	"expand": "dashboard_expand__BWWGl",
	"collapse": "dashboard_collapse__OJu7x",
	"expandNav": "dashboard_expandNav__tgHWN",
	"collapseNav": "dashboard_collapseNav__AHeNy",
	"none": "dashboard_none__9lF-N"
};
export default ___CSS_LOADER_EXPORT___;
