// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background_primary__1e\\+TH {\n  background: #0984e3;\n}\n.background_secondary__ZKhxe {\n  background: #636e72;\n}\n.background_success__NYfDL {\n  /* background : #00b894; */\n  background: #055437;\n}\n.background_warning__gt5X- {\n  background: #fdcb6e;\n}\n.background_danger__t\\+9Xs {\n  background: #d63031;\n}\n", "",{"version":3,"sources":["webpack://./src/components/styles/background.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,0BAA0B;EAC1B,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".primary {\n  background: #0984e3;\n}\n.secondary {\n  background: #636e72;\n}\n.success {\n  /* background : #00b894; */\n  background: #055437;\n}\n.warning {\n  background: #fdcb6e;\n}\n.danger {\n  background: #d63031;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "background_primary__1e+TH",
	"secondary": "background_secondary__ZKhxe",
	"success": "background_success__NYfDL",
	"warning": "background_warning__gt5X-",
	"danger": "background_danger__t+9Xs"
};
export default ___CSS_LOADER_EXPORT___;
