import { useState } from 'react';
import { GetTokens, isTokenValid } from '../../utils/axiosInstance';
import { IsTablet } from '../Functions/mediaQuery';
import Navbar from '../Navbar/Navbar';
import ErrorPage from '../Pages/ErrorPage';
import Sidebar from '../Sidebar/Sidebar';
import style from './dashboard.module.css';
import Header from './Header';

interface DashboardType {
  Component: Function;
  plain?: boolean;
  title?: string;
  noTitle?: Boolean;
  noSidebar?: Boolean;
  noBurger?: Boolean;
  noNavbar?: Boolean;
}

const Dashboard = ({ Component, title, noTitle, noSidebar, noNavbar, plain }: DashboardType) => {
  const ViewHandler = IsTablet();

  const [expand, setExpand] = useState(false);

  const Expander = () => {
    setExpand((prev) => !prev);
  };

  const View = ViewHandler ? style.none : expand ? style.collapse : style.expand;
  const navView = ViewHandler ? style.none : expand ? style.collapseNav : style.expandNav;

  const tokens = GetTokens();
  if (!isTokenValid(tokens)) {
    return <ErrorPage code={404} message={'PAGE NOT FOUND'} />;
  }

  return (
    <>
      {!noNavbar && <Navbar view={ViewHandler} media={navView} />}
      <div>
        <div className={style.container}>
          <Sidebar view={ViewHandler} expander={Expander} hidden={noSidebar} />
          <div className={`${plain ? '' : style.main} ${plain ? '' : View}`}>
            {!noTitle && title && <Header title={title} />}
            <Component />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
