import React, { BaseSyntheticEvent, FormEvent, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import Container, { Content } from '../components/Container/Container';
import Form, { FieldContainer, FormButton, FormInput, FormTextArea } from '../components/Form/Form';
import Modal, { ModalButton, ModalFooterButton } from '../components/Modal/Modal';
import { SwalFailed, SwalLoading, SwalSuccess, SwalUnderCons } from '../components/Swal/Swal';
import Table, { TableAction, TableEmpty, TableRow } from '../components/Table/Table';
import Table2, { TableRow2 } from '../components/Table/Table2';
import { FormatDate, ParseToPHP } from '../utils/Formatter';
import {
  GetCashInBankPart,
  GetCurrentBalPetty,
  GetCurrentBalPetty2,
  GetCvNo,
  GetGenericList,
  GetMe,
  GetParticularList,
  GetPCV,
  GetPettyBalance,
  GetPettyCashList,
  GetPettyCashReqList,
  GetVoucher,
  GetVoucherItem,
  GetVoucherItemPart,
  GetVoucherPart,
  GetVoucherReports,
} from '../utils/GetApi';
import {
  BaseListView,
  CashInBankType,
  CvNo,
  Me,
  Particulars,
  ParticularTags,
  PCVTYpe,
  PettyCashListType,
  PettyCurrentBalanceType,
  PettyVoucherFormStatusType,
  SelectedPettyVoucherFormItem,
  VoucherItemListType,
  VoucherItemType,
  VoucherPartReportsType,
  VoucherPartType,
  VoucherReportsType,
  VoucherType,
} from '../utils/Interface';
import { PostPettyCashVoucher, PostVoucher, PostVoucherItem, PostVoucherItemType, PostVoucherType } from '../utils/PostApi';
import style from '../components/Form/form.module.css';
import axiosInstance, { GetTokens } from '../utils/axiosInstance';
import printJS from 'print-js';
import { TableLoader } from '../components/Loader/Spinner';
import Pagination from '../components/Pagination/paginate';
import GoToPage from '../components/Pagination/GoToPage';
import { v4 as uuid } from 'uuid';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import PettyCashVoucherForm from './VoucherPages/PettyCashVoucherForm';
import PettyCashVoucherFormPrint from './VoucherPages/PettyCashVoucherFormPrint';
import ChequeVoucherPrint from './VoucherPages/ChequeVoucherPrint';

const PettyCashVoucher = () => {
  const GetVoucherList = async () => {
    setDisableNextPrev(true);

    GetPettyCashList<PettyCashListType>({
      setList: setPettyCashList,
      pageNum: currentPage,
      has_req: false,
      limit: apiLimit,
    }).then((res) => {
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });

    GetPettyBalance().then((res) => {
      if (res === undefined) return;
      setTotalBalance(res?.total_balance);
    });

    GetCurrentBalPetty2().then((res) => {
      if (res === undefined) return;
      const test = res?.results.find((item) => item.current_balance);
      setCurrentBalance(test?.current_balance);
    });
  };

  const [date, setDate] = useState('');
  const [pcv, setPCV] = useState<string | undefined>();
  const [to, setTo] = useState('');
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [total, setTotal] = useState(0);

  const [datePettyForm, setDatePettyForm] = useState('');
  const [cvPettyForm, setCVPettyForm] = useState<string | undefined>();
  const [payeePettyForm, setPayeePettyForm] = useState('');
  const [descriptionPettyForm, setDescriptionPettyForm] = useState('');
  const [showFormPrint, setShowFormPrint] = useState(false);

  const [totalBalance, setTotalBalance] = useState<number | undefined>();
  const [currentBalance, setCurrentBalance] = useState<number | undefined>();
  const [voucherList, setVoucherList] = useState<PostVoucherType | null>(null);
  const [particularList, setParticularList] = useState<BaseListView<Particulars> | null>(null);
  const [cashInBankPart, setCashInBankPart] = useState<BaseListView<CashInBankType> | null>(null);

  const [pettyCashList, setPettyCashList] = useState<BaseListView<PettyCashListType> | null>(null);
  const [pettyCashReqList, setPettyCashReqList] = useState<BaseListView<PettyCashListType> | null>(null);
  const [showAdd, setShowAdd] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPrevLoad, setNextPrevLoad] = useState(true);
  const [apiLimit, setApiLimit] = useState(25);
  const [jumpToPage, setJumpToPage] = useState(1);
  const [disableNextPrev, setDisableNextPrev] = useState(true);
  const [currentPageReq, setCurrentPageReq] = useState(1);
  const [jumpToPageReq, setJumpToPageReq] = useState(1);
  const [nextPrevLoadReq, setNextPrevLoadReq] = useState(true);
  const [disableNextPrevReq, setDisableNextPrevReq] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  const [refreshAttempted, setRefreshAttempted] = useState(false);
  const [showGeneratePettyForm, setShowGeneratePettyForm] = useState(false);
  const [rerenderList, setRerenderList] = useState(true);

  const SetMe = async () => {
    const res = GetTokens();
    setMyAccInfo(res);
  };

  useEffect(() => {
    SetMe();
    GetVoucherList();
  }, [currentPage, apiLimit, rerenderList]);

  useEffect(() => {
    GetPettyCashReqList<PettyCashListType>({
      setList: setPettyCashReqList,
      pageNum: currentPageReq,
      has_req: true,
      limit: apiLimit,
    }).then((res) => {
      setNextPrevLoadReq(true);
      setDisableNextPrevReq(false);
    });
  }, [currentPageReq, apiLimit]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handlePageHide = (e: PageTransitionEvent) => {
      if (!e.persisted) {
        setRefreshAttempted(false);
      }
    };
    window.addEventListener('pagehide', handlePageHide);

    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (refreshAttempted) {
      return;
    }
    event.preventDefault();
    event.returnValue = '';
  };

  // #region Voucher Item

  interface VoucherItem {
    id: string;
    code: string;
    name: string;
    amount: string;
    particular: string;
    particularName: string;
  }

  const [voucherItem, setVoucherItem] = useState<(VoucherItem & VoucherStatusType)[]>([
    { id: uuid(), code: '', name: '', amount: '', particular: '', isSuccess: false, particularName: '' },
  ]);
  const [rerender, setRerender] = useState(true);
  const [rerender2, setRerender2] = useState(true);

  const voucherItemHandler = (index: number, name: 'name' | 'amount' | 'code', { target }: BaseSyntheticEvent) => {
    voucherItem[index][name] = target.value;
    if (name === 'name') {
      const code = getMatchingParticularCode(target.value, particularList);
      const id = getMatchingParticularID(target.value, particularList);
      const name = getMatchingParticularName(target.value, particularList);
      voucherItem[index].code = code ?? 'Invalid Account Title';
      voucherItem[index].particular = id ?? '';
      voucherItem[index].particularName = name ?? '';
      voucherItem[index].isSuccess = false;
    }
    if (name === 'code') {
      voucherItem[index].amount = '';
    }
    GetTotalAmount();
    setRerender((prev) => !prev);
  };

  const getMatchingParticularCode = (name: string, possibleParticulars?: BaseListView<Particulars> | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.results.find((item) => item.name.toLowerCase() === name.toLowerCase())?.code;
  };

  const getMatchingParticularID = (name: string, possibleParticulars?: BaseListView<Particulars> | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.results.find((item) => item.name.toLowerCase() === name.toLowerCase())?.id;
  };

  const getMatchingParticularName = (name: string, possibleParticulars?: BaseListView<Particulars> | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.results.find((item) => item.name.toLowerCase() === name.toLowerCase())?.name;
  };

  useEffect(() => {
    setVoucherItem(voucherItem);
    setVoucherList(voucherList);
    // setVoucherItemContainer(voucherItem)
  }, [rerender]);

  const AddButton = (
    onClick: MouseEventHandler,
    text: string,
    color: string,
    align: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
  ) => (
    <div style={{ textAlign: align }}>
      <button style={{ border: 'none', background: 'none', fontWeight: '500', color: color }} onClick={onClick} type={'button'}>
        <i className="fa-solid fa-plus"></i>
        {text}
      </button>
    </div>
  );

  const AddNewPart = () => {
    const lastVoucherItem = voucherItem[voucherItem.length - 1];
    if (lastVoucherItem.name.trim() === '') {
      SwalFailed('No Particular Name!');
      return;
    }
    if (parseFloat(lastVoucherItem.amount) <= 0) {
      SwalFailed('No Total Amount!');
      return;
    }
    if (lastVoucherItem.particular === '') {
      SwalFailed('Invalid Account Title');
      return;
    }
    setVoucherItem((prev) => [...prev.concat({ id: uuid(), name: '', code: '', particular: '', amount: '', particularName: '' })]);

    // TO BE DELETED
  };

  const GetTotalAmount = () => {
    const total = voucherItem.map((item) => parseFloat(item.amount === '' ? '0' : item.amount));
    const totalAmount = total.reduce((total, currentval) => (total = total + currentval), 0);

    setTotal(totalAmount);
  };
  // #endregion Voucher List

  // #region Petty bulk adding
  const [disableAll, setDisableAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  interface VoucherStatusType {
    isPosting?: boolean;
    isSuccess?: boolean;
    isDone?: boolean;
  }

  const HandleVoucherChangeOnStatus = (id: string | undefined, isPosting: boolean, isSuccess: boolean, isDone: boolean) => {
    setVoucherItem((prev) => [
      ...prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isPosting,
            isSuccess,
            isDone,
          };
        }
        return item;
      }),
    ]);
  };

  const SubmitBulkAddPettyCash = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (!myAccInfo) return;

    const id = voucherItem.find((item) => item.id);
    if (id?.particular === '') {
      SwalFailed('Invalid Account Title');
      return;
    }
    if (date === '') {
      SwalFailed('Date is Empty!');
      return;
    }

    if (to.trim() === '') {
      SwalFailed('To is Empty!');
      return;
    }

    let error = false;

    setIsLoading(true);
    const filteredVoucherItem = voucherItem.filter((item) => item.isSuccess === false);
    if (filteredVoucherItem.length <= 0) {
      setIsLoading(false);
      return SwalFailed('Something went wrong!');
    }
    setDisableAll(true);
    for (let i = 0; i < filteredVoucherItem.length; i++) {
      const voucherType = filteredVoucherItem[i];
      HandleVoucherChangeOnStatus(voucherType.id, true, false, false);
      const isSuccess = await PostPettyCashVoucher({
        id: voucherType.id,
        church: myAccInfo.church,
        church_account: myAccInfo.church_account,
        particular: voucherType.particular,
        date: date,
        to: to,
        pcv_no: pcv,
        amount: voucherType.amount,
      });
      if (isSuccess) HandleVoucherChangeOnStatus(voucherType.id, false, true, true);
      else {
        HandleVoucherChangeOnStatus(voucherType.id, false, false, true);
        error = true;
        setErrorOccured(true);
      }
    }

    setIsLoading(false);
    if (error) return SwalFailed('Something went wrong!');
    SwalSuccess('Particular/s Added!').then((res) => {
      setShowAdd(true);
      setDisableAll(false);
    });
  };
  const [failedHolder, setFailedHolder] = useState<PettyCashListType | null>(null);

  const ReSubmitBulkAddPettyCash = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (!myAccInfo) return;

    let error = false;

    setIsLoading(true);
    const filteredVoucherItem = voucherItem.filter((item) => item.isSuccess === false);
    if (filteredVoucherItem.length <= 0) {
      setIsLoading(false);
      return SwalFailed('Something went wrong!');
    }
    setDisableAll(true);
    // Update the first item
    await axiosInstance
      .get(`/parish/api/petty-cash-voucher/update/${filteredVoucherItem[0].id}`)
      .then((res) => setFailedHolder(res.data))
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') return null;
        error = true;
        setErrorOccured(true);
      });

    // Handle the result of updating the first item
    if (!error) {
      HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, true, false, false);
      const isUpdated = await axiosInstance.put(`/parish/api/petty-cash-voucher/update/${filteredVoucherItem[0].id}`, {
        id: filteredVoucherItem[0].id,
        church: myAccInfo.church,
        church_account: myAccInfo.church_account,
        particular: filteredVoucherItem[0].particular,
        date: date,
        to: to,
        pcv_no: pcv,
        amount: filteredVoucherItem[0].amount,
      });
      if (isUpdated) {
        HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, false, true, true);
      } else {
        HandleVoucherChangeOnStatus(filteredVoucherItem[0].id, false, false, true);
        error = true;
        setErrorOccured(true);
      }
    }

    // Post the rest of the items
    for (let i = 1; i < filteredVoucherItem.length; i++) {
      const voucherType = filteredVoucherItem[i];
      if (failedHolder?.id === voucherType.id) return;
      HandleVoucherChangeOnStatus(voucherType.id, true, false, false);
      const isSuccess = await PostPettyCashVoucher({
        id: voucherType.id,
        church: myAccInfo.church,
        church_account: myAccInfo.church_account,
        particular: voucherType.particular,
        date: date,
        to: to,
        pcv_no: pcv,
        amount: voucherType.amount,
      });
      if (isSuccess) {
        HandleVoucherChangeOnStatus(voucherType.id, false, true, true);
      } else {
        HandleVoucherChangeOnStatus(voucherType.id, false, false, true);
        error = true;
        setErrorOccured(true);
      }
    }

    setIsLoading(false);
    if (error) return SwalFailed('Something went wrong!');
    SwalSuccess('Particular/s Added!').then((res) => {
      setShowAdd(true);
      setDisableAll(false);
    });
  };

  const PrintPetty = () => {
    Print();
    setDate('');
    setTo('');
    setVoucherItem([{ id: uuid(), code: '', name: '', amount: '', particular: '', isSuccess: false, particularName: '' }]);
    setTotal(0);
    setErrorOccured(false);
    setShowAdd(false);
    setRerender2((prev) => !prev);
  };
  // #endregion

  // #region Voucher View

  const [view, setView] = useState<'table' | 'form'>('table');
  const AddPart = () => {
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === '' || !pKey) {
      return setView((prev) => (prev === 'table' ? 'form' : 'table'));
    }
    if (pKey !== '') return SwalFailed('This is not your parish!');
  };

  useEffect(() => {
    if (view === 'form' || showGeneratePettyForm === true) {
      GetPCV().then((res) => {
        if (res?.next_pcv === undefined) return;
        setPCV(res.next_pcv);
      });

      GetParticularList<Particulars>({
        setList: setParticularList,
      });

      GetCvNo().then((res) => {
        if (res?.next_cv === undefined) return;
        setCVPettyForm(res.next_cv);
      });

      GetCashInBankPart<CashInBankType>({
        setList: setCashInBankPart,
      });
    }
  }, [rerender2, view, showGeneratePettyForm]);

  // #endregion

  // #region Petty Voucher Cheque Form Functions
  const voucherID = uuid();

  const [selectedPettyCashList, setSelectedPettyCashList] = useState<(SelectedPettyVoucherFormItem & PettyVoucherFormStatusType)[]>([
    { item_id: uuid(), particular_code: '', particular: '', particular_id: '', isSuccess: false, debit: '', credit: '' },
  ]);

  const [rerender3, setRerender3] = useState(true);

  const voucherFormItemHandler = (
    index: number,
    name: 'particular_code' | 'particular' | 'debit' | 'credit',
    { target }: BaseSyntheticEvent
  ) => {
    selectedPettyCashList[index][name] = target.value;
    if (name === 'particular') {
      const code = getMatchingFormParticularCode(target.value, particularList);
      const id = getMatchingFormParticularID(target.value, particularList);
      selectedPettyCashList[index].particular_code = code ?? 'Invalid Account Title';
      selectedPettyCashList[index].particular_id = id ?? '';
    }
    if (name === 'debit') {
      selectedPettyCashList[index].credit = '';
    }
    if (name === 'credit') {
      selectedPettyCashList[index].debit = '';
    }
    setRerender3((prev) => !prev);
    // GetTotalFormAmount()
  };

  const getMatchingFormParticularCode = (name: string, possibleParticulars?: BaseListView<Particulars> | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.results.find((item) => item.name.toLowerCase() === name.toLowerCase())?.code;
  };

  const getMatchingFormParticularID = (name: string, possibleParticulars?: BaseListView<Particulars> | null) => {
    if (possibleParticulars === undefined || possibleParticulars === null) return;
    return possibleParticulars.results.find((item) => item.name.toLowerCase() === name.toLowerCase())?.id;
  };

  useEffect(() => {
    setSelectedPettyCashList(selectedPettyCashList);
    // setVoucherItemContainer(voucherItem)
  }, [rerender3]);

  const selectedPettyCashTotal = useMemo(() => {
    const list = selectedPettyCashList ? selectedPettyCashList.map((item) => item) : [];

    const debits = list.map((item) => parseFloat(item.debit === '' ? '0' : item.debit));
    const credits = list.map((item) => parseFloat(item.credit === '' ? '0' : item.credit));

    const totalDebit = debits.reduce((total, currentVal) => (total = total + currentVal), 0);
    const totalCredit = credits.reduce((total, currentVal) => (total = total + currentVal), 0);

    return {
      list,
      totalDebit,
      totalCredit,
    };
  }, [selectedPettyCashList, rerender3]);

  const writtenNumber = require('written-number');
  const toAmountWords = writtenNumber(selectedPettyCashTotal.totalDebit);

  const CloseVoucherModal = () => {
    setDate('');
    setPayeePettyForm('');
    setDescriptionPettyForm('');
    setSelectedPettyCashList([
      { item_id: uuid(), particular_id: '', particular_code: '', particular: '', isSuccess: false, debit: '', credit: '' },
    ]);
    setErrorOccured(false);
    setShowFormPrint(false);
    setShowGeneratePettyForm(false);
    setRerender3((prev) => !prev);
    setRerenderList((prev) => !prev);
  };

  const [lastIdPosted, setLastIdPost] = useState<string | null>(null);
  const [errorOccuredForm, setErrorOccuredForm] = useState(false);

  const HandleFormVoucherChangeOnStatus = (id: string | undefined, isPosting: boolean, isSuccess: boolean, isDone: boolean) => {
    setSelectedPettyCashList((prev) => [
      ...prev.map((item) => {
        if (item.item_id === id) {
          return {
            ...item,
            isPosting,
            isSuccess,
            isDone,
          };
        }
        return item;
      }),
    ]);
  };

  const SubmitBulkAddVoucherForm = async (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (!myAccInfo) return;

    if (datePettyForm === '') {
      SwalFailed('Date is Empty');
      return;
    }
    if (descriptionPettyForm.trim() === '') {
      SwalFailed('Description is Empty');
      return;
    }
    if (payeePettyForm.trim() === '') {
      SwalFailed('Pay To is Empty');
      return;
    }
    if (
      selectedPettyCashTotal.totalDebit !== selectedPettyCashTotal.totalCredit ||
      selectedPettyCashTotal.totalCredit !== selectedPettyCashTotal.totalDebit
    ) {
      SwalFailed('Debit and Credit is not equal');
      return;
    }

    let error = false;

    setIsLoading(true);
    const filteredvoucherFormItem = selectedPettyCashList.filter((item) => item.isSuccess === false);
    if (filteredvoucherFormItem.length <= 0) {
      setIsLoading(false);
      return SwalFailed('Something went wrong!');
    }
    setLastIdPost(voucherID);
    setDisableAll(true);
    const Voucher = await PostVoucher({
      id: voucherID,
      church: myAccInfo.church,
      name: `Voucher-${cvPettyForm}`,
      type: 'Cheque',
      amount_in_words: toAmountWords,
      date_time: date,
      cv_no: cvPettyForm,
      pay_to: payeePettyForm,
      description: descriptionPettyForm,
      church_account: myAccInfo.church_account,
      amount: selectedPettyCashTotal.totalDebit,
    });
    if (!Voucher) {
      SwalFailed('Something went Wrong!');
      return;
    }
    HandleFormVoucherChangeOnStatus(filteredvoucherFormItem[0].item_id, true, false, false);
    const FirstValue = await PostVoucherItem({
      id: filteredvoucherFormItem[0].item_id,
      church: myAccInfo.church,
      code: filteredvoucherFormItem[0].particular_code,
      name: filteredvoucherFormItem[0].particular,
      particular: filteredvoucherFormItem[0].particular_id,
      voucher: Voucher.id,
      debit: parseFloat(filteredvoucherFormItem[0].debit === '' ? '0' : filteredvoucherFormItem[0].debit),
      credit: parseFloat(filteredvoucherFormItem[0].credit === '' ? '0' : filteredvoucherFormItem[0].credit),
    });
    if (FirstValue) {
      HandleFormVoucherChangeOnStatus(filteredvoucherFormItem[0].item_id, false, true, true);
    } else {
      HandleFormVoucherChangeOnStatus(filteredvoucherFormItem[0].item_id, false, false, true);
      error = true;
      setErrorOccuredForm(true);
    }
    for (let i = 1; i < filteredvoucherFormItem.length; i++) {
      const voucherType = filteredvoucherFormItem[i];
      HandleFormVoucherChangeOnStatus(voucherType.item_id, true, false, false);
      const isSuccess = await PostVoucherItem({
        id: voucherType.item_id,
        church: myAccInfo.church,
        code: voucherType.particular_code,
        name: voucherType.particular,
        particular: voucherType.particular_id,
        voucher: Voucher.id,
        debit: parseFloat(voucherType.debit === '' ? '0' : voucherType.debit),
        credit: parseFloat(voucherType.credit === '' ? '0' : voucherType.credit),
      });
      await axiosInstance
        .put(`/parish/api/petty-cash-voucher/update/${voucherType.id}`, {
          has_check_voucher: true,
        })
        // eslint-disable-next-line no-loop-func
        .catch((err) => {
          HandleFormVoucherChangeOnStatus(voucherType.item_id, false, false, true);
          error = true;
          setErrorOccuredForm(true);
        });
      if (isSuccess) {
        HandleFormVoucherChangeOnStatus(voucherType.item_id, false, true, true);
      } else {
        HandleFormVoucherChangeOnStatus(voucherType.item_id, false, false, true);
        error = true;
        setErrorOccuredForm(true);
      }
    }

    setIsLoading(false);
    if (error) return SwalFailed('Something went wrong!');
    SwalSuccess('Particular/s Added!').then((res) => {
      setShowFormPrint(true);
      setDisableAll(false);
    });
  };

  const ReSubmitBulkAddVoucherForm = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (!myAccInfo) return;

    let error = false;

    setIsLoading(true);
    const filteredVoucherFormItem = selectedPettyCashList.filter((item) => item.isSuccess === false);
    if (filteredVoucherFormItem.length <= 0) {
      setIsLoading(false);
      return SwalFailed('Something went wrong!');
    }
    setDisableAll(true);
    // Check if Voucher is already existing
    await axiosInstance
      .get(`/parish/api/vouchers/${lastIdPosted}`)
      .then((res) => {})
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') return null;
        error = true;
        setErrorOccured(true);
      });

    await axiosInstance
      .get(`/parish/api/voucher-items/${filteredVoucherFormItem[0].item_id}`)
      .then((res) => {})
      .catch((err) => {
        if (err.code === 'ERR_BAD_REQUEST') return null;
        error = true;
        setErrorOccured(true);
      });
    // Handle the result of updating the first item
    if (error) {
      SwalFailed('Something went wrong!');
      return;
    }
    const VoucherIsUpdated = await axiosInstance.put(`/parish/api/vouchers/${lastIdPosted}`, {
      id: lastIdPosted,
      church: myAccInfo.church,
      name: `Voucher-${cvPettyForm}`,
      type: 'Cheque',
      amount_in_words: toAmountWords,
      date_time: datePettyForm,
      cv_no: cvPettyForm,
      pay_to: payeePettyForm,
      description: descriptionPettyForm,
      church_account: myAccInfo.church_account,
      amount: selectedPettyCashTotal.totalDebit,
    });
    if (!VoucherIsUpdated) {
      SwalFailed('Something went wrong!');
      return;
    }
    HandleFormVoucherChangeOnStatus(filteredVoucherFormItem[0].item_id, true, false, false);
    const isUpdated = await axiosInstance.put(`/parish/api/voucher-items/${filteredVoucherFormItem[0].item_id}`, {
      id: filteredVoucherFormItem[0].item_id,
      church: myAccInfo.church,
      code: filteredVoucherFormItem[0].particular_code,
      name: filteredVoucherFormItem[0].particular,
      particular: filteredVoucherFormItem[0].particular_id,
      voucher: VoucherIsUpdated.data.id,
      debit: parseFloat(filteredVoucherFormItem[0].debit === '' ? '0' : filteredVoucherFormItem[0].debit),
      credit: parseFloat(filteredVoucherFormItem[0].credit === '' ? '0' : filteredVoucherFormItem[0].credit),
    });
    await axiosInstance
      .put(`/parish/api/petty-cash-voucher/update/${filteredVoucherFormItem[0].id}`, {
        has_check_voucher: true,
      })
      .catch((err) => {
        HandleFormVoucherChangeOnStatus(filteredVoucherFormItem[0].item_id, false, false, true);
        error = true;
        setErrorOccuredForm(true);
      });
    if (isUpdated) {
      HandleFormVoucherChangeOnStatus(filteredVoucherFormItem[0].id, false, true, true);
    } else {
      HandleFormVoucherChangeOnStatus(filteredVoucherFormItem[0].id, false, false, true);
      error = true;
      setErrorOccured(true);
    }
    // // Post the rest of the items
    for (let i = 1; i < filteredVoucherFormItem.length; i++) {
      const voucherType = filteredVoucherFormItem[i];
      HandleFormVoucherChangeOnStatus(voucherType.item_id, true, false, false);
      const isSuccess = await PostVoucherItem({
        id: voucherType.item_id,
        church: myAccInfo.church,
        code: voucherType.particular_code,
        name: voucherType.particular,
        particular: voucherType.particular_id,
        voucher: VoucherIsUpdated.data.id,
        debit: parseFloat(voucherType.debit === '' ? '0' : voucherType.debit),
        credit: parseFloat(voucherType.credit === '' ? '0' : voucherType.credit),
      });
      await axiosInstance
        .put(`/parish/api/petty-cash-voucher/update/${voucherType.id}`, {
          has_check_voucher: true,
        })
        // eslint-disable-next-line no-loop-func
        .catch((err) => {
          HandleFormVoucherChangeOnStatus(voucherType.item_id, false, false, true);
          error = true;
          setErrorOccuredForm(true);
        });
      if (isSuccess) HandleFormVoucherChangeOnStatus(voucherType.item_id, false, true, true);
      else {
        HandleFormVoucherChangeOnStatus(voucherType.item_id, false, false, true);
        error = true;
        setErrorOccured(true);
      }
    }

    setIsLoading(false);
    if (error) return SwalFailed('Something went wrong!');
    SwalSuccess('Particular/s Added!').then((res) => {
      setShowFormPrint(true);
      setDisableAll(false);
    });
  };

  // #endregion

  // #region Pagination and JumpTo

  const decrement = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage - 1);
  };

  const increment = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage + 1);
  };

  const JumpToButton = () => {
    if (!pettyCashList) return;
    setNextPrevLoad(false);
    setDisableNextPrev(true);
    const lastPage = Math.round(pettyCashList?.count / apiLimit);
    const pageToJump = jumpToPage > lastPage ? lastPage : jumpToPage < 1 ? 1 : jumpToPage;
    GetPettyCashList<PettyCashListType>({
      setList: setPettyCashList,
      pageNum: pageToJump,
      has_req: false,
      limit: apiLimit,
    }).then((res) => {
      setCurrentPage(pageToJump);
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });
  };

  const decrementReq = () => {
    setNextPrevLoadReq(false);
    setCurrentPageReq(currentPageReq - 1);
  };

  const incrementReq = () => {
    setNextPrevLoadReq(false);
    setCurrentPageReq(currentPageReq + 1);
  };

  const JumpToButtonReq = () => {
    if (!pettyCashReqList) return;
    setNextPrevLoadReq(false);
    setDisableNextPrevReq(true);
    const lastPage = Math.round(pettyCashReqList?.count / apiLimit);
    const pageToJump = jumpToPageReq > lastPage ? lastPage : jumpToPageReq < 1 ? 1 : jumpToPageReq;
    GetPettyCashReqList<PettyCashListType>({
      setList: setPettyCashReqList,
      pageNum: pageToJump,
      has_req: true,
      limit: apiLimit,
    }).then((res) => {
      setCurrentPageReq(pageToJump);
      setNextPrevLoadReq(true);
      setDisableNextPrevReq(false);
    });
  };
  // #endregion
  const noScroll = (e: BaseSyntheticEvent) => {
    e.target.blur();

    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const DeleteCoaShowModal = (e: BaseSyntheticEvent, index: number) => {
    e.preventDefault();
    if (voucherItem.length > 1) {
      setVoucherItem((prev) => prev.filter((_, i) => i !== index));
      const test = total - parseFloat(voucherItem[index].amount);
      setTotal(test);
    }
  };

  const isChecked = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (!pettyCashList) return;
    const { value, checked } = e.target;
    if (checked) {
      const selected = pettyCashList.results.find((item) => item.id === id);
      if (!selected) return;
      const amount = selected.amount;
      const updatedSelected = { ...selected, debit: amount, credit: '', isSuccess: false, item_id: uuid() };
      setSelectedPettyCashList((prev) => [...prev.concat(updatedSelected)]);
    } else {
      setSelectedPettyCashList((prev) => [...prev.filter((item) => item.id !== id)]);
    }
  };

  const selectedPettyCashListID = useMemo(() => selectedPettyCashList.map((item) => item.id), [selectedPettyCashList]);

  return (
    <>
      {showGeneratePettyForm ? (
        <>
          <PettyCashVoucherForm
            disableAll={disableAll}
            errorOccured={errorOccuredForm}
            date={datePettyForm}
            setDate={setDatePettyForm}
            description={descriptionPettyForm}
            setDescription={setDescriptionPettyForm}
            cv={cvPettyForm}
            payee={payeePettyForm}
            setPayee={setPayeePettyForm}
            amountInPeso={selectedPettyCashTotal.totalDebit.toString()}
            noScroll={noScroll}
            SubmitBulkAddVoucherForm={SubmitBulkAddVoucherForm}
            voucherItemHandler={voucherFormItemHandler}
            voucherPartList={cashInBankPart}
            ReSubmitBulkAddVoucherForm={ReSubmitBulkAddVoucherForm}
            selectedPettyCashList={selectedPettyCashList}
            totalDebit={selectedPettyCashTotal.totalDebit}
            totalCredit={selectedPettyCashTotal.totalCredit}
            setShowGeneratePettyForm={setShowGeneratePettyForm}
          />
          <PettyCashVoucherFormPrint
            showAdd={showFormPrint}
            CloseVoucherModal={CloseVoucherModal}
            id="PrintPettyForm"
            payee={payeePettyForm}
            amountInPeso={selectedPettyCashTotal.totalDebit.toString()}
            date={datePettyForm}
            description={descriptionPettyForm}
            cv={cvPettyForm}
            selectedPettyCash={selectedPettyCashList}
            totalDebit={selectedPettyCashTotal.totalDebit}
            totalCredit={selectedPettyCashTotal.totalCredit}
            PrintVoucher={PrintPettyForm}
            PrintCheque={PrintChequeForm}
          />
          <ChequeVoucherPrint
            date={datePettyForm}
            payTo={payeePettyForm}
            amount={selectedPettyCashTotal.totalDebit.toString()}
            id="PrintChequeForm"
          />
        </>
      ) : (
        <>
          <div style={{ marginLeft: '50%', textAlign: 'end', color: '#055437' }}>
            <div style={{ padding: '0.5' }}>
              <strong>Current Balance: {ParseToPHP(currentBalance ? currentBalance : 0)}</strong>
            </div>
            <div style={{ padding: '0.5' }}>
              <strong>Total Balance: {ParseToPHP(totalBalance ? totalBalance : 0)}</strong>
            </div>
          </div>
          <Content flex justify={view === 'table' ? 'space-between' : 'end'}>
            {view === 'table' ? (
              <div style={{ paddingTop: '16px', fontSize: '24px', color: '#055437' }}>
                <strong>Pending Request</strong>
              </div>
            ) : (
              <></>
            )}
            <FormButton
              width="300px"
              success
              title={view === 'table' ? 'ADD PETTY CASH VOUCHER' : 'PETTY CASH VOUCHER LIST'}
              name={''}
              disabled={pettyCashList === null || disableAll}
              onClick={() => {
                AddPart();
              }}
              type={'button'}
            />
          </Content>
          {view === 'table' ? (
            <>
              <Table header={['PCV#', 'Account Title', 'Amount']} success>
                {nextPrevLoad ? (
                  pettyCashList ? (
                    pettyCashList.results.length > 0 ? (
                      pettyCashList.results.map((petty, i) => (
                        <TableRow
                          key={petty.id}
                          info={[
                            <>
                              <label
                                className={'flex justify-evenly '}
                                style={{
                                  width: 240,
                                }}
                              >
                                <input
                                  defaultChecked={selectedPettyCashListID.includes(petty.id)}
                                  type={'checkbox'}
                                  disabled={isLoading}
                                  onChange={(e) => {
                                    isChecked(e, petty.id);
                                  }}
                                />
                                {petty.pcv_no}
                              </label>
                            </>,
                            <>{petty.particular}</>,
                            <>{ParseToPHP(parseFloat(petty.amount))}</>,
                          ]}
                        />
                      ))
                    ) : (
                      <TableEmpty colSpan={3}>No Petty Cash Voucher/s Found!</TableEmpty>
                    )
                  ) : (
                    <TableLoader colSpan={3} type={'dual_ring'} />
                  )
                ) : (
                  <TableLoader colSpan={3} type={'dual_ring'} />
                )}
              </Table>
              <Content flex justify="right">
                <FormButton
                  width="350px"
                  success
                  title={`Generate Petty Cash Voucher (${selectedPettyCashListID.length - 1})`}
                  name={''}
                  disabled={pettyCashList === null || selectedPettyCashListID.length === 1}
                  onClick={() => {
                    setShowGeneratePettyForm(true);
                  }}
                  type={'button'}
                />
              </Content>
              <Content flex justify={'space-between'} padding={'0.5em'}>
                <GoToPage
                  disabled={disableNextPrev}
                  next={pettyCashList?.next}
                  prev={pettyCashList?.previous}
                  jumpTo={JumpToButton}
                  currentPage={jumpToPage}
                  setCurrentPage={setJumpToPage}
                  count={pettyCashList?.count}
                  limit={apiLimit}
                />
                <Pagination
                  limit={apiLimit}
                  next={pettyCashList?.next}
                  prev={pettyCashList?.previous}
                  currentPage={currentPage}
                  increment={increment}
                  decrement={decrement}
                  count={pettyCashList?.count}
                  disabled={disableNextPrev}
                />
              </Content>
              <hr style={{ margin: '20px 0px' }} />
              <div style={{ display: 'flex', justifyContent: 'start', paddingTop: '16px', fontSize: '24px', color: '#055437' }}>
                <div>
                  <strong>Requested</strong>
                </div>
              </div>
              <Table header={['PCV#', 'Account Title', 'Amount']} success>
                {nextPrevLoadReq ? (
                  pettyCashReqList ? (
                    pettyCashReqList.results.length > 0 ? (
                      pettyCashReqList.results.map((petty, i) => (
                        <TableRow
                          key={i}
                          info={[<>{petty.pcv_no}</>, <>{petty.particular}</>, <>{ParseToPHP(parseFloat(petty.amount))}</>]}
                        />
                      ))
                    ) : (
                      <TableEmpty colSpan={3}>No Petty Cash Voucher/s Found!</TableEmpty>
                    )
                  ) : (
                    <TableLoader colSpan={3} type={'dual_ring'} />
                  )
                ) : (
                  <TableLoader colSpan={3} type={'dual_ring'} />
                )}
              </Table>
              <Content flex justify={'space-between'} padding={'0.5em'}>
                <GoToPage
                  disabled={disableNextPrevReq}
                  next={pettyCashReqList?.next}
                  prev={pettyCashReqList?.previous}
                  jumpTo={JumpToButtonReq}
                  currentPage={jumpToPageReq}
                  setCurrentPage={setJumpToPageReq}
                  count={pettyCashReqList?.count}
                  limit={apiLimit}
                />
                <Pagination
                  limit={apiLimit}
                  next={pettyCashReqList?.next}
                  prev={pettyCashReqList?.previous}
                  currentPage={currentPageReq}
                  increment={incrementReq}
                  decrement={decrementReq}
                  count={pettyCashReqList?.count}
                  disabled={disableNextPrevReq}
                />
              </Content>
            </>
          ) : (
            <>
              <Container flex justify="space-between">
                <Content grow="1" padding="0em 0.5em">
                  <FormInput
                    required
                    margin="0.6em 0 0 0"
                    title={
                      <>
                        <span className="text-red-500">*</span>Date:
                      </>
                    }
                    type={'date'}
                    disabled={disableAll}
                    name={''}
                    value={date}
                    onChange={({ target }: BaseSyntheticEvent) => {
                      setDate(target.value);
                    }}
                  />
                </Content>
                <Content grow="1" padding="0 0.5em">
                  <FormInput
                    disabled
                    margin="0.6em 0 0 0"
                    title={
                      <>
                        <span className="text-red-500">*</span>PCV#:
                      </>
                    }
                    name={''}
                    value={pcv ? pcv : ''}
                    onChange={({ target }: BaseSyntheticEvent) => {}}
                  />
                </Content>
                <Content grow="1" padding="0 0.5em">
                  <FormInput
                    margin="0.6em 0 0 0"
                    required
                    title={
                      <>
                        <span className="text-red-500">*</span>To:
                      </>
                    }
                    name={''}
                    disabled={disableAll}
                    value={to}
                    onChange={({ target }: BaseSyntheticEvent) => {
                      setTo(target.value);
                    }}
                  />
                </Content>
              </Container>
              <Form
                id={''}
                handleSubmit={(e) => {
                  SubmitBulkAddPettyCash(e);
                }}
              >
                <Table2 success header={['Acount Titles', 'Amount']}>
                  {voucherItem.map((item, i) => (
                    <TableRow2
                      colspan={2}
                      key={i}
                      padding=".4em .5em"
                      info={[
                        <div style={{ display: 'flex', margin: '.2em 0' }}>
                          {item.isPosting && (
                            <span style={{ marginTop: '0.8em' }}>
                              <ReactLoading width={25} height={25} type="spin" color="#00b894" />
                            </span>
                          )}
                          {item.isSuccess ? (
                            <span style={{ padding: '0.2em', fontSize: '1.5em' }}>
                              <i className="fa-solid fa-circle-check"></i>
                            </span>
                          ) : (
                            <></>
                          )}
                          <div className={style.inputContainer} style={{ padding: '0.2em' }}>
                            <input
                              key={`a-${i}`}
                              type={'text'}
                              {...(i === 0 && { title: '' })}
                              name={''}
                              value={item.name}
                              required
                              disabled={disableAll}
                              onChange={(e) => {
                                voucherItemHandler(i, 'name', e);
                              }}
                              list={'partList'}
                            />
                            <datalist id={'partList'}>
                              {particularList?.results.map((part, i) => (
                                <option key={i} value={part.name}></option>
                              ))}
                            </datalist>
                          </div>

                          <FormInput
                            key={`b-${i}`}
                            type={'number'}
                            {...(i === 0 && { title: '' })}
                            name={''}
                            required
                            value={item.amount}
                            padding={'.2em'}
                            disabled={disableAll}
                            onWheel={noScroll}
                            placeHolder={'0'}
                            onChange={(e) => {
                              voucherItemHandler(i, 'amount', e);
                            }}
                            textAlign={'right'}
                          />
                          <span style={{ paddingLeft: '0.5em' }}></span>
                          <TableAction
                            icon={<i className="fa-solid fa-trash"></i>}
                            disabled={disableAll}
                            onClick={(e) => {
                              DeleteCoaShowModal(e, i);
                            }}
                          />
                        </div>,
                      ]}
                    />
                  ))}
                  <TableRow2
                    padding=".4em .5em"
                    info={[
                      AddButton(
                        () => {
                          AddNewPart();
                        },
                        'Add Account Title',
                        '#00b894',
                        'left'
                      ),
                    ]}
                  />
                  <TableRow2
                    padding=".4em .5em"
                    info={[
                      <h4></h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'bold', paddingRight: '0.4em', color: '#055437' }}>
                        Total Amount: {ParseToPHP(total ? total : 0)}
                      </h4>,
                    ]}
                  />
                </Table2>
                {errorOccured ? (
                  <Content flex justify="right">
                    <FormButton
                      secondary
                      width="20em"
                      success
                      title={'RETRY PETTY CASH VOUCHER'}
                      name={''}
                      onClick={(e) => {
                        ReSubmitBulkAddPettyCash(e);
                      }}
                      type={'submit'}
                    ></FormButton>
                  </Content>
                ) : (
                  <Content flex justify="right">
                    <FormButton width="20em" success title={'CREATE PETTY CASH VOUCHER'} name={''} type={'submit'}></FormButton>
                  </Content>
                )}
              </Form>
            </>
          )}
          <Modal
            title={`Petty Cash Voucher`}
            success
            show={showAdd}
            hide={true}
            onClose={() => {
              setShowAdd(false);
            }}
            name={''}
            width={'800px'}
          >
            <pre id="PrintPetty">
              <div style={{ display: 'flex', textAlign: 'start' }}>
                <div style={{ marginRight: 'auto', color: '#055437' }}>
                  <p>PCV #: {pcv}</p>
                  <p>To: {to}</p>
                </div>
                <div style={{ color: '#055437' }}>
                  <p>Date: {FormatDate(date ?? 'No Date')}</p>
                </div>
              </div>
              <div>
                <Table2 header={['Account Titles', 'Amount']} success>
                  {voucherItem.map((item, i) => (
                    <TableRow2
                      key={i}
                      info={[
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{item.name}</h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal' }}>
                          {ParseToPHP(parseFloat(item.amount ? item.amount : '0'))}
                        </h4>,
                      ]}
                    />
                  ))}
                  <TableRow2
                    info={[
                      <h4 style={{ textAlign: 'left', fontWeight: 'bold' }}>Total:</h4>,
                      <h4 style={{ textAlign: 'end', fontWeight: 'bold' }}>{ParseToPHP(total)}</h4>,
                    ]}
                  />
                </Table2>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
                <p>Approved for Payment:</p>
                <p>Paid By:</p>
                <p>Received Payment:</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold', color: '#055437' }}>
                <p>_______________</p>
                <p>_______________</p>
                <p>_______________</p>
              </div>
            </pre>
            <Container flex justify="center">
              <Content padding="0 1em">
                <FormButton
                  width="18em"
                  success
                  title={'PRINT/SAVE'}
                  name={''}
                  onClick={() => {
                    PrintPetty();
                  }}
                  type={'submit'}
                />
              </Content>
            </Container>
          </Modal>
        </>
      )}
    </>
  );
};

const Print = async () => {
  printJS({
    printable: 'PrintPetty',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

const PrintPettyForm = async () => {
  printJS({
    printable: 'PrintPettyForm',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

const PrintChequeForm = async () => {
  printJS({
    printable: 'PrintChequeForm',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

export default PettyCashVoucher;
