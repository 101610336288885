import axios from 'axios';
import { Me } from './Interface';

const baseURL = process.env.REACT_APP_BASE_URL;

// testing public api
// const baseURL = "https://rickandmortyapi.com"

export function isTokenValid(token: any): token is Me {
  if (!token) return false;
  if (!token.hasOwnProperty('access_token')) return false;
  if (!token.hasOwnProperty('church')) return false;
  if (!token.hasOwnProperty('church_account')) return false;
  if (!token.hasOwnProperty('expires_in')) return false;
  if (!token.hasOwnProperty('refresh_token')) return false;
  if (!token.hasOwnProperty('role')) return false;
  if (!token.hasOwnProperty('username')) return false;
  return (token as Me) !== undefined;
}

export function isAdmin(token: any): token is Me {
  if (!token) return false;
  if (!token.hasOwnProperty('role')) return false;
  if (token.role === 'superuser') return (token as Me) !== undefined;
  return false;
}

export const GetTokens = () => (sessionStorage.getItem('authTokens') ? JSON.parse(sessionStorage.getItem('authTokens') ?? '') : '');

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${GetTokens().access_token ? GetTokens().access_token : ''}`,
  },
});

const refreshAccessToken = async () => {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

  const authTokens = sessionStorage.getItem('authTokens') ? JSON.parse(sessionStorage.getItem('authTokens') ?? '') : null;

  let refresh = '';

  await fetch('http://localhost:8000/o/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `grant_type=refresh_token&refresh_token=${authTokens.refresh_token}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
    })
    .then((data) => {
      if (!data) return;
      sessionStorage.setItem('authTokens', JSON.stringify(data));
      refresh = data.refresh_token;
    });
  return refresh;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const authTokens = sessionStorage.getItem('authTokens') ? sessionStorage.getItem('authTokens') : '{}';
    if (!authTokens) return config;
    const keys = JSON.parse(authTokens);
    config.headers = {
      Authorization: `Bearer ${keys.access_token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
