import { access } from 'fs';
import React, { BaseSyntheticEvent, ChangeEvent, FormEvent, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import Container, { Content } from '../components/Container/Container';
import Form, { FormButton, FormInput, FormTextArea } from '../components/Form/Form';
import { Loader, TableLoader } from '../components/Loader/Spinner';
import Modal, { ModalFooterButton } from '../components/Modal/Modal';
import Select, { Option } from '../components/Select/Select';
import { SwalFailed, SwalFailedCustom, SwalLoading, SwalSuccess, SwalUnderCons } from '../components/Swal/Swal';
import Table, { TableAction, TableEmpty, TableRow } from '../components/Table/Table';
import axiosInstance, { GetTokens, isTokenValid } from '../utils/axiosInstance';
import { FormatDate, ParseToPHP } from '../utils/Formatter';
import {
  GetAR,
  GetCashReceipt,
  GetCashReports,
  GetChurchWithDetails,
  GetCoAList,
  GetCurrentAssets,
  GetGenericList,
  GetIncomePart,
  GetMe,
} from '../utils/GetApi';
import {
  ArNo,
  BaseListView,
  CashReceiptType,
  CashReportsType,
  ChartOfAccounts,
  CoAListType,
  CurrentAssetsType,
  IncomePartType,
  Me,
  Parish,
  ParishWithDetailsType,
  Particulars,
  ParticularTags,
  ProcessedCashReceiptType,
} from '../utils/Interface';
import { AddCashReceipts, AddChartOfAccount, PostParticularName, PostParticularTag } from '../utils/PostApi';
import style from '../components/styles/cashreceipts.module.css';
// import { ProcessCashReceipt } from "../utils/Remapper";
import printJS from 'print-js';
import CashReceiptsPrint from './CashReceiptsPages/PrintCashReceipts';
import SelectedReceiptTable from './CashReceiptsPages/SelectedReceiptTable';
import PrintReceiptTable from './CashReceiptsPages/PrintReceiptTable';
import CashReportsTable from './CashReceiptsPages/CashReportsTable';
import PrintCashReports from './CashReceiptsPages/PrintCashReports';
import VoidSticker from '../components/Modal/VoidSticker';
import Pagination from '../components/Pagination/paginate';
import GoToPage from '../components/Pagination/GoToPage';

const CashReceipts = () => {
  const [myAccInfo, setMyAccInfo] = useState<Me | null>(null);
  const [rerender, setRerender] = useState(true);
  const [rerender2, setRerender2] = useState(true);
  const [currentAssets, setCurrentAssets] = useState<BaseListView<CurrentAssetsType> | null>(null);
  const [incomeParticular, setIncomeParticular] = useState<BaseListView<IncomePartType> | null>(null);
  const [cashReceipts, setCashReceipts] = useState<BaseListView<CashReceiptType> | null>(null);
  const [cashReports, setCashReports] = useState<CashReportsType | null>(null);
  const [church, setChurch] = useState<BaseListView<ParishWithDetailsType> | null>(null);
  const [date, setDate] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [receivedFrom, setReceivedFrom] = useState('');
  const [paymentFor, setPaymentFor] = useState('');
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [formOfPayment, setFormOfPayment] = useState('');
  const [selectedAssets, setSelectedAssets] = useState<CurrentAssetsType>();
  const [selectedIncomePart, setSelectedIncomePart] = useState<IncomePartType>();
  const [arNo, setArNo] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [apiLimit, setApiLimit] = useState(25);
  // eslint-disable-next-line prefer-const
  let [jumpToPage, setJumpToPage] = useState(1);
  const [message, setMessage] = useState('');

  const writtenNumber = require('written-number');
  const toAmountWords = writtenNumber(amount);

  const [showVoid, setShowVoid] = useState(false);

  // #region useEffect List

  const ChangeAssetsHandler = ({ target: { value } }: BaseSyntheticEvent) => {
    const SelectedAsset = currentAssets?.results.find((assets) => assets.id === value);
    if (SelectedAsset === undefined) return;
    setSelectedAssets(SelectedAsset);
  };

  const ChangeIncomePartHandler = ({ target: { value } }: BaseSyntheticEvent) => {
    const SelectedIncomePart = incomeParticular?.results.find((part) => part.id === value);
    if (SelectedIncomePart === undefined) return;
    setSelectedIncomePart(SelectedIncomePart);
  };

  const [selectedCashReceipt, setSelectedCashReceipt] = useState<CashReceiptType | null>(null);

  const [reportsLoading, setReportsLoading] = useState(false);
  const [disablePrint, setDisablePrint] = useState(false);
  const [nextPrevLoad, setNextPrevLoad] = useState(true);
  const [disableNextPrev, setDisableNextPrev] = useState(true);

  const GetCashReportsList = () => {
    setReportsLoading(false);
    setDisablePrint(true);
    GetCashReports(dateFrom, dateTo).then((res) => {
      setCashReports(res);
      setReportsLoading(true);
      setDisablePrint(false);
    });
  };

  useEffect(() => {
    setReportsLoading(false);
    setDisablePrint(true);
    setDisableNextPrev(true);

    GetCashReceipt<CashReceiptType>({
      setList: setCashReceipts,
      pageNum: currentPage,
      limit: apiLimit,
    }).then((res) => {
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });

    // GetCashReportsList()
  }, [rerender2, rerender, currentPage, apiLimit]);

  useEffect(() => {
    SetMe();
  }, []);

  // #endregion

  const [isAdmin, setIsAdmin] = useState(false);

  const SetMe = async () => {
    const tokens = GetTokens();
    if (isTokenValid(tokens)) {
      if (tokens.role === 'superuser') {
        setIsAdmin(true);
      }
      setMyAccInfo(tokens);
    }
  };

  // #region Post Buttons

  const SubmitHandler = (ev: BaseSyntheticEvent) => {
    ev.preventDefault();
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey) return SwalFailed('This is not your parish!');
    if (!selectedAssets || selectedAssets === undefined) {
      SwalFailed('Please select a Balance Sheet!');
      return;
    }
    if (!selectedIncomePart || selectedIncomePart === undefined) {
      SwalFailed('Please select a Income Account!');
      return;
    }
    if (date === '') {
      SwalFailed('Date is Empty!');
      return;
    }
    if (receivedFrom.trim() === '') {
      SwalFailed('Received From is Empty!');
      return;
    }
    if (address.trim() === '') {
      SwalFailed('Address is Empty!');
      return;
    }
    if (amount.trim() === '') {
      SwalFailed('Amount is Empty!');
      return;
    }
    if (formOfPayment.trim() === '') {
      SwalFailed('Form of Payment is Empty!');
      return;
    }

    SubmitCashReceipt();
  };

  const SubmitCashReceipt = async () => {
    const onSuccess = () => {
      SwalSuccess('Cash Receipt Added!');
      setAddress('');
      setAmount('');
      setFormOfPayment('');
      setReceivedFrom('');
      setPaymentFor('');
      setDate('');
      setSelectedAssets(undefined);
      setSelectedIncomePart(undefined);
      setRerender((prev) => !prev);
      setShowAdd(false);
    };
    const onFail = () => {
      SwalFailed('OOPS! something went wrong.');
      setAddress('');
      setAmount('');
      setFormOfPayment('');
      setReceivedFrom('');
      setPaymentFor('');
      setDate('');
      setSelectedAssets(undefined);
      setSelectedIncomePart(undefined);
      setShowAdd(false);
      setRerender((prev) => !prev);
    };
    if (!myAccInfo) return;
    if (myAccInfo.church === 'N/A') {
      SwalFailed('No Parish assigned to your account!');
      return;
    }
    SwalLoading();
    if (selectedAssets === undefined) return;
    if (selectedIncomePart === undefined) return;
    if (arNo === undefined) return;
    await AddCashReceipts({
      church: myAccInfo.church,
      amount: amount,
      date_time: date,
      balance_sheet: selectedAssets.id,
      income_expense: selectedIncomePart.id,
      received_from: receivedFrom,
      address: address,
      payment_for: paymentFor,
      amount_in_words: toAmountWords,
      form_of_payment: formOfPayment,
      received_by: myAccInfo.church_account,
      ar_no: arNo,
      onSuccess,
      onFail,
    });
  };

  // #endregion

  const AddButton = (
    onClick: MouseEventHandler,
    text: string,
    color: string,
    align: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
    cursor: string
  ) => (
    <div style={{ textAlign: align, paddingTop: '0.8em', marginRight: '0.3em' }}>
      <button
        style={{ border: 'none', background: 'none', fontWeight: '600', color: color, cursor: cursor }}
        onClick={onClick}
        type={'button'}
      >
        <i className="fa-solid fa-plus"></i>
        {text}
      </button>
    </div>
  );

  const ModalOpenHandler = (name: string) => setModals((prev) => ({ ...prev, [name]: true }));
  const ModalCloseHandler = ({ target: { name } }: BaseSyntheticEvent) => setModals((prev) => ({ ...prev, [name]: false }));
  const [showAdd, setShowAdd] = useState(false);

  const ModalCloseButton = () => {
    setAddress('');
    setAmount('');
    setFormOfPayment('');
    setReceivedFrom('');
    setPaymentFor('');
    setDate('');
    setSelectedAssets(undefined);
    setSelectedIncomePart(undefined);
    setShowAdd(false);
  };

  const ShowCashReceipt = (index: number) => {
    if (!cashReceipts?.results) return;
    const cashIndex = cashReceipts?.results[index];
    setSelectedCashReceipt(cashIndex);
    setModals((prev) => ({ ...prev, viewCashReceipt: true }));
  };

  const ShowPrint = (index: number) => {
    if (!cashReceipts?.results) return;
    const cashIndex = cashReceipts?.results[index];
    setSelectedCashReceipt(cashIndex);
    setModals((prev) => ({ ...prev, printReceipt: true }));
  };

  const ShowVoidVoucher = (index: number) => {
    if (!cashReceipts) return;
    const itemIndex = cashReceipts.results[index];
    setSelectedCashReceipt(itemIndex);
    setShowVoid(true);
  };

  const VoidVoucher = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    axiosInstance
      .put(`/parish/api/cash-receipts/void/${selectedCashReceipt?.id}`, {
        void: true,
        purpose_of_void: message,
      })
      .then((res) => {
        setShowVoid(false);
        setRerender2((prev) => !prev);
        SwalSuccess('Void Successful!');
      });
  };

  const [modals, setModals] = useState({
    incomeReceipt: false,
    viewCashReceipt: false,
    viewCashReceiptsReports: false,
    printReceipt: false,
  });

  const CloseModal = () => {
    setDateFrom('');
    setDateTo('');
    setReportsLoading(false);
    setDisablePrint(true);
    setModals((prev) => ({ ...prev, viewCashReceiptsReports: false }));
  };

  const GetAddReceiptAPI = async () => {
    GetCurrentAssets<CurrentAssetsType>({
      setList: setCurrentAssets,
    });

    GetIncomePart<IncomePartType>({
      setList: setIncomeParticular,
    });

    GetAR().then((res) => {
      setArNo(res?.next_ar);
    });
    setShowAdd(true);
  };

  const GenerateReportButton = () => {
    setModals((prev) => ({ ...prev, viewCashReceiptsReports: true }));
  };

  const ClearButton = () => {
    setDateFrom('');
    setDateTo('');
    setReportsLoading(false);
    setDisablePrint(true);
  };

  const AddCashReceiptButton = () => {
    const pKey = sessionStorage.getItem('ProxyKey');
    if (pKey === myAccInfo?.church || pKey === '' || !pKey) {
      return GetAddReceiptAPI();
    }
    if (pKey !== '')
      return SwalFailedCustom({
        title: 'Oops,',
        text: 'This is not your parish!',
      });
  };

  const decrement = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage - 1);
  };

  const increment = () => {
    setNextPrevLoad(false);
    setCurrentPage(currentPage + 1);
  };

  const JumpToButton = () => {
    if (!cashReceipts) return;
    setNextPrevLoad(false);
    setDisableNextPrev(true);
    const lastPage = Math.round(cashReceipts?.count / apiLimit);
    if (jumpToPage > lastPage) {
      return GetCashReceipt<CashReceiptType>({
        setList: setCashReceipts,
        pageNum: (jumpToPage = lastPage),
        limit: apiLimit,
      }).then((res) => {
        setCurrentPage(jumpToPage);
        setNextPrevLoad(true);
        setDisableNextPrev(false);
      });
    }
    if (jumpToPage < 1) {
      return GetCashReceipt<CashReceiptType>({
        setList: setCashReceipts,
        pageNum: 1,
        limit: apiLimit,
      }).then((res) => {
        setCurrentPage(1);
        setNextPrevLoad(true);
        setDisableNextPrev(false);
      });
    }
    GetCashReceipt<CashReceiptType>({
      setList: setCashReceipts,
      pageNum: jumpToPage,
      limit: apiLimit,
    }).then((res) => {
      setCurrentPage(jumpToPage);
      setNextPrevLoad(true);
      setDisableNextPrev(false);
    });
  };

  const noScroll = (e: BaseSyntheticEvent) => {
    e.target.blur();

    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div className={style.container}>
        <div
          className={style.content}
          onClick={() => {
            GenerateReportButton();
          }}
        >
          <i className="fa-solid fa-gears" style={{ fontSize: '30px' }}></i>
          <p className={style.title}>GENERATE CASH RECEIPTS REPORTS</p>
        </div>
      </div>
      <div className={style.coaTitleContainer}>
        <span>
          {AddButton(
            () => {
              AddCashReceiptButton();
            },
            'ADD CASH RECEIPTS',
            '#055437',
            'end',
            'pointer'
          )}
        </span>
      </div>
      <Content>
        <Table success header={['Date', 'Payment For', 'Balance Sheet Account', 'Income Account', 'Amount', 'Action']}>
          {nextPrevLoad ? (
            cashReceipts?.results ? (
              cashReceipts.results.length > 0 ? (
                cashReceipts?.results.map((cash, i) => (
                  <React.Fragment key={i}>
                    <TableRow
                      voidd={cash.void}
                      info={[
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{FormatDate(cash.date_time)}</h4>,
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal', fontFamily: '"IBM Plex Sans", sans-serif' }}>
                          {cash.payment_for}
                        </h4>,
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{cash.balance_sheet.name}</h4>,
                        <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>{cash.income_expense.name}</h4>,
                        <h4 style={{ textAlign: 'end', fontWeight: 'normal', paddingRight: '3.5em' }}>
                          {ParseToPHP(parseFloat(cash.amount))}
                        </h4>,
                        <div style={{ textAlign: 'center' }}>
                          <TableAction
                            icon={<i className="fa-solid fa-eye"></i>}
                            onClick={() => {
                              ShowCashReceipt(i);
                            }}
                          />
                          <span style={{ padding: '0.3em' }}></span>
                          <TableAction
                            icon={<i className="fa-solid fa-print"></i>}
                            onClick={() => {
                              ShowPrint(i);
                            }}
                          />
                          <span style={{ padding: '0.3em' }}></span>
                          <TableAction
                            icon={<i className="fa-solid fa-file-circle-minus"></i>}
                            disabled={cash.void === true}
                            onClick={() => {
                              ShowVoidVoucher(i);
                            }}
                          />
                        </div>,
                      ]}
                    />
                  </React.Fragment>
                ))
              ) : (
                <TableEmpty colSpan={6}>No Cash Receipts!</TableEmpty>
              )
            ) : (
              <TableLoader colSpan={6} type={'dual_ring'} />
            )
          ) : (
            <TableLoader colSpan={6} type={'dual_ring'} />
          )}
        </Table>
        <Content flex justify="space-between" padding="0.5em">
          <GoToPage
            disabled={disableNextPrev}
            next={cashReceipts?.next}
            prev={cashReceipts?.previous}
            jumpTo={JumpToButton}
            currentPage={jumpToPage}
            setCurrentPage={setJumpToPage}
            count={cashReceipts?.count}
            limit={apiLimit}
          />
          <Pagination
            limit={apiLimit}
            next={cashReceipts?.next}
            prev={cashReceipts?.previous}
            currentPage={currentPage}
            increment={increment}
            decrement={decrement}
            count={cashReceipts?.count}
            disabled={disableNextPrev}
          />
        </Content>
      </Content>
      <Modal
        title={'Add Cash Receipts'}
        success
        show={showAdd}
        onClose={() => {
          ModalCloseButton();
        }}
        name={''}
      >
        <Form id={''} handleSubmit={() => {}}>
          <Container flex width="100%" padding="0">
            <Content padding="0 1em">
              <Select
                width="100%"
                title={
                  <>
                    <span className="text-red-500">*</span>Balance Sheet:
                  </>
                }
                name="account"
                required
                onChange={ChangeAssetsHandler}
                placeHolder={'Choose Current Assets'}
                value={selectedAssets?.id}
              >
                {currentAssets?.results.map((item) => (
                  <Option key={item.id} value={item.id} title={item.name} />
                ))}
              </Select>
            </Content>
            <Content padding="0 1em">
              <Select
                width="100%"
                title={
                  <>
                    <span className="text-red-500">*</span>Income Account:
                  </>
                }
                name="income"
                required
                onChange={ChangeIncomePartHandler}
                placeHolder={'Choose Income Particular'}
                value={selectedIncomePart?.id}
              >
                {incomeParticular?.results.map((item) => (
                  <Option key={item.id} value={item.id} title={item.name} />
                ))}
              </Select>
            </Content>
            <Content padding="0 1em">
              <FormInput
                type="date"
                title={
                  <>
                    <span className="text-red-500">*</span>Date:
                  </>
                }
                name={''}
                value={date}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setDate(target.value);
                }}
              />
            </Content>
          </Container>
          <Container flex width="100%" padding="0">
            <Content padding="0 1em">
              <FormInput
                title={
                  <>
                    <span className="text-red-500">*</span>Received From:
                  </>
                }
                name={''}
                value={receivedFrom}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setReceivedFrom(target.value);
                }}
              />
              <FormInput
                title="Amount in Words:"
                name={''}
                value={`${toAmountWords} peso only`}
                onChange={({ target }: BaseSyntheticEvent) => {}}
              />
            </Content>
            <Content padding="0 1em">
              <FormInput
                title={
                  <>
                    <span className="text-red-500">*</span>Address:
                  </>
                }
                name={''}
                value={address}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setAddress(target.value);
                }}
              />
              <FormInput
                type="number"
                onWheel={noScroll}
                title={
                  <>
                    <span className="text-red-500">*</span>Amount in Peso:
                  </>
                }
                name={''}
                value={amount}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setAmount(target.value);
                }}
              />
            </Content>
            <Content padding="0 1em">
              <FormInput
                title={
                  <>
                    <span className="text-red-500">*</span>Payment For:
                  </>
                }
                name={''}
                value={paymentFor}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setPaymentFor(target.value);
                }}
              />
              <FormInput
                title={
                  <>
                    <span className="text-red-500">*</span>Form of Payment:
                  </>
                }
                name={''}
                value={formOfPayment}
                onChange={({ target }: BaseSyntheticEvent) => {
                  setFormOfPayment(target.value);
                }}
              />
            </Content>
          </Container>
          <Container flex>
            <Content padding="0 1em">
              <FormButton
                width="8em"
                success
                title={'ADD'}
                name={''}
                onClick={(e) => {
                  SubmitHandler(e);
                }}
                type={'submit'}
              />
            </Content>
            <Content padding="0 1em">
              <FormButton
                width="8em"
                secondary
                success
                title={'CANCEL'}
                name={''}
                onClick={() => {
                  ModalCloseButton();
                }}
                type={'button'}
              />
            </Content>
          </Container>
        </Form>
      </Modal>
      <Modal
        show={modals.viewCashReceipt}
        onClose={ModalCloseHandler}
        name={'viewCashReceipt'}
        title={'Individual Cash Receipt View'}
        success
        footer={[
          <ModalFooterButton
            key={1}
            success
            name="login"
            text="PRINT RECEIPT"
            onClick={() => {
              Print();
            }}
          />,
          <ModalFooterButton key={2} secondary name="viewCashReceipt" text="CLOSE" onClick={ModalCloseHandler} />,
        ]}
      >
        <VoidSticker isVoid={selectedCashReceipt?.void} />
        <SelectedReceiptTable selectedCashReceipt={selectedCashReceipt} />
      </Modal>
      <Modal
        show={modals.printReceipt}
        onClose={ModalCloseHandler}
        name={'printReceipt'}
        title={'Cash Receipt Print View'}
        success
        footer={[
          <ModalFooterButton
            key={1}
            success
            name="login"
            text="PRINT RECEIPT"
            onClick={() => {
              Print();
            }}
          />,
          <ModalFooterButton key={2} secondary name="printReceipt" text="CLOSE" onClick={ModalCloseHandler} />,
        ]}
      >
        <PrintReceiptTable selectedCashReceipt={selectedCashReceipt} myAccInfo={myAccInfo} />
      </Modal>
      <Modal
        show={modals.viewCashReceiptsReports}
        onClose={CloseModal}
        name={'viewCashReceiptsReports'}
        title={'Cash Receipts Reports View'}
        success
        footer={[
          <ModalFooterButton
            disabled={disablePrint}
            key={1}
            success
            name="login"
            text="Print Cash Reports"
            onClick={() => {
              Print2();
            }}
          />,
          <ModalFooterButton key={2} secondary name="printReceipt" text="CLOSE" onClick={CloseModal} />,
        ]}
      >
        <Container flex>
          <Content padding="0 1em">
            <FormInput
              type="date"
              title="Date From:"
              name={''}
              value={dateFrom}
              onChange={({ target }: BaseSyntheticEvent) => {
                setDateFrom(target.value);
              }}
            />
          </Content>
          <Content padding="0 1em">
            <FormInput
              type="date"
              title="Date To:"
              name={''}
              value={dateTo}
              onChange={({ target }: BaseSyntheticEvent) => {
                setDateTo(target.value);
              }}
            />
          </Content>
          <Content flex justify="space-around">
            <div
              onClick={() => {
                GetCashReportsList();
              }}
              style={{ display: 'flex', paddingLeft: '2em', paddingTop: '2em', color: '#055437', cursor: 'pointer' }}
            >
              <i className="fa-solid fa-gears" style={{ fontSize: '30px' }}></i>
              <p className={style.title}>GENERATE</p>
            </div>
            <div style={{ marginLeft: '1em', marginTop: '1.3em' }}>
              <FormButton
                success
                title={'Clear'}
                name={''}
                onClick={() => {
                  ClearButton();
                }}
                type={'submit'}
              />
            </div>
          </Content>
        </Container>
        <CashReportsTable isLoading={reportsLoading} cashReports={cashReports} />
      </Modal>
      <Modal
        show={showVoid}
        onClose={() => {
          setShowVoid(false);
        }}
        name={'Void Cash Receipt'}
        title={`Void Cash Receipt`}
        width="550px"
        success
      >
        <div>
          <p style={{ color: '#298D69', fontWeight: 'bolder' }}>Are you sure you want to void this cash receipt?</p>
        </div>
        <div>
          <p style={{ color: 'red', fontWeight: 'bolder', textAlign: 'start', marginTop: '2em' }}>Please Type purpose of void*</p>
        </div>
        <Form
          id={''}
          handleSubmit={(e) => {
            VoidVoucher(e);
          }}
        >
          <FormTextArea
            name={'message'}
            required
            value={message}
            rows={2}
            fontSize="1em"
            onChange={({ target }: BaseSyntheticEvent) => {
              setMessage(target.value);
            }}
          />
          <Content flex justify="space-around" padding="1em 0 0 0">
            <div>
              <FormButton success title={'Confirm'} name={''} onClick={() => {}} type={'submit'} />
            </div>
            <div>
              <FormButton
                title={'Cancel'}
                secondary
                name={'addNewModal'}
                onClick={() => {
                  setShowVoid(false);
                }}
                type={'button'}
              />
            </div>
          </Content>
        </Form>
      </Modal>
      <CashReceiptsPrint id="cashReceiptPrint" selectedCashReceipt={selectedCashReceipt} myAccInfo={myAccInfo} />
      <PrintCashReports
        id="cashReportsPrint"
        cashReports={cashReports}
        processedCashReceipt={cashReceipts?.results}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
    </>
  );
};

const Print = async () => {
  printJS({
    printable: 'cashReceiptPrint',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

const Print2 = async () => {
  printJS({
    printable: 'cashReportsPrint',
    type: 'html',
    targetStyles: ['*'],
    font_size: '',
    style: '*{font-family: Calibri;}',
    showModal: true,
  });
};

export default CashReceipts;
